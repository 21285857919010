@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';
@import '../../../../styles/utils/mixin';

.report-main-block{

  &-logo {
    width: 64px;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
    border-radius: @border-radius;
    border: none;
    .with-theme({
      background: @vars[blue-100-to-neutral-900];
    });

    img {
      width: fit-content;
      max-width: 100%;
      height: auto;
      max-height: 100%;
      object-fit: contain;
      border-radius: @border-radius;
    }
  }

  border-radius: @border-radius;
  padding: 23px;
  margin: 24px 0 16px;
  display: flex;
  align-items: center;

  .with-theme({
    border: 1px solid @vars[neutral-100-to-800];
  });

  //&_title:not(.fad-spot){
  //  font-family: 'Inter', sans-serif;
  //  font-style: normal;
  //  font-weight: 600;
  //  font-size: 22px;
  //  line-height: 32px;
  //  word-break: normal;
  //  text-overflow: ellipsis;
  //  white-space: nowrap;
  //  overflow: hidden;
  //  max-width: 350px;
  //  display: block;
  //
  //  .with-theme({
  //    color: @vars[neutral-800-to-200];
  //  });
  //}

  &_link{
    white-space: nowrap;
    margin-right: 16px;

    @media @viewport-mobile {
      margin: 0;    
    }

    .icon{
      margin-left: 8px;
      path{
        fill: @bluePrimary;
      }
    }

    &:hover{
      .icon{
        path{
          fill: @blueDepressed;
        }
      }
    }
  }

  .ant-btn.btn-primary.white{
    height: 40px;
    padding: 11px 19px;
    margin-left: auto;
    margin-right: 16px;
    background: transparent;
    min-width: 156px;

    .with-theme({
      color: @vars[neutral-800-to-white];
      border-color: @vars[neutral-100-to-800];
      &:not([disabled]):hover {
        border-color: @bluePrimary;
        color: @bluePrimary;
      }
    });
  }

  .select-block{
    max-width: 202px;
  }

  .ant-checkbox-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    font-family: 'Inter', sans-serif;
    font-weight: 400;
    min-height: 24px;
    width: fit-content;
    line-height: 20px;
    .with-theme({
      color: @vars[neutral-800-to-200];
    });

    &:hover {
      .ant-checkbox {
        .ant-checkbox-inner {
          .with-theme({
            border-color: @bluePrimary;
          });
        }
      }
    }

    .ant-checkbox {
      top: 0;

      .ant-checkbox-inner {
        width: 20px;
        height: 20px;

        .with-theme({
          border: 1.5px solid @vars[neutral-200-to-700];
          background-color: @vars[color-white-to-dark];
        });

        &:after {
          content: url("../../../Images/checkbox-mark.svg");
          left: 50%;
          top: 50%;
          font-size: 0;
          border: none;
          width: 16px;
          height: 16px;
          transform: scale(1) translate(-50%, -50%);
        }
      }

      &.ant-checkbox-checked {
        .ant-checkbox-inner {
          .with-theme({
            border-color: @bluePrimary;
            background-color: @bluePrimary;
          });
        }
      }
    }

    .info-wrap {
      margin-left: 8px;

      span {
        display: block;
      }

      span:last-of-type {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: @Neutral500;
      }
    }
  }

  .dropdown-sales_tracker-wrapper-inner{
    .dropdown-database-list {
      margin-top: 0;
    }
  }

  &-skeleton {
    .link.skeleton {
      display: inline-block;
    }
  }
}
