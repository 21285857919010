@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';

.fadspot-page-item-wrapper {
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  height: 710px;
  width: 460px;
  font-family: 'Inter', sans-serif;

  &.addLibrary{
    height: auto;
    width: 100%;

    .fadspot-page-item {
      @media screen and (min-width: 1920px) {
        height: 660px;
        width: 100% !important;
      }
      &-image {
        &-wrapper {
          position: relative;
          width: 100%;
          max-height: 350px;
          @media screen and (min-width: 1200px) and (max-width: 1440px) {
            height: 300px;
          }
          @media screen and (min-width: 2000px) {
            max-height: 500px;
            height: 500px;
          }
        }
      }
    }
  }

  .with-theme({
    border: 1px solid @vars[neutral-100-to-neutral-dark-100];
    background: @vars[white-to-neutral-dark-50];
  });

  @media screen and (min-width: 1920px) {
    height: 660px;
    width: 386px !important;
  }

  @media screen and (min-width: 1441px) and (max-width: 1919px) {
    height: 710px;
    width: 430px;
  }

  @media screen and (min-width: 1200px) and (max-width: 1440px) {
    height: 590px;
    width: 330px;
   }

  @media @viewport-mobile {
    width: 95%;
    height: 580px;
    margin: 0 auto;
  }

  .fadspot-page-item {

    &-header {

      &-wrapper {
        width: 100%;
        padding: 16px 16px 12px;
        display: flex;
        flex-direction: column;
        gap: 12px;
      }

      &-logo {
        position: relative;
        margin-right: 8px;
        border-radius: 50%;
        width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        .with-theme({
          background: @vars[blue-100-to-neutral-dark-100];
        });

        .header-logo-status {
          cursor: pointer;
          z-index: 3;
          border-radius: 50%;
          width: 12px;
          height: 12px;
          background: @redPrimary;
          position: absolute;
          bottom: 0;
          right: 0;
          .with-theme({
            border: 2px solid @vars[color-white-to-dark];
          });

          &.active {
            background: @greenPrimary;
          }

          &:hover + div  {
            display: block !important;
          }

          &-tooltip {
            display: none;
            z-index: 300;
            white-space: nowrap;
            width: fit-content;
            position: absolute;
            right: 0;
            top: 0;
            transform: translateX(calc(100% + 4px));
            border-radius: @border-radius;
            font-family: 'Inter', sans-serif;
            padding: 8px 16px;
            font-size: 14px;
            line-height: 20px;
            font-weight: 500;
            background: @Neutral800;
            color: @white;
          }
        }

        img {
          width: 32px;
          height: 32px;
          object-fit: contain;
          border-radius: 50%;
        }

        &-wrapper {
          display: flex;
          align-items: center;
          max-width: calc(100% - 195px);

          &.inner {
            max-width: calc(100% - 75px);
          }
        }

      }

      &-title {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        cursor: pointer;
        .with-theme({
          color: @vars[neutral-800-to-200];
        });

        @media screen and (min-width: 1200px) and (max-width: 1440px) {
          font-size: 14px;
        }

        @media screen and (min-width: 1441px)  and (max-width: 1919px) {
          max-width: 130px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        &.inner {
          cursor: default;

          & > span {
            max-width: 300px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }

          @media screen and (min-width: 1441px)  and (max-width: 1919px) {
            max-width: 230px;
          }
        }

        & > span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 208px !important;

          @media screen and (min-width: 1200px) and (max-width: 1440px) {
            max-width: 135px !important;
          }

          &.overflowing {
            &:before {
              position: absolute;
              left: 50px;
              z-index: 10;
              top: 25px;
              width: max-content;
              max-width: 300px;
              white-space: break-spaces;
              border-radius: 6px;
              padding: 16px;
              font-size: 12px;
              line-height: 16px;
              background: @Neutral800;
              color: @white;
              text-align: left;
              content: attr(data-text);

              @media @viewport-mobile {
                max-width: 200px;
              }
            }
          }
        }

        & > svg {
          margin-left: 4px;
        }

        &-info-wrapper {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          max-width: calc(100% - 56px);
        }

        &-wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;

          @media @viewport-mobile {
            flex-direction: column;
            align-items: flex-start;
            gap: 12px;
          }
        }

        &-info {
          display: flex;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          align-items: center;
          white-space: nowrap;

          .with-theme({
            color: @vars[neutral-600-to-neutral-dark-500];
          });

          @media screen and (min-width: 1200px) and (max-width: 1440px) {
            font-size: 12px;
          }

          & > .quantity {
            margin-left: 6px;
            @media screen and (min-width: 1441px)  and (max-width: 1919px) {
              white-space: nowrap;
              max-width: 50px;
              overflow: hidden;
              text-overflow: ellipsis;
              margin-left: 0;

              &.overflowing {
                &:before {
                  position: absolute;
                  left: 150px;
                  z-index: 10;
                  top: 45px;
                  width: max-content;
                  max-width: 230px;
                  white-space: break-spaces;
                  border-radius: 6px;
                  padding: 16px;
                  font-size: 12px;
                  line-height: 16px;
                  background: @Neutral800;
                  color: @white;
                  text-align: left;
                  content: attr(data-text);
                }
              }
            }
          }

          &-circle {
            border-radius: 50%;
            background: @Neutral500;
            width: 4px;
            height: 4px;

            .with-theme({
              background-color: @vars[neutral-600-to-neutral-dark-500];
            });

            &-wrapper {
              display: flex;
              align-items: center;
              justify-content: center;
              margin: 0 4px;
            }
          }
        }

        &-actions {
          display: flex;
          align-items: center;

          .ant-divider {
            margin: '0 12px';
            height: 16px;
            border-top: none;
            .with-theme({
              background: @vars[neutral-200-to-neutral-dark-200];
            })
          }
        }

        &-action {
          padding: 0;
          margin: 0;
          border: none;
          cursor: pointer;
          background: transparent;
          svg path {
            transition: all .3s ease;
          }

          &:hover svg path:first-of-type {
            fill: @blueDepressed;
          }
        }

        &-more {
          cursor: pointer;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          color: @bluePrimary;
          transition: all .3s ease;
          display: flex;
          align-items: center;

          &.disabled {
            .with-theme({
              color: @vars[neutral-500-to-neutral-dark-500];
            });
            
            svg path {
              fill: @Neutral500;
            }
            
            &:hover {
              cursor: not-allowed;
            }
          }
          svg {
            margin-top: 2px;
            transform: rotate(-90deg);
            path {
              transition: all .3s ease;
              fill: @bluePrimary;
            }
          }

          &:hover {
            color: @blueDepressed;

            svg path {
              fill: @blueDepressed;
            }
          }
        }
      }

      &-description {
        height: 50px;
        overflow-y: scroll;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;

        @media screen and (min-width: 1200px) and (max-width: 1440px) {
          font-size: 12px;
         }

        .with-theme({
          color: @vars[neutral-600-to-neutral-dark-500];
        });

        &::-webkit-scrollbar {
          width: 4px;
          border-radius: 2px;
        }
        &::-webkit-scrollbar-button {
          display: none;
        }
        &::-webkit-scrollbar-track-piece {
          background: transparent;
          border-radius: 2px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 2px;
          .with-theme({
            background: @vars[neutral-200-to-neutral-dark-200];
          });
        }

      }
    }

    &-image {

      &-wrapper {
        position: relative;
        width: 100%;
        height: 430px;

        .with-theme({
          background: @vars[blue-100-to-900];
        });

        @media screen and (min-width: 1920px) {
          height: 384px;
          width: 384px;
        }

        @media screen and (min-width: 1200px) and (max-width: 1440px) {
          height: 330px;
         }

        @media @viewport-mobile {
          width: 100%;
          height: 270px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &-play {
        width: 72px;
        height: 72px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-30%, -50%);
        cursor: pointer;

        svg {
          transition: all .3s ease;
          path {
            fill: @Neutral200;
          }
        }

        &:hover svg {
          transform: scale(1.1);
        }
      }
    }

    &-action {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 90px;
      overflow-y: auto;
      padding: 16px;
      .with-theme({
        background: @vars[neutral-50-to-neutral-dark-100];
      });

      &:has(.overflowing) {
        overflow: visible !important;
      }

      @media screen and (min-width: 1200px) and (max-width: 1440px) {
        height: 80px;
      }

      @media @viewport-mobile {
        align-items: center;
        height: 80px;
      }

      &-wrapper {
        display: flex;
        flex-direction: column;
        position: relative;

        .fadspot-page-item-action-slider {
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          z-index: 1000;
          bottom: 95px;
          left: 50%;
          transform: translate(-50%, -50%);
          gap: 4px;

          @media @viewport-mobile {
            bottom: 75px;
          }

          &-prev {
            width: 28px;
            height: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: @border-radius;
            cursor: pointer;
            background-color: rgba(0, 0, 0, .5);
            color: @white;

            &:hover {
              background-color: rgba(0, 0, 0, .6);
            }
    
            &.disabled {
              cursor: not-allowed;

              &:hover {
                background-color: rgba(0, 0, 0, .5);
              }

              svg path {
                stroke: @NeutralDark200;
              }
            }
    
            svg {
              path {
                fill: transparent;
                stroke: white;
              }
            }
    
          }
    
          &-next {
            width: 28px;
            height: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 8px;
            cursor: pointer;
            background-color: rgba(0, 0, 0, .5);
            color: @white;
            transition: all .3s;
    
            &:hover {
              background-color: rgba(0, 0, 0, .6);
            }
    
            &.disabled {
              cursor: not-allowed;

              &:hover {
                background-color: rgba(0, 0, 0, .5);
              }

              svg path {
                stroke: @NeutralDark200;
              }
            }
    
            svg {
              path {
                fill: transparent;
                stroke: white;
              }
            }
          }
    
          &-counter {
            display: flex;
            align-items: center;
            padding: 4px 6px;
            border-radius: 8px;
            font-family: 'Inter', sans-serif;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
            background-color: rgba(0, 0, 0, .5);
            color: @white;
    
            svg {
              margin-right: 4px;
            }
    
            @media @viewport-mobile {
              bottom: 90px;
            }
          }
        }

        .slick-dots {
          display: none !important;
        }
      }

      &-text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-family: 'Inter', sans-serif;
        font-style: normal;
        max-width: 300px;

        @media @viewport-mobile {
          max-width: 65%;
        }

        @media screen and (min-width: 1200px) and (max-width: 1440px) {
          max-width: 200px;
        }

        @media screen and (min-width: 1441px) and (max-width: 1919px) {
          max-width: 235px;
        }

        @media screen and (min-width: 1920px) {
          max-width: 225px;
        }
      }

      &-domain {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: @bluePrimary;
        cursor: pointer;
        text-transform: uppercase;
        padding-bottom: 4px;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 200px;
        text-wrap: nowrap;

        @media screen and (min-width: 1200px) and (max-width: 1440px) {
          font-size: 12px;
          line-height: 14px;
        }

        @media @viewport-mobile {
          font-size: 12px;
          line-height: 14px;
        }
      }

      &-headline {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-bottom: 4px;

        .with-theme({
          color: @vars[neutral-800-to-100];
        });

        &.overflowing {
          &:before {
            position: absolute;
            z-index: 300;
            left: 16px;
            top: 0;
            transform: translateY(calc(-100% + 20px));
            max-width: calc(100% - 32px);
            white-space: break-spaces;
            border-radius: 6px;
            padding: 16px;
            font-size: 12px;
            line-height: 16px;
            background: @Neutral800;
            color: @white;
            text-align: left;
            content: attr(data-text);
          }
        }

        @media @viewport-mobile {
          font-size: 14px;
          line-height: 18px;
        }

        @media screen and (min-width: 1200px) and (max-width: 1440px) {
          font-size: 14px;
          line-height: 18px;
        }
      }

      &-description {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: @Neutral500;
        white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;

        .with-theme({
          color: @vars[neutral-600-to-neutral-dark-500];
        });

        &.overflowing {
          &:before {
            position: absolute;
            z-index: 300;
            left: 16px;
            top: 0;
            transform: translateY(calc(-100% + 50px));
            width: calc(100% - 32px);
            white-space: break-spaces;
            border-radius: 6px;
            padding: 16px;
            font-size: 12px;
            line-height: 16px;
            background: @Neutral800;
            color: @white;
            text-align: left;
            content: attr(data-text);
          }
        }

        @media @viewport-mobile {
          font-size: 14px;
          line-height: 14px;
        }
        @media screen and (min-width: 1200px) and (max-width: 1440px) {
          font-size: 12px;
          line-height: 14px;
        }
      }

      &-btn {
        padding: 10px 12px;
        border-radius: 10px;
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        .with-theme({
          color: @vars[neutral-800-to-100];
          background: @vars[white-to-neutral-dark-50];
          border: 1px solid @vars[neutral-200-to-neutral-dark-300];
          box-shadow: @vars[box-shadow-object-middle];
        });
        transition: all .3s ease;
        cursor: pointer;
        text-wrap: nowrap;

        @media @viewport-mobile {
          text-align: center;
        }

        &:hover {
          border: 1px solid @bluePrimary;
          color: @bluePrimary;
        }
      }
    }

    &-emoji {
      display: flex;
      align-items: center;
      z-index: 0;

      &-icon-value {
        position: relative;

        &:after {
          z-index: 300;
          white-space: nowrap;
          width: fit-content;
          display: none;
          position: absolute;
          right: -10px;
          top: 50%;
          transform: translate(100%, -50%);
          border-radius: @border-radius;
          font-family: 'Inter', sans-serif;
          padding: 4px 8px;
          font-size: 12px;
          line-height: 16px;
          background: @Neutral800;
          color: @white;
          content: attr(data-value);
        }

        &:hover:after {
          display: block;
        }
      }

      &-icon {
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        .with-theme({
          border: 1px solid @vars[color-white-to-dark];
          background: @vars[color-white-to-dark];;
        });

        &:not(:first-of-type) {
          margin-left: -10px;
        }

        @media @viewport-mobile {
          svg {
            width: 18px !important;
            height: 18px !important;
          }
        }
      }

      & > span {
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        margin-left: 8px;

        @media @viewport-mobile {
          font-size: 12px;
        }

        @media screen and (min-width: 1200px) and (max-width: 1440px) {
          font-size: 12px;
        }

        .with-theme({
          color: @vars[neutral-600-to-neutral-dark-500]
        })
      }

      &-wrapper {
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 16px;
        
        @media screen and (min-width: 1200px) and (max-width: 1440px) {
          padding: 8px;
        }
      }

      &-actions {
        display: flex;
        align-items: center;
        gap: 12px;

        @media @viewport-mobile {
          gap: 4px;
        }

        @media screen and (min-width: 1200px) and (max-width: 1440px) {
          gap: 4px;
        }

        &-item {
          display: flex;
          align-items: center;
          gap: 4px;
          font-family: 'Inter', sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          color: @Neutral500;

          @media screen and (min-width: 1200px) and (max-width: 1440px) {
            font-size: 12px;
          }

          & > span {
            .with-theme({
              color: @vars[neutral-600-to-neutral-dark-500]
            })
          }

          @media @viewport-mobile {
            gap: 4px;
            font-size: 12px;

            svg {
              width: 18px !important;
              height: 18px !important;
            }
          }
        }
      }
    }
  }
}
