@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';

.user-tracking-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 16px;
  min-height: 464px;
  .with-theme({
    box-shadow: @vars[box-shadow-btn-secondary];
  });

  @media screen and (min-width: 769px) and (max-width: 1400px) {
    width: calc((100% - 16px) / 2);
  }

  @media screen and (min-width: 1401px) and (max-width: 1900px) {
    width: calc((100% - 32px) / 3);
  }

  @media screen and (min-width: 1901px) and (max-width: 2500px) {
    width: calc((100% - 48px) / 4);
  }

  @media screen and (min-width: 2501px) {
    width: calc((100% - 64px) / 5);
  }

  @media @viewport-mobile {
    width: 100%;
  }

  &-image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    aspect-ratio: 1;
    border-radius: 16px 16px 0 0;

    img {
      display: block;
      aspect-ratio: 1;
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 16px 16px 0 0;
    }
  }

  &-title {
    width: 100%;
    flex-wrap: nowrap;
    padding: 16px 12px 12px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;

    span {
      display: block;
      width: calc(100% - 28px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.3px;
      .with-theme({
        color: @vars[neutral-800-to-100];
      });
    }
  }

  &-info {
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 8px;

    .row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .key {
        text-align: left;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.3px;
        .with-theme({
          color: @vars[neutral-600-to-neutral-dark-500];
        });
      }

      .value {
        display: flex;
        align-items: center;
        gap: 8px;
        text-align: right;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.3px;
        .with-theme({
          color: @vars[neutral-800-to-100];
        });
      }
    }
  }

  &-btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 16px 12px;
    width: 100%;

    .product-actions-options {
      width: 34px;
      height: 34px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      .with-theme({
        box-shadow: @vars[box-shadow-btn-secondary];
        background: @vars[white-to-neutral-dark];

        &:hover, &.ant-dropdown-open {
          background: @vars[neutral-50-to-neutral-dark-100];
        }
      })
    }
  }

  &-btn {
    width: calc(100% - 44px);
    cursor: pointer;
    border-radius: 8px;
    padding: 6px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.3px;
    transition: all 0.2s ease;
    .with-theme({
      box-shadow: @vars[box-shadow-btn-secondary];
      background: @vars[white-to-neutral-dark];
      color: @vars[neutral-800-to-100];

      &:hover, &:focus {
        background: @vars[neutral-50-to-neutral-dark-100];
      }
    })
  }
}
