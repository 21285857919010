@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';
@import '../../../../styles/utils/mixin';

.product-cards-page {
  margin-top: 24px;
  width: 100%;

  .empty-state-product-database {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    font-family: "Inter", sans-serif;

    .empty-state-product-database-title {
      font-weight: 600;
      font-size: 22px;
      line-height: 32px;
      .with-theme({
        color: @vars[neutral-800-to-200];
      })
    }

    .empty-state-product-database-subtitle {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #707BA0;
      margin: 16px 0;
    }
  }

  &-categories {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 20px;
    .with-theme({
      border-bottom: 1px solid @vars[neutral-100-to-800];
    })
  }

  &-category {
    position: relative;
    width: 33%;
    height: 52px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: @Neutral500;
    cursor: pointer;
    transition: all .3s ease;
    .with-theme({
      background: @vars[color-white-to-dark];
    });

    &:hover {
      .with-theme({
        color: @vars[color-dark-to-white];
        background: @vars[blue-100-to-900];
      });
    }

    &.active {
      cursor: default;
      .with-theme({
        color: @vars[color-dark-to-white];
        background: @vars[blue-100-to-900];
      });

      &:after {
        position: absolute;
        width: 100%;
        height: 4px;
        background: @bluePrimary;
        left: 0;
        bottom: -2px;
        content: '';
      }
    }
  }

  &-filters-wrapper {
    padding: 16px;
    display: flex;
    align-items: center;
    border-radius: @border-radius;
    gap: 24px;
    width: 100%;
    height: 72px;
    .with-theme({
      border: 1px solid @vars[neutral-100-to-800];
      background: @vars[color-white-to-dark];
    });

    @media @viewport-mobile {
      flex-direction: column;
      height: auto;
    }
  }

  &-search {
    width: calc(100% - 589px);

    &.filters-panel {
      width: 500px;
      
      .top-products-search-wrapper {

        button {
          border-radius: 10px;
          padding: 10px 12px;
          
          &[disabled] {
            .with-theme({
              background-color: @vars[neutral-50-to-neutral-dark-100];
            });
          }

          &:hover {
            .with-theme({
              background-color: @vars[neutral-50-to-neutral-dark-100];
            });
          }
          
          .with-theme({
            border: 1px solid @vars[neutral-200-to-neutral-dark-200];
            background-color: @vars[white-to-neutral-dark-50];
            color: @vars[neutral-800-to-100] !important;
            box-shadow: @vars[box-shadow-btn-secondary-without-border];
          });
        }
      }
    }

    @media @viewport-mobile {
      width: 100%;
    }


    .top-products-search-wrapper {
      display: flex;
      align-items: center;
      gap: 16px;

      @media @viewport-mobile {
        flex-direction: column;
        gap: 10px;

        .sales-tracker-search {
          width: 100%;
        }
      }

      .top-products-search-list {
        align-items: center;
        border-radius: @border-radius;
        display: flex;
        min-height: 40px;
        padding: 0 18px;
        width: calc(100% - 83px);
        transition: all .3s ease;
        .with-theme({
          border: 1px solid @vars[neutral-200-to-neutral-dark-200];
        });

        @media @viewport-mobile {
          width: 100%;
        }

        svg {
          transition: all .3s ease;
          path {
            .with-theme({
              // fill: @vars[neutral-600-to-neutral-dark-500];
              // fill: @vars[neutral-100-to-neutral-dark-200];
            });
          }
        }

        &:focus-within {
          border: 1px solid @bluePrimary;
        }

        .top-products-search-prefix {
          align-items: center;
          display: flex;
          margin-right: 16px;

          .ant-divider {
            height: 32px;
            margin-left: 16px;
            margin-right: 0;
            .with-theme({
              background: @vars[neutral-100-to-800];
            })
          }

          svg path {
            .with-theme({
              fill: @vars[neutral-200-to-neutral-dark-200];
            });
          }
        }

        .top-products-search-list-items {
          align-content: center;
          display: flex;
          flex-wrap: wrap;
          max-width: calc(100% - 65px);
          min-height: 30px;

          .ant-input {
            background-color: initial!important;
            border: none!important;
            caret-color: @bluePrimary;
            height: 30px!important;
            padding: 0!important;

            &::placeholder {
              .with-theme({
                color: @vars[neutral-400-to-neutral-dark-400];
              });
            }
          }

          .ant-tag {
            align-items: center;
            justify-content: space-between;
            border: none;
            border-radius: 6px;
            display: flex;
            font-family: Inter,sans-serif;
            font-size: 12px;
            font-weight: 500;
            line-height: 16px;
            margin-bottom: 8px;
            margin-right: 8px;
            white-space: pre-wrap;
            .with-theme({
              border: 1px solid @vars[neutral-200-to-neutral-dark-200];
              color: @vars[neutral-800-to-200];
            });

            @media @viewport-mobile {
              padding: 0 4px;
            }

            &-close-icon {
              padding: 1.75px;
              border-radius: 4px;
    
              .with-theme({    
                svg {
                  width: 15px;
                  height: 15px;

                  @media @viewport-mobile {
                    width: 13px;
                    height: 13px;
                  }
    
                  path {
                    stroke: @vars[neutral-600-to-neutral-dark-500];
                  }
                }
              })
            }
          }
        }
      }
    }
  }

  &-dropdown-wrapper {
    display: flex;
    align-items: center;
    gap: 12px;

    @media @viewport-mobile {
      flex-direction: column;
      gap: 10px;
      width: 100%;
    }

    .main-filter-dropdown {
      position: relative;

      &.price {
        .main-filter-dropdown-content-wrapper {
          @media @viewport-mobile {
            left: 0 !important;
          }
        }
      }
      &.revenue {
        .main-filter-dropdown-content-wrapper {
          left: -249px !important;
          @media @viewport-mobile {
            left: 0 !important;
          }
        }
      }
      &.sales {
        .main-filter-dropdown-content-wrapper {
          left: -397px !important;
          @media @viewport-mobile {
            left: 0 !important;
          }
        }
      }

    }
  }

  &-items-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    gap: 12px;
    margin-top: 32px;
    height: calc(100vh - 118px);
    overflow-y: scroll;

    @media @viewport-mobile {
      justify-content: center;
    }

    &::-webkit-scrollbar {
      width: 4px;
      border-radius: 2px;
    }
    &::-webkit-scrollbar-button {
      display: none;
    }
    &::-webkit-scrollbar-track-piece {
      border-radius: 2px;
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      .with-theme({
        background: @vars[neutral-200-to-neutral-dark-200];
      });
    }

    .product-cards-page-item {
      width: 268px;
      height: 520px;
      border-radius: 12px;
      .with-theme({
        background-color: @vars[white-to-neutral-dark-50];
        border: 1px solid @vars[neutral-200-to-neutral-dark-200];
      });

      @media @viewport-mobile {
        width: 100%;
      }

      .item {
        &-logo {
          width: 100%;
          height: 268px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-top-left-radius: 11px;
          border-top-right-radius: 11px;
          background: @blueLight;
          position: relative;
          max-height: unset;
          transition: all linear 1s;

          &-rank {
            position: absolute;
            min-width: 32px;
            min-height: 32px;
            padding: 6px 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 8px;
            background: @black;
            opacity: .5;
            z-index: 1;
            color: @white;
            left: 7px;
            top: 7px;
            font-family: Inter, sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
          }

          &:hover{
            a {
              overflow: visible;
              border-radius: 12px;
              img{
                border-radius: 12px;
                transform: scale(1.01);
                height: max-content;
              }
            }

            @media @viewport-mobile {
              a {
                overflow: hidden;

                img {
                  transform: scale(1);
                  height: 268px;
                  border-radius: @border-radius @border-radius 0 0;
                }
              }
            }
          }

          a {
            width: 100%;
            height: 268px;
            overflow: hidden;
            transition: all 0.3s ease;

            img {
              width: 100%;
              height: 268px;
              border-top-left-radius: 11px;
              border-top-right-radius: 11px;
              object-fit: cover;
              object-position: top;
              transition: all 0.3s ease;

              @media @viewport-mobile {
                object-position: 0;
              }
            }
          }
        }

        &-content {
          margin-top: 12px;
          font-family: Inter, sans-serif;
          padding: 0 16px;
        }

        &-title {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          max-width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: left;
          margin-bottom: 12px;

          .with-theme({
            color: @vars[neutral-800-to-200];
          });

          &.overflowing {
            &:before {
              position: absolute;
              left: 16px;
              top: 20px;
              width: max-content;
              max-width: 230px;
              white-space: break-spaces;
              border-radius: 6px;
              padding: 16px;
              font-size: 12px;
              line-height: 16px;
              color: @white;
              text-align: left;
              content: attr(data-text);
              .with-theme({
                background-color: @vars[neutral-800-to-neutral-dark];
              });
            }
          }
        }

        &-stat {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 8px;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          margin-bottom: 12px;
          
          &-key {
            font-weight: 400;
            .with-theme({
              color: @vars[neutral-600-to-neutral-dark-500];
            });
            text-align: left;
          }
          
          &-value {
            text-align: right;
            display: flex;
            align-items: center;
            
            .with-theme({
              color: @vars[neutral-800-to-100];
            });

            &.clickable {
              color: @bluePrimary;
              cursor: pointer;

              .primary {
                font-size: 18px;
                margin-right: 5px;
                font-weight: 400;
              }

              .secondary {
                font-size: 14px;
                font-weight: 400;
                
                .with-theme({
                  color: @vars[neutral-600-to-neutral-dark-500];
                });
              }

              &:hover {
                color: @blueDepressed;
              }
            }
          }
        }

        &-btn-wrapper {
          margin-top: 24px;
          display: flex;
          align-items: center;
          gap: 12px;
          width: 100%;

          @media @viewport-mobile {
            justify-content: center;
          }

          .item-btn {
            width: 100%;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: @border-radius;
            cursor: pointer;
            transition: all .3s ease;
            font-family: Inter, sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            gap: 8px;
            border-radius: 8px;

            @media @viewport-mobile {
              width: calc(50% - 6px);
            }

            &.import {
              font-weight: 400;
              .with-theme({
                color: @vars[neutral-800-to-200];
                background: @vars[white-to-neutral-dark-50];
                border: 1px solid @vars[neutral-200-to-neutral-dark-200];
                box-shadow: @vars[box-shadow-btn-secondary-without-border];

                svg path {
                  fill: @vars[neutral-800-to-200];
                }
              });

              &:hover {
                color: @bluePrimary;
                border: 1px solid @bluePrimary;

                svg path {
                  fill: @bluePrimary;
                }
              }
            }

            &.track {
              color: @white;
              background: @bluePrimary;
              border: none;
              font-weight: 400;
              .with-theme({
                box-shadow: @vars[box-shadow-btn-secondary-without-border];
              });

              svg path {
                stroke: @white;
              }

              &:hover {
                background: @blueDepressed;
              }

              &.tracked {
                background: @redPrimary;

                &:hover {
                  background: @redDepressed;
                }
              }
            }
          }
        }
      }
    }
  }
}
