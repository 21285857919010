@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';
@import '../../../../styles/utils/mixin';

.page-options-wrapper.adlibrary {
  .view-options {
    .ant-dropdown-trigger.disabled{
        cursor: not-allowed;
        .with-theme({
        background: @vars[neutral-100-to-neutral-dark-100] !important;
        })
    }
    @media @viewport-mobile {
      .filters, .presets.sort {
        width: 50%;
        justify-content: center;
      }
    }
  }

  .sales-tracker-top-page-header-dropdown.sort {
    width: 215px;
    height: auto;

    .sort-header {
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: -0.3px;
      .with-theme({
        color: @vars[neutral-400-to-neutral-dark-400];
      });

      &-options {
        margin-top: 8px;
        width: 100%;
        display: flex;
        align-items: center;
        gap: 8px;
      }

      &-option {
        cursor: pointer;
        transition: all .3s ease;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 32px;
        padding: 12px 8px;
        border-radius: 8px;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.3px;
        .with-theme({
          border: 1px solid @vars[neutral-200-to-neutral-dark-200];
          background: @vars[white-to-neutral-dark-50];
          color: @vars[neutral-500-to-neutral-dark-500];

          &:hover {
            background: @vars[neutral-50-to-neutral-dark-100];
          }
        });

        &.active {
          border: 1px solid @bluePrimary !important;
          color: @white !important;
          background: @bluePrimary !important;
        }
      }
    }

    .sort-options {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      width: 100%;
      gap: 4px;

      .sort-option {
        display: flex;
        border-radius: 10px;
        width: 100%;
        align-items: center;
        justify-content: flex-start;
        height: 34px;
        padding: 0 8px;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.3px;
        cursor: pointer;
        transition: all .3s ease;
        .with-theme({
          color: @vars[neutral-800-to-100];

          &:hover {
            background: @vars[neutral-50-to-neutral-dark-100];
          }
        });

        &.active {
          background: @bluePrimary !important;
          color: @white !important;
        }
      }
    }
  }
}
