@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';
@import '../../../../styles/utils/mixin';

.page-options-wrapper {

  .search-options {

    .search {
      position: relative;

      @media @viewport-mobile {
        flex-direction: column;

        .ant-input-search {
          width: 100%;
        }
      }
    }

    .search-suggestions {

      &-wrapper {
        width: 400px;
        left: 0;
        border-radius: 12px;
        .with-theme({
          background: @vars[white-to-neutral-dark];
          border: 1px solid @vars[neutral-200-to-neutral-dark-200];
          box-shadow: @vars[box-shadow-object-large];
        });
      }

      &-title {
        padding: 8px 8px 0;
        display: flex;
        align-items: center;
        gap: 8px;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.3px;
        .with-theme({
          color: @vars[neutral-800-to-100];
        })
      }

      &-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 6px 8px;
        margin: 0 auto;
        width: calc(100% - 8px);
        transition: all .3s ease;
        cursor: pointer;
        border-radius: 8px;

        &:hover {
          .with-theme({
            background: @vars[blue-100-to-neutral-dark-100];
          })
        }

        &-info {
          display: flex;
          align-items: center;
          gap: 8px;
        }

        .logo {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          .with-theme({
            background: @vars[blue-100-to-neutral-900];
          });

          img {
            width: 40px;
            height: 40px;
            object-fit: cover;
            border-radius: 50%;
          }
        }

        .title {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          h4 {
            margin: 0;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: -0.3px;
            .with-theme({
              color: @vars[neutral-800-to-100];
            });
          }
          span {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: -0.3px;
            .with-theme({
              color: @vars[neutral-600-to-neutral-dark-500];
            });
          }
        }

        &-count {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: -0.3px;
          .with-theme({
            color: @vars[neutral-600-to-neutral-dark-500];
          });
        }
      }

      &-items {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        max-height: 248px;
        overflow-y: auto;

        &::-webkit-scrollbar {
          width: 4px;
          border-radius: 2px;
        }
        &::-webkit-scrollbar-button {
          display: none;
        }
        &::-webkit-scrollbar-track-piece {
          background: transparent;
          border-radius: 2px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 2px;
          .with-theme({
            background: @vars[neutral-200-to-neutral-dark-200];
          });
        }
        &::-webkit-scrollbar-track-piece:start {
          background: transparent;
          margin-top: 10px;
        }
        &::-webkit-scrollbar-track-piece:end {
          background: transparent;
          margin-bottom: 10px;
        }

        &-empty {
          padding: 0 8px 8px;
          display: flex;
          flex-direction: column;
          align-items: center;

          h3 {
            margin: 0;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.3px;
            .with-theme({
              color: @vars[neutral-800-to-100];
            });
          }

          p {
            text-align: center;
            margin: 0;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: -0.3px;
            .with-theme({
              color: @vars[neutral-500-to-neutral-dark-500];
            });
          }
        }
      }
    }
  }

  .search-submit {
    display: flex;
    align-items: center;
    gap: 8px;

    @media @viewport-mobile {
      width: 100%;
      justify-content: center;
    }

    & > svg path {
      .with-theme({
        stroke: @vars[neutral-500-to-neutral-dark-500];
      });
    }
  }

  .display-options {
    display: flex;
    align-items: center;
    gap: 16px;

    @media @viewport-mobile {
      width: 100%;
    }
  }

  .product-view {
    display: flex;
    align-items: center;
    height: 40px;
    cursor: pointer;
    border-radius: 10px;
    padding: 0 4px;
    .with-theme({
      background: @vars[neutral-50-to-neutral-dark-50];
      border: 1px solid @vars[neutral-200-to-neutral-dark-200];
    });

    &-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      border-radius: 8px;
      transition: all .3s ease;

      &--active {
        box-shadow: 0px 5px 10px -2px rgba(25, 28, 33, 0.06), 0px 2px 6px -2px rgba(106, 115, 133, 0.12), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
        .with-theme({
          background: @vars[white-to-neutral-dark-200];
        })
      }
    }
  }

  .page-view {
    height: 40px;

    @media @viewport-mobile {
      width: 100%;
    }

    .product-view-btn {
      height: 32px;
      width: fit-content;
      padding: 0 12px;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.3px;
      .with-theme({
        color: @vars[neutral-600-to-neutral-dark-500];

        &.product-view-btn--active {
          color: @vars[neutral-800-to-100];
        }
      });

      @media @viewport-mobile {
        width: 50%;
      }
    }
  }
}
