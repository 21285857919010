@import '../styles/utils/variables';
@import '../styles/theme/theme';
@import '../styles/base/ui-kit';

.ant-modal.change-modal.custom-modal.video-modal {
  width: 100% !important;
  max-width: 874px !important;
  overflow: visible;

  .ant-modal-content {
    border: none !important;
    height: 47vw;
    max-height: 487px;
    width: 99%;
    box-shadow: 0px 24px 24px -12px rgba(21, 30, 58, 0.04), 0px 12px 12px -6px rgba(21, 30, 58, 0.04), 0px 6px 6px -3px rgba(21, 30, 58, 0.04), 0px 3px 3px -1.5px rgba(21, 30, 58, 0.04), 0px 1px 1px -0.5px rgba(21, 30, 58, 0.04), 0px 0px 0px 1px rgba(21, 30, 58, 0.04);;

    iframe {
      border-radius: 12px;

      @media @viewport-mobile {
        border-radius: 24px 24px 0 0;
      }
    }

    @media @viewport-mobile {
      border-radius: 24px 24px 0 0;
      width: 100%;
      height: 100vw;
    }
  }

  .ant-modal-body {
    padding: 0 !important;
    width: 100%;
    height: 100%;
    border-radius: @border-radius;

    @media @viewport-mobile {
      padding-top: 24px;
      border-radius: 0;
    }
  }

  .ant-modal-close {
    top: -46px !important;
    right: -46px !important;
    width: 36px;
    height: 36px;
    border-radius: 10px;
    .with-theme({
      background: @vars[white-to-neutral-dark];
      box-shadow: @vars[box-shadow-btn-secondary];
    });

    @media @viewport-mobile {
      right: 0 !important;
      top: -40px !important;
    }

    svg {
      width: 13px;
    }
  }
}
