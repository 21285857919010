@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';

.product-database-table {

  &-body {

    .table-column-title {
      display: flex;
      align-items: center;
      gap: 4px;
      text-wrap: nowrap;

      .title-sort-header {
        display: flex;
        flex-direction: column;
        width: 20px;
        gap: 0;

        svg {

          &.icon-descend, &.icon-ascend {
            path {
              stroke: @bluePrimary
            }
          }

          &:first-of-type {
            transform: rotate(-90deg);
          }

          &:last-of-type {
            transform: rotate(90deg);
            margin-top: -9px;
          }
        }
      }
    }

    .ant-table-row {
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.3px;
      .with-theme({
        color: @vars[neutral-800-to-100];
      })
    }

    .ant-table-empty {
      .ant-table-tbody > tr.ant-table-placeholder:hover > td {
        background: transparent !important;
      }
    }

    &--dark {
      .ant-empty-description {
        color: @Neutral500;
      }

      .ant-empty-image {
        svg path, g, ellipse {
          fill: @Neutral500;
        }
      }
    }
  }

  &-wrapper {
    margin-top: 24px;
    padding: 8px 0 0;
    border-radius: 0;
    position: relative;
    bottom: -4px;
    .with-theme({
      border-top: 1px solid @vars[neutral-200-to-neutral-dark-200];
      border-bottom: 1px solid @vars[neutral-200-to-neutral-dark-200];
    });

    &.active {
      border-top: none !important;
      margin-top: 0;
      padding: 2px 0 0;
    }

    @media @viewport-mobile {
      margin-top: 16px;
    }

    .link.skeleton {
      display: inline-block;
      border-radius: @border-radius;
    }

    .product-database-table-skeleton-row {
      padding-left: 32px;
      height: 80px;
      display: flex;
      .with-theme({
        border-bottom: 1px solid @vars[neutral-200-to-neutral-dark-200];
      });

      .product-database-table-skeleton-cell {

        &-wrapper {
          width: 100%;
          display: flex;
          align-items: center;
        }

        &-1 {
          width: 30.5%;
          display: flex;
        }

        &-2 {
          width: 13.5%;
          display: flex;
          align-items: center;
          gap: 8px;
        }

        &-3 {
          width: 15.5%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }

        &-4 {
          width: 16%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }

        &-5 {
          padding-right: 15px;
          width: 24%;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }

    .product-database-table-subheader {
      display: flex;
      margin-top: 30px;
      div {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        span:not(:first-of-type) {
          margin-left: 8px;
        }
      }
    }

    &-hidden {
      display: none;
      opacity: 0;
    }

    &-empty {
      height: 250px;
      .with-theme({
        background: @vars[white-to-neutral-dark];
        border: none !important;
      });
      display: flex;
      justify-content: center;
      align-items: center;

      &.active {
        height: calc(100dvh - 93px - 91px - 24px - 18px);

        @media @viewport-mobile {
          height: calc(100dvh - 127px - 69px);
        }
      }

      .empty-state-product-database {
        display: flex;
        flex-direction: column;
        align-items: center;

        &-title {
          font-weight: 600;
          font-size: 22px;
          line-height: 32px;
          .with-theme({
            color: @vars[neutral-800-to-100];
          })
        }

        &-subtitle {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          margin: 16px 0;
          .with-theme({
            color: @vars[neutral-500-to-neutral-dark-500];
          })
        }
      }
    }
  }

  &-body {
    margin-top: 8px;
    position: relative;

    .ant-table-thead > tr > td:first-child {
      z-index: 1000 !important;
    }

    .ant-spin-nested-loading > div > .ant-spin {
      z-index: 4000;
    }

    .ant-table-cell-fix-right {
      right: -1px !important;
    }

    :where(.css-dev-only-do-not-override-ecti0p).ant-table-wrapper .ant-table-thead >tr>th, :where(.css-dev-only-do-not-override-ecti0p).ant-table-wrapper .ant-table-thead >tr>td {
      border-radius: 0 !important;

      .with-theme({
        border-bottom: 1px solid @vars[neutral-200-to-neutral-dark-200] !important;
      });

      &.ant-table-cell-fix-right {
        right: 0 !important;
      }
    }

    @media @viewport-mobile {
      :where(.css-dev-only-do-not-override-ecti0p).ant-table-wrapper .ant-table-thead >tr>th, :where(.css-dev-only-do-not-override-ecti0p).ant-table-wrapper .ant-table-thead >tr>td {
        .with-theme({
          border-bottom: none !important;
        });
      }
    }

    .ant-table-cell-fix-left-first::after,
    .ant-table-cell-fix-left-last::after,
    .ant-table-ping-right .ant-table-cell-fix-right-first::after,
    .ant-table-ping-right .ant-table-cell-fix-right-last::after,
    .ant-table-cell-fix-sticky {
      box-shadow: none !important;
      border-right: none !important;
    }

    .ant-table-cell-fix-sticky {
      border-bottom: none !important;
    }

    .ant-table-cell.ant-table-cell-fix-left.ant-table-cell-fix-left-last.ant-table-cell-fix-sticky {
      &:before {
        display: none;
      }
    }

    .ant-table-row-expand-icon-cell {
      text-align: left;
    }

    .icon-btn-expand-row {
      svg {
        transition: all .3s ease;
        path {
          fill: @bluePrimary;
        }
      }

      &.empty:not(.active) {
        svg {
          path {
            .with-theme({
              fill: @vars[neutral-300-to-neutral-dark-300]
            })
          }
        }
      }

      &.active {

        svg {
          transform: rotate(90deg);
          path {
            fill: @blueDepressed;
          }
        }

        &.empty {
          svg {
            path {
              fill: @bluePrimary;
            }
          }
        }

      }
    }

    .ant-table-cell.ant-table-row-expand-icon-cell{
      padding-right: 12px;
      .with-theme({
        background: transparent;
      })
    }

    .ant-table {
      background: transparent;

      &-body {

        &::-webkit-scrollbar {
          cursor: pointer !important;
          width: 4px;
          height: 0;
          border-radius: 2px;
        }
        &::-webkit-scrollbar-button {
        }
        &::-webkit-scrollbar-track {
        }
        &::-webkit-scrollbar-track-piece {
          border-radius: 2px;
          background: transparent;
        }
        &::-webkit-scrollbar-thumb {
          cursor: pointer !important;
          border-radius: 2px;
          .with-theme({
            background: @vars[neutral-200-to-neutral-dark-200];
          });
        }

        &::-webkit-scrollbar-thumb:hover {
          cursor: pointer !important;
        }
      }

      &-header {
        border-radius: 0 !important;

        .ant-table-cell-scrollbar {
          box-shadow: none;
        }

        table {
          border-radius: 0 !important;
        }
        .with-theme({
          background: @vars[white-to-neutral-dark];
        })
      }

      .ant-table-thead td {
        z-index: -1;
        .with-theme({
          background: @vars[neutral-50-to-neutral-dark-50];
        });

        @media @viewport-mobile {
          border-bottom: none !important;
        }

        &:before {
          display: none;
        }
      }

      &-column-sorters{
        width: fit-content;
        position: relative;
      }

      &-container{
        .ant-table-sticky-scroll{
          top: 37px;
          bottom: auto;
          position: absolute;
          border: none;
          opacity: 1;
          height: 4px!important;
          border-radius: 0;
          .with-theme({
            background: @vars[neutral-200-to-neutral-dark-200];
          });

          .ant-table-sticky-scroll-bar {
            cursor: pointer;
            height: 4px;
            background: @bluePrimary;
            border-radius: 2px;
          }
        }
      }

      &-expanded-row{
        .ant-table-cell{
          position: relative;
          &:after {
            position: absolute;
            content: '';
            bottom: 0;
            left: 24px;
            right: 50px;
            height: 1px;
            .with-theme({
              background: @vars[neutral-100-to-800];;
            })
          }

          &:last-of-type{
            .with-theme({
              border: none;
              background: transparent;
            });
          }

          .ant-table-expanded-row-fixed{
            padding: 16px 50px 16px 16px;

            @media @viewport-mobile {
              padding: 16px;
            }
          }
        }
      }

      &-cell{
        .icon-btn-arrow{
          display: flex;
          align-items: center;
          width: 24px;
          height: 24px;
          font-size: 24px;
          line-height: 24px;
          transform: rotate(-90deg);
          transition: transform 0.15s linear;

          &:hover, &.active{
            svg{
              fill: @bluePrimary;
            }
          }
          &.active {
            transform: rotate(0deg);
          }
        }
      }

      &-expanded-row{
        .ant-table-cell{
          position: relative;
          &:after {
            position: absolute;
            content: '';
            bottom: 0;
            left: 24px;
            right: 50px;
            height: 1px;
            .with-theme({
              background: @vars[neutral-100-to-800];;
            })
          }

          &.ant-table-cell-fix-right.ant-table-cell-fix-right-first{
            .with-theme({
              border: none;
              background: transparent;
            });
          }

          .ant-table-expanded-row-fixed{
           padding: 16px 50px 16px 16px;

            @media @viewport-mobile {
              padding: 16px;
            }
          }
        }
      }

      &-cell{
        .icon-btn-arrow{
          display: flex;
          align-items: center;
          width: 24px;
          height: 24px;
          font-size: 24px;
          line-height: 24px;
          &:hover, &.active{
            svg{
              fill: @bluePrimary;
            }
          }
        }
      }

      &-summary{
        .with-theme({
          background: @vars[neutral-100-to-800];
          color: @Neutral500;
        });

        .ant-table-cell-fix-left{
          .with-theme({
            background: @vars[neutral-100-to-800];
            color: @Neutral500;
          });
        }

        > tr > td {
          border-bottom: none;
          padding: 9px 0;

          &:first-of-type{
            padding-left: 32px;
          }
        }
      }

      &-empty{
        table{
          table-layout: auto!important;
          .ant-table-thead{
            tr th{
              left: auto!important;
            }
          }
        }
      }
    }

    .ant-table-column-sorter.ant-table-column-sorter-full {
      display: none !important;
    }

    td.ant-table-column-sort {
      background: transparent;
    }

    .ant-table-tbody > tr > td {
      padding: 0;
      transition: border-bottom-color 0s !important;
      border-bottom: none !important;
    }

    &.product-database-table-body_simple .ant-table-tbody > tr > td {
      height: 80px;
    }


    .ant-table-row {
      transition: all .3s ease;

      & > td:first-of-type {
        padding-left: 32px !important;
        border: none;
        position: relative;
      }
    }

    .ant-table-tbody > tr.ant-table-row > td {
      cursor: default !important;
    }

    .ant-table-tbody > tr.ant-table-row > td, .ant-table-cell-fix-left, .ant-table-cell-fix-right {
      cursor: pointer;
      .with-theme({
        background: @vars[white-to-neutral-dark];
        border-bottom: 1px solid @vars[neutral-200-to-neutral-dark-200] !important;
      })
    }

    .ant-table-tbody > tr.ant-table-row:hover > td {
      .with-theme({
        background: @vars[neutral-50-to-neutral-dark-50];
      })
    }

    .ant-table-cell-fix-left-last.ant-table-cell-fix-sticky {
      text-align: start;
    }

    .ant-table-thead > tr > th {
      &:first-of-type {
        padding: 4px 16px 4px 32px !important;
      }

      padding: 4px 16px !important;
      .with-theme({
        background: @vars[neutral-50-to-neutral-dark-50] !important;
      });

      &.ant-table-cell-fix-right-first {
        background: transparent;
      }
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.3px;
      .with-theme({
        color: @vars[neutral-600-to-neutral-dark-500];
      });

      &:before {
        display: none;
      }
    }

    .product-database-table-cell {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 80px;
      padding: 0 16px;
      .with-theme({
        color: @vars[neutral-800-to-100];
      });

      &:has(.product-price-history) {
        justify-content: center;
        padding: 0 48px 0 0;

        .product-price-history {
          width: 16px;

          svg path {
            fill: @bluePrimary;
          }

          &:hover svg path {
            fill: @blueDepressed;
          }
        }
      }

      &_clickable {
        cursor: pointer;
      }

      > a {
        position: relative;
        display: flex;
        align-items: center;
        flex: 1 1;
      }

      .product-database-table-image {
        width: 40px;
        height: 40px;
        margin-right: 8px;
        border-radius: 8px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        .with-theme({
          background: @vars[blue-100-to-neutral-dark-100];
        });

        & img {
          //max-width: unset;
          height: 100%;
          width: 100%;
          object-fit: contain;
          border-radius: 8px;
        }
      }

      &.cell-actions {
        padding: 0;
        margin: 0px;

        .product-actions {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 auto;
          gap: 8px;
          height: 100%;
          width: calc(100% - 24px);

          &:after {
            position: absolute;
            content: '';
            right: 0;
            bottom: -9px;
            width: 100%;
            height: 1px;
            .with-theme({
              background: @vars[neutral-100-to-800];
            });
          }

          .product-actions-options {
            transition: all .3s ease;
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            .with-theme({
              border: none;
              svg path {
                fill: @vars[neutral-500-to-neutral-dark-500];
              }
            });
            &-btn {
              transition: all .3s ease;
              width: 90px;
              gap: 4px;
              height: 40px;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              border-radius: 10px;
              border: none;
              background: @bluePrimary;
              color: @white;
              font-family: Inter, sans-serif;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 16px;

              @media @viewport-mobile {
                width: 40px;
              }

              &:hover {
                background: @blueDeep;
              }

              &.ant-dropdown-open {
                background: @blueDeep;
                .with-theme({
                  box-shadow: 0 0 0 4px @vars[neutral-100-to-800];
                })
              }

              &--tracked {
                background: @redPrimary;

                &:hover, &.ant-dropdown-open {
                  background: @redDepressed;
                }
              }

              svg path {
                stroke: @white;
              }
            }
          }
        }

        &_tracker{
          .product-actions {
            &:after {
              right: 0;
            }
            span {
              display: inline-flex;
              width: 24px;
              height: 24px;
              align-items: center;
              justify-content: center;

              &:last-of-type svg {
                margin-left: 0;
              }
            }
          }
        }
      }

      .product {

        &-info {
          display: flex;
          align-items: center;
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          flex-wrap: nowrap;
          text-overflow: ellipsis;
        }

        &-info-wrapper {
          position: relative;
          display: flex;
          flex-direction: column;
          gap: 4px;
          align-items: flex-start;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: -0.3px;
          margin-left: 5px;
          max-width: calc(100% - 90px);
          .with-theme({
            color: @vars[neutral-500-to-neutral-dark-500];
          });

          .product-links {
            text-decoration: underline;
            .with-theme({
              color: @vars[neutral-500-to-neutral-dark-500];
            });
          }

          .product-divider {
            .with-theme({
              margin: 0 8px;
              border-color: @vars[neutral-200-to-neutral-dark-200];
            });
          }

          .product-variants {
            padding-left: 4px;
            white-space: nowrap;
            .with-theme({
              color: @vars[neutral-600-to-neutral-dark-500];
            })
          }

          .product-is-deleted {
            margin-left: 7px;
          }

          .info-wrapper-text {
            display: flex;
            align-items: center;
          }

          & a {
            cursor: pointer;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .attention-outline-icon {
            margin-left: 4px;
            .with-theme ({
              fill: @vars[neutral-300-to-neutral-dark-300];
            });

            &:hover {
              fill: @bluePrimary;
            }
          }
        }

        &-name {
          user-select: none;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: -0.3px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          max-width: 100%;
          .with-theme({
            color: @vars[neutral-800-to-100];
          });

          &-tag {
            border-radius: @border-radius;
            padding: 4px 8px;
            font-family: 'Inter', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: -0.3px;
            text-transform: uppercase;
            color: @bluePrimary;
            margin-left: 5px;
            white-space: nowrap;
            .with-theme({
              background: @vars[blue-100-to-900];
            });

            &.untrackable {
              color: @redPrimary;
              .with-theme({
                background: @vars[red-100-to-900];
              });
            }

            &.gathering {
              color: @yellowPrimary;
              .with-theme({
                background: @vars[yellow-100-to-900];
              });
            }
          }
        }

        &-url {
          .with-theme({
            color: @vars[neutral-500-to-neutral-dark-500];
          });
          text-decoration: underline;

          &:hover, &:focus {
            .with-theme({
              color: @vars[neutral-500-to-neutral-dark-500];
            });
          }
        }

        &-suppliers {
          display: flex;
          align-items: center;
          gap: 8px;

          .link.with-img{
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        &-price {
          display: flex;
          flex-direction: column;
          justify-content: center;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: @bluePrimary;

          &-advanced {
            margin-top: 4px;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: -0.3px;
            .with-theme({
              color: @vars[neutral-500-to-neutral-dark-500];
            });
          }
        }

        &-store-info {
          p:last-of-type {
            margin-top: 4px;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.3px;
            .with-theme({
              color: @vars[neutral-600-to-neutral-dark-500];
            })
          }
        }
      }
    }
  }

  &-header {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    position: relative;
    padding: 0 32px;
    letter-spacing: -0.3px;
    .with-theme({
      color: @vars[neutral-800-to-100];
    });

    display: flex;
    justify-content: space-between;
    align-items: center;

    @media @viewport-mobile {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      padding: 0 16px;

      & > span {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      & > div {
        width: 100%;
      }
    }

    &-skeleton {
      display: flex;
      align-items: center;

      @media @viewport-mobile {
        justify-content: center;
      }

      span:not(:first-of-type) {
        margin-left: 8px;
      }
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -8px;
      height: 1px;
      width: 100%;
      .with-theme({
        background: @vars[neutral-200-to-neutral-dark-200];
      });
    }

    & > span {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .ant-dropdown-trigger {
      position: relative;
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: -0.3px;
      height: 30px;
      width: 83px;
      padding: 0 11px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
      border-radius: 6px;
      .with-theme({
        border: 1px solid @vars[neutral-200-to-neutral-dark-200];
        color: @vars[neutral-800-to-100];

        svg {
          transition: all .3s ease;
        }

        svg path {
          stroke: @vars[neutral-600-to-neutral-dark-500];
        }
      });

      &.ant-dropdown-open {
        svg {
          transform: rotate(180deg);
        }
      }
    }

    .page-size-dropdown {
      width: 100px;
      border-radius: 12px;
      .with-theme({
        background: @vars[white-to-neutral-dark];
        border: 1px solid @vars[neutral-200-to-neutral-dark-200];
        box-shadow: @vars[box-shadow-object-large];
      });

      &-list {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        gap: 4px;
        padding: 8px;

        &-item {
          font-family: 'Inter', sans-serif;
          font-weight: 400;
          letter-spacing: -0.3px;
          font-size: 14px;
          line-height: 20px;
          transition: all .3s ease;
          .with-theme({
            color: @vars[neutral-800-to-100];
          });
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 4px 8px;
          border-radius: 8px;
          width: 100%;

          &:hover {
            .with-theme({
              background: @vars[blue-100-to-neutral-dark-100] !important;
            })
          }
        }
      }
    }

    .database-page-number {
      font-family: 'Inter', sans-serif;
      font-weight: 500;
      margin: 0 8px;
      width: 70px;
      height: 32px !important;
      text-align: center;
      .with-theme({
        background: @vars[white-to-neutral-dark];
        border: 1px solid @vars[neutral-200-to-neutral-dark-200];
      });

      &:not([disabled]):hover {
        border-color: @bluePrimary;
      }

      &[disabled] {
        .with-theme({
          background: @vars[neutral-200-to-neutral-dark-200] !important;
        });

        &:hover {
          .with-theme({
            border: 1px solid @vars[neutral-200-to-neutral-dark-200];
          });
        }
      }

      &:focus-within {
        border-color: @bluePrimary;
        box-shadow: 0 0 0 2px rgba(34, 90, 234, .2);
      }
    }

    .database-page-button {
      &s {
        display: flex;
        margin-left: 16px;
      }

      width: 32px;
      height: 32px;
      background: transparent;
      padding: 0;
      .with-theme({
        border: 1px solid @vars[neutral-200-to-neutral-dark-200];
      });

      &-prev {
        margin-right: 8px;
        transform: rotate(90deg);
      }

      &-next {
        transform: rotate(-90deg);
      }

      &:not([disabled]):hover {
        border-color: @bluePrimary;
      }

      &:not([disabled]):hover svg > path {
        stroke: @bluePrimary !important;
      }

      svg > path {
        .with-theme({
          stroke: @vars[neutral-600-to-neutral-dark-500];
        })
      }

      &[disabled] {
        .with-theme({
          background: @vars[neutral-100-to-neutral-dark-100] !important;
          svg path {
            stroke: @vars[neutral-300-to-neutral-dark-300];
          }
        })
      }
    }
  }
}

.domains-tooltip {
  .ant-tooltip-inner {
    width: max-content;
  }
}

.unavailable-store-triangle-tooltip {
  .ant-tooltip-inner {
    width: 167px;
  }
}

.expandable-chart-info {
  text-align: right;
  span:first-of-type {
    font-family: 'Inter', sans-serif;
    margin-right: 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }
  svg {
    path {
      transition: all .1s ease;
    }

    &:hover path {
      filL: @bluePrimary !important;
    }
  }

  .with-theme({
    span {
      color: @vars[neutral-800-to-100];
    }
    svg path {
      fill: @vars[neutral-300-to-neutral-dark-300];
    }
  });

  &.with-toggle {
    display: flex;
    align-items: center;
    margin-top: 5px;

    & > span:first-of-type {
      margin-left: auto;

      @media @viewport-mobile {
        font-size: 10px;
        line-height: 16px;
      }
    }

    .switch-option {
      display: flex;
      align-items: center;
      gap: 8px;

      @media @viewport-mobile {
        margin-left: 0;
      }

      .ant-switch {
        width: 28px;
        min-width: unset;
        height: 16px;
        border-radius: 50px;
        .with-theme({
          background: @vars[neutral-200-to-neutral-dark-200];
        });

        &:focus, &:hover {
          outline: none;
          box-shadow: none;
        }

        &-handle {
          width: 12px;
          height: 12px;
          background: @white;
          box-shadow: none;
          border-radius: 50%;
        }

        &.ant-switch-checked {
          background: @bluePrimary;

          .ant-switch-handle {
            left: calc(100% - 14px);
          }
        }
      }

      p {
        font-family: 'Inter', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        .with-theme({
          color: @vars[white-to-neutral-dark];
        });

        @media @viewport-mobile {
          font-size: 10px;
          line-height: 16px;
        }
      }
    }
  }
}

.expandable-chart-info-tooltip {

  &-wrapper {
    max-width: 248px !important;
  }

  font-family: 'Inter', sans-serif;

  &-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }

  &-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    p:last-of-type {
      font-weight: 600;
    }
  }
}

.competitors-table-body .product-database-table-cell.cell-actions .product-actions{

  &:after {
    right: 4px;
  }
}

.ant-table-thead > tr > th {
  transition: none !important;
}

.user-trackings-table {
  margin-top: 0 !important;

  .ant-table-thead > tr > th:first-of-type {
    padding: 4px 16px 4px 48px !important;
  }

  .ant-table-cell-fix-sticky {
    text-align: left !important;
  }

  .product-database-table-cell.cell-actions .product-actions:after {
    display: none !important;
  }
}
