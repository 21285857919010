@import '../../../../../styles/theme/theme';
@import '../../../../../styles/base/ui-kit';
@import '../../../../../styles/utils/variables';

.details-images-block {
  margin-top: 48px;
  height: auto;
  &_title {
    font-size: 16px;
    line-height: 24px;
    color: @Neutral800;
    margin-bottom: 12px;
    span {
      color: @red500;
      display: inline-block;
    }
  }
  &_images {
    .images-main {
      width: 284px;
      height: 284px;
      border-radius: @border-radius;
      overflow: hidden;
      img {
        height: 100%;
      }
    }
    .images-list {
      min-height: 284px;
      &.empty_list {
        min-height: 135px;
        .images-list_upload {
          min-height: 135px;
          .ant-upload-list {
            padding-left: 0;
          }
        }
      }
      .images-list_upload {
        min-height: 284px;
        .ant-upload-list {
          display: flex;
          position: relative;
          padding-left: 302px;
          flex-wrap: wrap;
          .ant-upload-list-item-container {
            width: 135px;
            height: 135px;

            margin-bottom: 16px;
            margin-right: 15px;
            &:nth-child(6),
            &:nth-child(11) {
              margin-right: 0;
            }
            &:first-child {
              position: absolute;
              top: 0;
              left: 0;
              border-radius: @border-radius;
              width: 286px;
              height: 286px;
              .ant-upload-draggable-list-item {
                .ant-upload-list-item {
                  border-radius: @border-radius;
                  width: 286px;
                  height: 286px;
                  .ant-upload-list-item-actions {
                    top: 92%;
                    left: 8%;
                    .ant-btn {
                      .anticon-delete {
                        svg {
                          fill: @red500 !important;
                          width: 36px;
                          height: 36px;
                        }
                      }
                    }
                  }
                }
              }
            }
            .ant-upload-draggable-list-item {
              .ant-upload-list-item {
                border-radius: @border-radius;
                width: 135px;
                height: 135px;
                padding: 0;
                position: relative;

                &::before {
                  width: 100%;
                  height: 100%;
                  border-radius: @border-radius;
                  background: linear-gradient(180deg,
                  rgba(0, 0, 0, 0.5) 0%,
                  rgba(0, 0, 0, 0) 64.06%);
                  transform: rotate(-180deg);
                }

                .ant-upload-span {
                  .ant-upload-list-item-thumbnail {
                    opacity: 1;
                  }
                }
                .ant-upload-list-item-actions {
                  top: 80%;
                  left: 30%;
                  .ant-btn {
                    .anticon-delete {
                      svg {
                        fill: @red500 !important;
                        width: 24px;
                        height: 24px;
                      }
                    }
                  }
                  &::after {
                    position: absolute;
                    background-image: url('../../../../Images/move-icon.png');
                    background-size: contain;
                    background-position: center;
                    content: '';
                    width: 24px;
                    height: 24px;
                    left: 27px;
                    top: 0;
                    cursor: move;
                  }
                }
              }
              .ant-upload-list-item-uploading {
                width: 135px;
                height: 135px;
                border-radius: @border-radius;
                background-color: @blue100;
                border: 1px dashed @blue500;
                display: flex;
                justify-content: center;
                align-items: center;
                .ant-upload-list-item-info {
                  border-radius: @border-radius;
                  display: block;
                  color: @blue500;
                  font-size: 14px;
                  line-height: 16px;
                }
                .ant-upload-list-item-progress {
                  display: none;
                }
              }
            }
          }

          .ant-upload-select {
            width: 135px;
            height: 135px;
            background-color: @blue100;
            border-radius: @border-radius;
            border: 1px dashed @blue500;
            margin-right: 0;

            span {
              .ant-spin-nested-loading {
                .ant-spin-container {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  .images-add {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    span {
                      display: block;
                      color: @blue500;
                      font-size: 14px;
                      line-height: 16px;
                      margin-bottom: 8px;
                      &:last-child {
                        margin-bottom: 0;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      &_item {
        width: 135px;
        height: 135px;
        overflow: hidden;
        border-radius: @border-radius;
        img {
          height: 100%;
        }
      }
    }
    .images-add1 {
      width: 135px;
      height: 135px;
      background-color: @blue100;
      border-radius: @border-radius;
      border: 1px dashed @blue500;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      span {
        display: block;
        color: @blue500;
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 8px;
      }
    }
  }
}
