@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';

.product-details-card-body.filters-body {
  margin-top: 0;

  .product-main-block_wrapper {
    @media @viewport-mobile {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }

  .filters-title {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    letter-spacing: -0.3px;
    margin-bottom: 24px;
    .with-theme({
      color: @vars[neutral-800-to-100];
    });

    @media @viewport-mobile {
      margin-bottom: 16px;
    }

    svg {
      cursor: pointer;
    }
  }
}

.product-details-card-body {

  .input-filter-wrapper{
    .input-product-filter-wrapper{
      &:last-of-type{
        .input-product-filter-list{
          margin-bottom: 0;
        }
      }
    }
  }

  .selects-wrapper {
    gap: 16px;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 0 !important;

    @media @viewport-mobile {
      gap: 12px;
    }
  }

  .switch-wrapper {
    display: flex;
    justify-content: space-between;

    @media @viewport-mobile {
      margin-top: 0 !important;
    }
  }

  .switch-button-wrapper {
    display: flex;
    justify-content: space-between;

    @media @viewport-mobile {
      gap: 12px;
    }

    .switch-button-reset {
      background: transparent;
      transition: all .3s ease;
      padding: 6px 12px !important;
      height: 40px;
      border: none !important;
      border-radius: 10px;
      .with-theme({
        color: @vars[neutral-800-to-100];
        background: @vars[white-to-neutral-dark-50];
        box-shadow: @vars[box-shadow-btn-secondary];

        &:hover, &:focus, &:active {
          background: @vars[neutral-50-to-neutral-dark-100];
          color: @vars[neutral-800-to-100] !important;
        }
      });

      @media @viewport-mobile {
        width: calc(50% - 6px);
      }
    }

    .switch-button-apply {
      margin-left: 12px;
      background: @bluePrimary !important;
      color: @white !important;
      border: none !important;
      border-radius: 10px;
      padding: 6px 12px !important;

      &[disabled] {
        box-shadow: none !important;
        .with-theme({
          color: @vars[neutral-500-to-neutral-dark-500] !important;
          background: @vars[neutral-100-to-neutral-dark-100] !important;
        })
      }

      &:hover {
        background: @blueDepressed !important;
      }

      @media @viewport-mobile {
        margin-left: 0 !important;
        width: calc(50% - 6px);
      }
    }
  }

  .switch-options-wrapper {
    display: flex;

    @media @viewport-mobile {
      margin: 24px 0 !important;
      gap: 8px !important;
    }

    .switch-option {
      display: flex;
      align-items: center;
      margin-right: 16px;

      span {
        font-family: 'Inter', sans-serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.3px;
        .with-theme({
          color: @vars[neutral-800-to-100];
        })
      }
    }

    .ant-switch {
      width: 32px;
      min-width: unset;
      height: 20px;
      border-radius: 50px;
      margin-right: 12px;
      .with-theme({
        box-shadow: 0px 1px 2px 0px rgba(21, 30, 58, 0.05) inset, 0px 0px 7px 1px rgba(21, 30, 58, 0.04) inset;
        background: @vars[neutral-200-to-neutral-dark-200] !important;
      });

      &:focus, &:hover {
        outline: none;
        box-shadow: none;
      }

      &-handle {
        width: 16px;
        height: 16px;
        background: @white;
        box-shadow: none;
        border-radius: 50%;
      }

      &.ant-switch-checked {
        background: @greenToggle !important;

        .ant-switch-handle {
          left: calc(100% - 18px);
        }
      }
    }

  }

  .ant-collapse.ant-collapse-icon-position-start.ant-collapse-ghost {
    width: 100%;
  }

  .ant-collapse-header-text, .ant-collapse.ant-collapse-icon-position-left.ant-collapse-ghost {
    width: 100%;
  }

  .ant-collapse.ant-collapse-ghost {
    .ant-collapse-item {
      &.ant-collapse-no-arrow > .ant-collapse-header{
        padding: 24px 0;

        .ant-collapse-header-text {
          font-size: 14px;
          line-height: 20px;
          font-weight: 500;
          letter-spacing: -0.3px;
          .with-theme({
            color: @vars[neutral-500-to-neutral-dark-500];
          })
        }

        @media @viewport-mobile {
          padding: 16px 0;
        }
      }
      .ant-collapse-content > .ant-collapse-content-box {
       padding: 0;

        @media @viewport-mobile {
          padding: 0;
        }
      }
    }
  }


}

.title {
  &.skeleton.competitor-research {
    width: 226px;
    height: 32px;
    border-radius: @border-radius;

    span {
      width: 76px;
      height: 32px;
      border-radius: @border-radius;
    }
  }
}

.link.skeleton {
  .skeleton-animation-bg();
  border-radius: @border-radius;
}

.advanced-options-wrapper {
  width: 100%;
  transition: all .3s ease;

  &-active {
    overflow: unset !important;
  }

  .advanced-min-max {
    display: flex;

    @media @viewport-mobile {
      flex-direction: column;
    }

    > div {
      .min-max-input-wrapper:last-child{
        margin-bottom: 0;
      }
    }
  }
}

.ant-collapse-item.ant-collapse-no-arrow {
  .advanced-filters-toggle {
    svg {
      transition: all .3s ease;

      path {
        .with-theme({
          stroke: @vars[neutral-500-to-neutral-dark-500];
        });
      }
    }
  }

  &.ant-collapse-item-active {
    .advanced-filters-toggle {
      svg {
        transform: rotate(180deg);
      }
    }
  }
}

.advanced-filters-toggle {
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  .with-theme({
    color: @vars[neutral-500-to-neutral-dark-500];
  });

  &-divider {
    width: calc((100% - 215px) / 2);
    height: 1px;
    .with-theme({
      background: @vars[neutral-200-to-neutral-dark-200];
    });

    @media @viewport-mobile {
      width: calc((100% - 150px) / 2);
    }
  }

  &-wrapper {
    width: 100%;
    padding: 0 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media @viewport-mobile {
      padding: 0;
    }
  }
}

.custom-competitor-filter-block {
  position: relative;

  &:after {
    position: absolute;
    content: '';
    right: -3px;
    top: 0;
    height: 100%;
    width: 1px;
    .with-theme({
      background: @vars[neutral-200-to-neutral-dark-200];
    });

    @media @viewport-mobile {
      top: calc(100% + 24px);
      left: 0;
      right: unset;
      width: calc(100% - 32px);
      height: 1px;
    }
  }
}
