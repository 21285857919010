@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';

.select-block {
  width: calc(50% - 8px);
  position: relative;

  @media @viewport-mobile {
    width: 100%;
  }

  &_label{
    width: 100%;
    display: inline-block;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 8px;
    letter-spacing: -0.3px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    .with-theme({
      color: @vars[neutral-800-to-100];
    });
  }
}


.ant-dropdown {
  &-trigger.dropdown-database {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 12px;
    height: 40px;
    border-radius: 10px;
    transition: border 0.3s linear;
    .with-theme({
      background: @vars[white-to-neutral-dark];
      border: 1px solid @vars[neutral-200-to-neutral-dark-200];
      color: @vars[neutral-800-to-100];
    });

    svg {
      transition: transform 0.2s linear, color 0.2s linear;
    }

    &:not([disabled]):hover {
      .with-theme({
        border: 1px solid @bluePrimary;
      });
    }

    &.ant-dropdown-open {
      .with-theme({
        border: 1px solid @bluePrimary;
      });

      svg {

        &:last-of-type {
          transform: rotateZ(180deg);
        }
      }
    }

    .dropdown-database_value {
      margin: 0px 8px;
      white-space: nowrap;
      overflow: hidden;
      flex: 1 1;
      .with-theme({
        color: @vars[neutral-800-to-100];
      });
    }
  }


  .dropdown-database {
    &-menu {
      border-radius: 12px;
      padding: 0;
      .with-theme({
        background: @vars[white-to-neutral-dark];
        border: 1px solid @vars[neutral-200-to-neutral-dark-200];
      });
      overflow: hidden;

      .ant-input-affix-wrapper.dropdown-search.input {
        margin-bottom: 8px;
        height: 40px;
        padding: 6px 12px;
        border: none !important;
        border-radius: 0;
        .with-theme({
          background: @vars[white-to-neutral-dark];
          border-bottom: 1px solid @vars[neutral-200-to-neutral-dark-200] !important;
        });

        .ant-input-prefix {
          margin-right: 8px;
        }

        &:hover{
          .with-theme({
            border-bottom: 1px solid @vars[neutral-200-to-neutral-dark-200];
          })
        }

        &.ant-input-affix-wrapper-focused{
          border-bottom: 1px solid  @bluePrimary !important;
        }

        > .ant-input {
          .with-theme({
            color: @vars[neutral-800-to-100];
            background: @vars[white-to-neutral-dark];

            &::placeholder {
              color: @vars[neutral-400-to-neutral-dark-400];
            }
          });
        }
      }
    }

    &-list {

      &.empty {
        width: 100%;
        align-items: center;
        padding-bottom: 4px;
        gap: 8px;

        .dropdown-database-list-item {
          width: calc(100% - 16px);
          padding: 8px;
          border-radius: 10px;
          .with-theme({
            box-shadow: @vars[box-shadow-circle];
            background: @vars[white-to-neutral-dark-50];
          })
        }

        .empty-state_wrapper-text {
          width: calc(100% - 16px);
          text-align: center;
          font-family: 'Inter', sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: -0.3px;
          .with-theme({
            color: @vars[neutral-600-to-neutral-dark-500];
          })
        }

        .empty-state_wrapper-select {
          margin: 0;
          font-size: 16px;
          line-height: 20px;
          font-weight: 500;
          letter-spacing: -0.3px;
          .with-theme({
            color: @vars[neutral-800-to-100];
          })
        }
      }

      margin-top: 8px;
      overflow-y: auto;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      gap: 4px;
      padding: 0 8px;

      &::-webkit-scrollbar {
        width: 4px;
        border-radius: 2px;
      }
      &::-webkit-scrollbar-button {
        display: none;
      }
      &::-webkit-scrollbar-track-piece {
        border-radius: 2px;
        background: transparent;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 2px;
        .with-theme({
          background: @vars[neutral-200-to-neutral-dark-200];
        });
      }

      &-item {
        cursor: pointer;

        &.test-item {
          display: flex;
          align-items: center;
          padding: 8px;
          margin-top: 1px;
          width: 100%;
          font-family: 'Inter', sans-serif;
          font-weight: 500;
          letter-spacing: -0.3px;
          font-size: 14px;
          line-height: 20px;
          transition: all .3s ease;
          .with-theme({
            color: @vars[neutral-800-to-100];
          });

          & > span.value {
            white-space: pre-wrap;
            max-width: calc(100% - 30px);

            .country-icon {
              width: 20px;
              height: 20px;
              object-fit: cover;
              border-radius: 6px;
              margin-right: 8px;
            }
          }

          .info-wrap {
            margin-left: 5px;
            span:first-of-type {
              display: block;
            }
            span:last-of-type {
              font-size: 12px;
              font-weight: 500;
              line-height: 20px;
              .with-theme({
                color: @vars[neutral-600-to-neutral-dark-500];
              });
            }
          }
        }

        &-hidden {
          display: none !important;
        }

        & .dropdown-database-list-item-checkbox {
          position: relative;
          margin-right: 10px;
          transition: all .3s ease;
          border-radius: @border-radius;
          width: 20px;
          height: 20px;
          .with-theme({
            border: 1.5px solid @vars[neutral-200-to-neutral-dark-200];
          });

          &:hover {
            border: 1.5px solid @bluePrimary;
          }

          &--active {
            background: @bluePrimary;
            border: 1.5px solid @bluePrimary !important;

            &:after {
              content: url("../../../Images/checkbox-mark.svg");
              left: 50%;
              top: 50%;
              font-size: 0;
              border: none;
              width: 16px;
              height: 16px;
              transform: scale(1) translate(-50%, -50%);
              position: absolute;
            }

            span {
              content: '';
              position: absolute;
              top: -1px;
              left: -1px;
              bottom: -1px;
              right: -1px;
              border-radius: @border-radius;
              border: 0 solid rgba(34, 90, 234, .2);
              opacity: 1;
              -webkit-animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
              animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
              -webkit-animation-fill-mode: forwards;
              animation-fill-mode: forwards;
              display: block;
              pointer-events: none;
            }
          }
        }

        &:hover {
          .with-theme({
            background: @vars[blue-100-to-900];
          });
          .dropdown-database-list-item-checkbox {
            .with-theme({
              border: 1.5px solid @bluePrimary;
            });
          }
        }
      }
    }
  }
}

.dropdown-database-wrapper {
  z-index: 1100;
  top: 75px !important;
  width: 232px !important;
}

@-webkit-keyframes waveEffect {
  100% {
    top: -6px;
    left: -6px;
    bottom: -6px;
    right: -6px;
    border-width: 6px;
  }
}
@keyframes waveEffect {
  100% {
    top: -6px;
    left: -6px;
    bottom: -6px;
    right: -6px;
    border-width: 6px;
  }
}
@-webkit-keyframes fadeEffect {
  100% {
    opacity: 0;
  }
}
@keyframes fadeEffect {
  100% {
    opacity: 0;
  }
}
