@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';

.top-page-limits-wrapper{
  display: flex;
  align-items: center;
  .top-page-limits{
    display: flex;
    align-items: center;
    border-radius: 8px;
    height: 36px;

    .with-theme({
      border: 1px solid @vars[neutral-200-to-neutral-dark-200];
      background: @vars[neutral-50-to-neutral-dark-50];

      @media @viewport-mobile {
        border:unset;
        border-top: 1px solid @vars[neutral-200-to-neutral-dark-200];
      }
    });

    @media @viewport-mobile {
      flex-direction: column;
      border-radius: unset;
      width: 100vw;
      max-width: 100%;
    }

    .top-page-limits-title{
      padding: 8px 12px;
      border-radius: 8px;
      .with-theme({
        color: @vars[neutral-600-to-neutral-dark-500];
      });
      @media @viewport-mobile {
        text-align: center;
      }
    }

    .limits-vertical-divider{
      height: 32px;
      width: 1px;
      .with-theme({
        background: @vars[neutral-200-to-neutral-dark-200];
      });
      @media @viewport-mobile {
        display: none;
      }
    }
    .limits-vertical-divider-mobile{
      height: 32px;
      width: 1px;
      .with-theme({
        background: @vars[neutral-200-to-neutral-dark-200];
      });
      @media @viewport-mobile {
        width: 2px;
      }
    }

    .limits-values{
      display: flex;
      padding: 8px 12px;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      .with-theme({
        background: @vars[white-to-neutral-dark];
        color: @vars[neutral-500-to-neutral-dark-500]
      });
      span{
        &.green{
          color: @greenPrimary;
        }
        &.orange{
          color: @orangePrimary;
        }
        &.second-color{
          .with-theme({
            color: @vars[neutral-600-to-100]
          });
        }
      }
      @media @viewport-mobile {
        text-align: center;
        width: 100vw;
        max-width: 100%;
        border-radius: unset;
        display: flex;
        align-items: center;
        justify-content: center;

        .with-theme({
          border-top: 1px solid @vars[neutral-200-to-neutral-dark-200];
          border-bottom: 1px solid @vars[neutral-200-to-neutral-dark-200];
        });
      }
    }
    .limits-values-title{
      margin-left: 2px;
    }
  }
}

.both-limits-wrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  @media @viewport-mobile {
    width: 100%;
  }
}