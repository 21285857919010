@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';

.store-apps-modal-wrapper {
  .store-apps-wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
    min-height: 225px;
    max-height: 530px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      cursor: pointer !important;
      width: 0;
      height: 0;
      border-radius: 2px;
    }
    &::-webkit-scrollbar-track-piece {
      border-radius: 2px;
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      cursor: pointer !important;
      border-radius: 2px;
      .with-theme({
        background: @vars[neutral-200-to-neutral-dark-200];
      });
    }

    .empty-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 40px;
      margin-left: 8px;

      svg {
        margin-bottom: 16px;
      }

      p {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        width: 60%;
        text-align: center;
        .with-theme({
          color: @vars[neutral-300-to-700];
        })
      }
    }
  }

  .store-app {

    &-wrapper {
      width: 100%;
      padding: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 12px;
      .with-theme({
        border: 1px solid @vars[neutral-200-to-neutral-dark-200];
      });

      @media @viewport-mobile {
        align-items: flex-start;
        flex-direction: column;
        gap: 12px;
        padding: 12px;
      }
    }

    &-info {
      display: flex;
      align-items: center;
    }

    &-image {
      margin-right: 16px;

      img {
        border-radius: @border-radius;
      }
    }

    &-name {
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      .with-theme({
        color: @vars[neutral-800-to-200];
      })
    }

    &-item {
      display: flex;
      align-items: center;

      &-divider {
        .with-theme({
          border-color: @vars[neutral-200-to-neutral-dark-200];
        })
      }

      &-text {
        display: flex;
        align-items: center;
        gap: 4px;

        svg path {
          .with-theme({
            stroke: @vars[neutral-400-to-neutral-dark-400];
          });
        }
      }
    }

    &-rating, &-review {
      font-size: 12px;
      .with-theme({
        color: @vars[neutral-600-to-neutral-dark-500];

        span:first-of-type {
          color: @vars[neutral-800-to-100];
        }
      });
    }

    &-button {
      padding: 8px 12px;
      border-radius: 8px;

      .with-theme({
        border: 1px solid @vars[neutral-200-to-neutral-dark-200];
        background: @vars[white-to-neutral-dark-50] !important;
        &:hover, &:focus {
          background: @vars[neutral-50-to-neutral-dark-100] !important;
        }
      });

      @media @viewport-mobile {
        width: 100%;
      }

      span {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        transition: all .3s ease;
        .with-theme({
          color: @vars[neutral-800-to-200];
        })
      }
    }

  }

}
