@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';

.sales-tracker-unavailable-note {

  &-wrapper {
    width: 100%;
    border-radius: @border-radius;
    border: 1px solid @yellowPrimary;
    .with-theme({
      background: @vars[yellow-100-to-900];
    });
    padding: 16px;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }

  &-title {
    span {
      margin-left: 11px;
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      .with-theme({
        color: @vars[neutral-900-to-200];
      })
    }
  }
}
