@import '../../../styles/theme/theme';
@import '../../../styles/base/animation';
@import '../../../styles/base/ui-kit';
@import '../../../styles/utils/variables';

.portfolio-page-product-list {

  .ant-spin-nested-loading {
    overflow: visible !important;
  }

  .search {
    padding: 24px 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .with-theme({
      border-bottom: 1px solid @vars[neutral-200-to-neutral-dark-200];
    });

    @media @viewport-mobile {
      flex-direction: column;
      align-items: flex-start;
      padding: 16px;
      gap: 12px;
    }

    &-wrapper {
      display: flex;
      align-items: center;
      gap: 12px;

      @media @viewport-mobile {
        width: 100%;
      }
    }

    &-input {
      width: 400px;
      margin-right: 0;

      @media @viewport-mobile {
        width: calc(100% - 82px);
      }
    }

    &-submit {
      transition: all .3s ease;
      cursor: pointer;
      display: flex;
      align-items: center;
      border: none !important;
      height: 40px;
      padding: 6px 12px;
      border-radius: 10px;
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.3px;
      .with-theme({
        color: @vars[neutral-800-to-100];
        background: @vars[white-to-neutral-dark-50];
        box-shadow: @vars[box-shadow-btn-secondary];

        &:hover, &:focus {
          background: @vars[neutral-50-to-neutral-dark-100];
        }

        &.disabled {
          cursor: not-allowed;
          background: @vars[neutral-100-to-neutral-dark-100];
        }
      })
    }

    .product-view {
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 4px;
      border-radius: 10px;
      .with-theme({
        background: @vars[neutral-50-to-neutral-dark-100];
        border: 1px solid @vars[neutral-200-to-neutral-dark-200];
      });

      &.wide {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        letter-spacing: -0.3px;
        .with-theme({
          color: @vars[neutral-800-to-100];
        });

        .product-view-btn {
          width: 50%;
          gap: 8px;
        }
      }

      &-btn {
        transition: all .3s ease;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        .with-theme({
          svg path {
            stroke: @vars[neutral-600-to-neutral-dark-500];
          }
        });

        &--active {
          box-shadow: 0px 5px 10px -2px rgba(25, 28, 33, 0.06), 0px 2px 6px -2px rgba(106, 115, 133, 0.12), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
          .with-theme({
            background: @vars[white-to-neutral-dark-100];

            svg path {
              stroke: @vars[neutral-800-to-100];
            }
          });
        }
      }
    }

    &-sort-menu {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;

      &-inner {
        transition: all .3s ease;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 8px;
        border: none !important;
        height: 40px;
        padding: 6px 12px;
        border-radius: 10px;
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.3px;
        .with-theme({
          color: @vars[neutral-800-to-100];
          background: @vars[white-to-neutral-dark-50];
          box-shadow: @vars[box-shadow-btn-secondary];

          &:hover, &:focus {
            background: @vars[neutral-50-to-neutral-dark-100];
          }

          svg path {
            stroke: @vars[neutral-600-to-neutral-dark-500];
          }
        });

        @media @viewport-mobile {
          width: calc(100% - 86px);
          justify-content: center;
        }
      }

      &-wrapper {
        width: 215px;
        height: 284px;
        padding: 8px;
        overflow-y: scroll;
        border-radius: 12px;
        .with-theme({
          background: @vars[white-to-neutral-dark-50];
          border: 1px solid @vars[neutral-200-to-neutral-dark-200];
          box-shadow: @vars[box-shadow-object-large];
        });

        &::-webkit-scrollbar {
          width: 4px;
          border-radius: 2px;
        }
        &::-webkit-scrollbar-button {
          display: none;
        }
        &::-webkit-scrollbar-track-piece {
          background: transparent;
          border-radius: 2px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 2px;
          .with-theme({
            background: @vars[neutral-200-to-neutral-dark-200];
          });
        }
        &::-webkit-scrollbar-track-piece:start {
          background: transparent;
          margin-top: 10px;
        }
        &::-webkit-scrollbar-track-piece:end {
          background: transparent;
          margin-bottom: 10px;
        }
      }

      &-item {
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.3px;
        padding: 8px;
        cursor: pointer;
        transition: all .3s ease;
        width: 100%;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .with-theme({
          color: @vars[neutral-800-to-100];

          &:hover {
            background: @vars[neutral-100-to-neutral-dark-100];
          }
        });

        &-wrapper {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 4px;
        }
      }
    }
  }

  .link.skeleton {
    display: block;
  }
}

// TOOLTIP
.details-tooltip {
  .ant-tooltip-inner,
  .ant-tooltip-arrow-content {
    white-space: wrap;
    .with-theme({
      background-color: @vars[neutral-900-to-neutral-dark-50];
    });
  }

  &.shipping {
    max-width: 413px;

    @media @viewport-mobile {
      max-width: (calc(100vw - 50px));
    }
  }

  &.p_c_ratio,
  &.fees {
    max-width: 386px;

    @media @viewport-mobile {
      max-width: (calc(100vw - 50px));
    }
  }

  &.pot_profit {
    max-width: 481px;

    @media @viewport-mobile {
      max-width: (calc(100vw - 50px));
    }
  }

  &.product_name-tooltip {
    max-width: 300px;

    @media @viewport-mobile {
      max-width: (calc(100vw - 50px));
    }
  }

  &.BECPA {
    max-width: 301px;
  }

  &.BEROAS {
    max-width: 305px;

    @media @viewport-mobile {
      max-width: (calc(100vw - 50px));
    }
  }

  &.competitors {
    max-width: 292px;

    @media @viewport-mobile {
      max-width: (calc(100vw - 50px));
    }
  }

  &.saturation {
    max-width: 401px;
  }

  &.score {
    max-width: 363px;

    @media @viewport-mobile {
      max-width: (calc(100vw - 50px));
    }
  }

  &.price {
    max-width: 358px;

    @media @viewport-mobile {
      max-width: (calc(100vw - 50px));
    }
  }

  &.orders {
    max-width: 271px;

    @media @viewport-mobile {
      max-width: (calc(100vw - 50px));
    }
  }

  &.CPA {
    max-width: 336px;

    @media @viewport-mobile {
      max-width: (calc(100vw - 50px));
    }
  }
}
