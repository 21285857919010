@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';
@import '../../../../styles/utils/mixin';

.ad-spot-nav-header {
  padding: 0 32px;
  .with-theme({
    border-top: 1px solid @vars[neutral-200-to-neutral-dark-200];
    border-bottom: 1px solid @vars[neutral-200-to-neutral-dark-200];
  });

  @media @viewport-mobile {
    padding: 0 16px;
  }

  &-btn-wrapper {
    display: flex;
    align-items: center;

    @media @viewport-mobile {
      justify-content: center;
    }
  }

  &-btn {
    height: 46px;
    padding: 0 12px;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .with-theme({
      color: @vars[neutral-600-to-100];
    });

    @media @viewport-mobile {
      font-size: 14px;
      line-height: 16px;
    }

    &-count {
      margin-left: 10px;
      .with-theme({
        color: @vars[neutral-600-to-neutral-dark-400];
      });
    }

    &:not(.active):hover {
      color: @bluePrimary;
    }

    &.active {
      position: relative;
      cursor: default;
      
      .with-theme({
        color: @vars[neutral-800-to-100];
      });
    }
    &.active:after {
      content: '';
      position: absolute;
      bottom: -1px;;
      width: 32px;
      border-bottom: 2px solid @bluePrimary;
    }
    &.disabled {
      cursor: not-allowed;
      border: 1px solid @Neutral200;
      .with-theme({
        background: @vars[neutral-100-to-800];
      });
      span {
        color: @Neutral500;
        margin-left: 5px;
      }
    }
  }
}
