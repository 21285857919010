@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';
@import '../../../../styles/utils/mixin';

.product-database-empty-block{
  position: relative;
  overflow: hidden;
  height: 300px;
  width: 100%;
  .border-radius();
  .border();

  padding: 64px;
  margin-top: 32px;

  background-color: @blueLight;
  background-image: url("../../../Images/database_empty_bg.png");
  background-size: 892px 300px;
  background-repeat: no-repeat;
  background-origin: border-box;
  background-position: right;

  &.dark{
    background-color: @Neutral800;
    background-image: url("../../../Images/database_empty_bg_dark.png");

    @media @viewport-mobile {
      background-image: none;
    }
  }

  &.skeleton-banner {
    background-image: unset;

    .skeleton-banner-rectangle {
      width: 800px;
      height: 900px;
      position: absolute;
      right: -300px;
      top: -87px;
      transform: rotate(55deg);

      @media @viewport-mobile {
        position: unset;
        display: block;
        width: 100%;
        height: 332px;
        margin-top: 30px;
        transform: unset;
      }
    }
  }


  .info-wrp{
    width: 380px;

    @media @viewport-mobile {
      width: 100%;

      span {
        max-width: 100%;
      }
    }
  }

  .some_title_styles();

  @media @viewport-mobile {
    position: relative;
    min-height: 584px;
    background-image: none;
    padding: 15px;

    &:not(.skeleton-banner){
      height: calc(var(--app-height) - 71px - 40px - 82px - 32px);

      &:after{
        display: block;
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        width: 360px;
        height: 348px;
        background-image: url("../../../Images/database_empty_bg_mobile.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: bottom right;
      }


    &.dark{

      &:after{
        background-image: url("../../../Images/database_empty_bg_dark_mobile.png");
      }
    }
    }
  }
}
