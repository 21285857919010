@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';
@import '../../../../styles/utils/mixin';

.report-main-block{

  .main-info.fad-spot {
    position: relative;
  }

  .switch-option {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-left: auto;

    @media @viewport-mobile {
      margin-left: 0;
    }

    .ant-switch {
      width: 32px;
      min-width: unset;
      height: 20px;
      border-radius: 50px;
      .with-theme({
        background: @vars[neutral-200-to-700];
      });

      &:focus, &:hover {
        outline: none;
        box-shadow: none;
      }

      &-handle {
        width: 16px;
        height: 16px;
        background: @white;
        box-shadow: none;
        border-radius: 50%;
      }

      &.ant-switch-checked {
        background: @bluePrimary;

        .ant-switch-handle {
          left: calc(100% - 18px);
        }
      }
    }

    p {
      font-family: 'Inter', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      .with-theme({
        color: @vars[color-dark-to-white];
      })
    }
  }

  .main-block-calendar {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;


    span {
      .with-theme({
        color: @vars[neutral-800-to-200];
      });
    }

    .select-block {
      width: 100px !important;
    }
  }

  &-logo {
    width: 64px;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
    border-radius: @border-radius;
    .with-theme({
      background: @vars[blue-100-to-neutral-900];
      border: 1px solid @vars[neutral-100-to-800];
    });

    img {
      width: fit-content;
      max-width: 100%;
      height: auto;
      max-height: 100%;
      object-fit: contain;
      border-radius: @border-radius;
    }
  }

  border-radius: @border-radius;
  padding: 23px;
  display: flex;
  align-items: center;
  
  &:not(.report-main-block-new) {
    @media @viewport-mobile {
      padding: 0;
      border: none !important;
      flex-direction: column;
      gap: 16px;
      margin-top: 0;
      margin-bottom: 7px;

      & > button {
        width: 100%;
      }
    }
  }

  .with-theme({
    border: 1px solid @vars[neutral-100-to-800];
  });

  &_title {

    &-tag {
      user-select: none;
      color: @redPrimary;
      border-radius: @border-radius;
      padding: 4px 8px;
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      text-transform: uppercase;
      margin-left: 5px;
      .with-theme({
        background: @vars[red-100-to-900];
      });
    }

    &.fad-spot {
      display: flex;
      align-items: center;
      .with-theme({
        color: @vars[neutral-800-to-200];
      });

      & > span {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 32px;
        word-break: normal;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 550px;
        display: block;

        @media @viewport-mobile {
          font-size: 16px;
          line-height: 20px;
          max-width: 200px !important;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &.overflowing:after {
          z-index: 10;
          width: fit-content;
          display: none;
          position: absolute;
          left: 80px;
          top: 40px;
          max-width: 100%;
          white-space: break-spaces;
          border-radius: @border-radius;
          font-family: 'Inter', sans-serif;
          padding: 16px;
          font-size: 12px;
          line-height: 16px;
          background: @Neutral800;
          color: @white;
          content: attr(data-text);
        }

        &:hover:after {
          display: block;
        }
      }
    }
  }

  .main-link > a > span {
    @media @viewport-mobile {
      max-width: 150px !important;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .main-title:has(span.ellipsis.overflowing) {
    position: relative;
  }

  &_title:not(.fad-spot) {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 32px;
    display: flex;
    align-items: center;
    .with-theme({
      color: @vars[neutral-800-to-200];
    });

    span.ellipsis {
      word-break: normal;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 550px !important;

      &.overflowing {

        &:before {
          position: absolute;
          left: 0;
          top: 30px;
          width: max-content;
          max-width: 100%;
          white-space: break-spaces;
          border-radius: 6px;
          padding: 16px;
          font-size: 12px;
          line-height: 16px;
          background: @Neutral800;
          color: @white;
          text-align: left;
          content: attr(data-text);
          display: none;
        }

        &:hover:before {
          display: block;
        }
      }
    }

    @media @viewport-mobile {
      font-size: 16px;
      line-height: 20px;
      max-width: 200px !important;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &_link{
    white-space: nowrap;
    margin-right: 16px;
    
    @media @viewport-mobile {
      margin: 0;    
    }

    .icon{
      margin-left: 8px;
      path{
        fill: @bluePrimary;
      }
    }

    &:hover{
      .icon{
        path{
          fill: @blueDepressed;
        }
      }
    }
  }

  .ant-btn.btn-primary.white{
    height: 40px;
    padding: 11px 19px;
    margin-left: auto;
    margin-right: 16px;
    background: transparent;
    min-width: 156px;

    .with-theme({
      color: @vars[neutral-800-to-white];
      border-color: @vars[neutral-100-to-800];
      &:not([disabled]):hover {
        border-color: @bluePrimary;
        color: @bluePrimary;
      }
    });
  }

  .ant-checkbox-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    font-family: 'Inter', sans-serif;
    font-weight: 400;
    min-height: 24px;
    width: fit-content;
    line-height: 20px;
    .with-theme({
      color: @vars[neutral-800-to-200];
    });

    &:hover {
      .ant-checkbox {
        .ant-checkbox-inner {
          .with-theme({
            border-color: @bluePrimary;
          });
        }
      }
    }

    .ant-checkbox {
      top: 0;

      .ant-checkbox-inner {
        width: 20px;
        height: 20px;

        .with-theme({
          border: 1.5px solid @vars[neutral-200-to-700];
          background-color: @vars[color-white-to-dark];
        });

        &:after {
          content: url("../../../Images/checkbox-mark.svg");
          left: 50%;
          top: 50%;
          font-size: 0;
          border: none;
          width: 16px;
          height: 16px;
          transform: scale(1) translate(-50%, -50%);
        }
      }

      &.ant-checkbox-checked {
        .ant-checkbox-inner {
          .with-theme({
            border-color: @bluePrimary;
            background-color: @bluePrimary;
          });
        }
      }
    }

    .info-wrap {
      margin-left: 8px;

      span {
        display: block;
      }

      span:last-of-type {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: @Neutral500;
      }
    }
  }

  .dropdown-sales_tracker-wrapper {
    top: 48px !important;
    @media @viewport-mobile {
      top: 45px !important;
    }
  }

  .dropdown-sales_tracker-wrapper-inner{
    .dropdown-database-list {
      margin-top: 0;
    }
  }

  &-skeleton {
    .link.skeleton {
      display: inline-block;
    }
  }
}
