@import '../../../styles/theme/theme';
@import '../../../styles/base/ui-kit';

.product-database-page {
  padding: 0 !important;
  overflow: unset !important;
  overflow-x: visible !important;
  height: 100dvh;
  overscroll-behavior: contain;
  font-family: 'Inter', sans-serif;

  @media @viewport-mobile {
    overflow-x: hidden !important;
    max-width: 100vw;
  }

  .sales-tracker-top-page-header {
    margin-bottom: 48px;

    .title-wrapper-icon {
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0.00) 100%), #16895A;
      border: 1px solid @greenDeep;

      svg path {
        fill: white;
      }
    }
  }

  .sales-tracker-top-page-limits-wrapper {
    gap: 12px;
  }

  &-no_scroll {
    overflow-y: hidden !important;
  }

  .link.disabled {
    pointer-events: none;
  }

  .block-with-label-custom {
    &:after {
      width: 1px;
      height: calc(100% - 16px);
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      .with-theme({
        background: @vars[neutral-200-to-neutral-dark-200];
      })
    }
  }

  .product-details-card {
    @media @viewport-mobile {
      padding: 0;
    }
  }

  .product-details-card-header {
    @media @viewport-mobile {
      margin-top: 20px;
      padding: 0 24px;
    }
  }

  .product-details-card-header .header-tag {
    font-size: 12px;
    line-height: 16px;
    padding: 8px 16px;
  }

  @media @viewport-mobile {
    max-width: 100vw;
    padding: 20px;
    height: calc(var(--app-height) - 71px);
    overflow-y: auto;

    .empty-row {
      display: none;
      min-height: 110px;
    }
  }

  .product-database-mobile-banner {
    color: @yellowPrimary;
    padding: 16px;
    box-sizing: border-box;
    border: 1px solid @yellowPrimary;
    border-radius: @border-radius;
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-column-gap: 8px;
    .with-theme ({
      background: @vars[yellow-100-to-900];
    });

    span {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      .with-theme({
        color: @vars[neutral-800-to-200];
      })
    }
  }
}

.preset-notification-block {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  font-family: 'Inter', sans-serif;
  .with-theme({
    color: @vars[neutral-800-to-200];
  });

  @media @viewport-mobile {
    font-size: 12px;
    line-height: 16px;
  }
}

.preset-delete-notification {
  .notification__icon {
    .with-theme({
      background: @vars[red-100-to-900];
    });
  }
  .notification__name {
    width: 100%;
  }
}

.notification--restore {
  .notification__name {
    width: 100% !important;
  }
}
