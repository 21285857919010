@import '../../../styles/theme/theme';
@import '../../../styles/utils/variables';
@import '../../../styles/base/ui-kit';

.ant-notification {
  z-index: 10000000050;

  @media @viewport-mobile {
    top: 24px !important;
    bottom: unset !important;
  }
}

.notification--save-to-default {
  width: 400px !important;
}

.ant-notification-notice.custom-notification {
  padding: 12px;
  width: 500px;
  border-radius: 16px;
  margin-bottom: 0;
  .with-theme({
    background: @vars[white-to-neutral-dark];
    border: 1px solid @vars[neutral-200-to-neutral-dark-200];
    box-shadow: @vars[box-shadow-object-middle];
  });

  .ant-notification-notice-icon {
    display: none;
  }

  .ant-notification-notice-description {
    width: 100%;
    margin: 0;
    padding: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
  }

  .ant-notification-notice-message {
    display: none;
    margin: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding: 0;
  }
}

.notification {

  &_delete {
    .notification-text {
      max-width: 344px;
    }
  }

  &-wrapper {
    width: 100%;
    display: flex;
    align-items: flex-start;
    gap: 8px;
  }

  &-content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    font-family: 'Inter', sans-serif;
    width: calc(100% - 28px);

    @media @viewport-mobile {
      flex-direction: column;
      gap: 12px;
    }
  }

  &-text-block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }

  &-title {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.3px;
    margin: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    margin-block-start: 0;
    margin-block-end: 0;
    .with-theme({
      color: @vars[neutral-800-to-100];
    })
  }

  &-text {
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.3px;
    max-width: 290px;
    .with-theme({
      color: @vars[neutral-600-to-neutral-dark-500];
    });

    span {
      width: 100%;
      display: block;
    }

    &.wide {
      max-width: 100%;
    }

    .blue-text {
      cursor: pointer;
      color: @bluePrimary;

      &:hover {
        color: @blueDepressed;
      }
    }
  }

  &-btn {
    margin: 0;
    height: 36px;
    padding: 6px 12px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.3px;
    border-radius: 8px;
    cursor: pointer;
    transition: all .3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none !important;

    @media @viewport-mobile {
      width: 100%;
    }

    .with-theme({
      color: @vars[neutral-800-to-100] !important;
      background: @vars[white-to-neutral-dark-50];
      box-shadow: @vars[box-shadow-btn-secondary];

      &:hover, &:focus {
        background: @vars[neutral-50-to-neutral-dark-100];
      }
    });
  }
}
