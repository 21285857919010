@import '../../../styles/theme/theme';
@import '../../../styles/base/ui-kit';

.dashboard-page {
  max-width: calc(100vw - 72px);
  max-height: 100dvh;
  overscroll-behavior: contain;

  @media @viewport-mobile {
    max-width: 100vw;
  }

  margin: 0 auto !important;

  .link.skeleton {
    .skeleton-animation-bg();
    border-radius: @border-radius;
    display: block;
  }

  &-shopify {
    width: 100%;
    padding: 16px;
    margin-top: 24px;
    display: flex;
    align-items: center;

    .with-theme({
      border: 1px solid @vars[neutral-100-to-800];
      background: @vars[shopify-gradient];
      background-image: url("../../Images/shopifyDesktop.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      @media @viewport-mobile {
        align-items: flex-start;
        border: none;
        background-image: url("../../Images/shopifyMobile.png");
      }
    });
  border-radius: 16px;

  &.dark {
    .with-theme({
      border: 1px solid @vars[neutral-200-to-neutral-dark-200];
      background: @vars[shopify-gradient];
      background-image: url("../../Images/shopifyDesktopDark.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      @media @viewport-mobile {
        align-items: flex-start;
        border: none;
        background-image: url("../../Images/shopifyMobileDark.png");
      }
    });
}


&-text {
  width: 100%;
  max-width: unset;
  align-items: flex-start;
  margin-left: 22px;
  gap: 0;

  @media @viewport-mobile {
    margin-left: 0;
    align-items: flex-start;
    padding-bottom: 16px;
  }

  .dashboard-page-block-title {
    line-height: 20px;
  }

  .dashboard-page-block-subtitle {
    padding: 0;
    margin-top: 0;

    @media @viewport-mobile {
      margin-top: 12px;
    }
  }
}
}

&-blocks {
  display: grid;
  grid-template-rows: 222px;
  grid-template-columns: repeat(5, minmax(211px, 1fr));
  grid-gap: 16px;
  width: 100%;
  margin-top: 16px;

  &-title {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;

    .with-theme({
      color: @vars[neutral-800-to-200];
    });
  text-align: left;
  margin-bottom: 0;
}

&-subtitle {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  margin-top: 4px;
  margin-bottom: 0;

  .with-theme({
    color: @vars[neutral-600-to-neutral-dark-500];
  });
}

&-wrapper {
  padding: 32px;
  @media @viewport-mobile {
    padding: 16px;
  }
}
}

.university-page-course-wrapper {
  margin-top: 2px;
}

.category-list-wrapper {
  min-height: 292px;
  max-height: 292px;
  overflow: auto;

  >div:not(:last-of-type) {
    margin-bottom: 12px;
  }

  &.empty {
    min-height: auto;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .empty-product-list {
      padding-top: 0;

      @media @viewport-mobile {
        padding: 8px 0 48px;
        margin-bottom: 0;
      }

      .empty-product-list_header {
        text-align: center;
      }
    }

  }
}

.empty-row {
  height: 64px;
}

@media @viewport-mobile {
  height: calc(var(--app-height) - 71px);

  .skeleton-shopify {
    &-button {
      margin-top: 24px;
      width: 100% !important;
    }

    &-subtitle {
      width: 303px !important;
      height: 40px !important;
    }
  }

  &-shopify {
    height: unset;
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &-title {
      margin-top: 22px;
    }

    .dashboard-page-block-button {
      width: 100%;
    }
  }

  &-blocks {
    display: grid;
    margin-top: 0;
    grid-gap: 12px;
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 10px;


    &-subtitle {
      padding-bottom: 16px;
    }
  }

  .welcome-block {
    width: 100%;
    min-width: 100vw;
    margin-bottom: 20px;
    @media @viewport-mobile {
      margin-bottom: unset;
    }
  }

  .dashboard-upcoming-block {
    margin-right: 0;
    margin-bottom: 16px;
  }

  .category-list-wrapper {
    min-height: auto;
    max-height: max-content;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    >div:not(:last-of-type) {
      margin-bottom: 16px;
    }

    >div:last-of-type {
      margin-bottom: 16px;
    }
  }

  .empty-row {
    height: 20px;
  }
}
}

.dashboard-page-shopify-wrapper {
  display: flex;
  flex-direction: column;
  padding: 0 32px 16px 32px;
  @media @viewport-mobile {
    padding: 0 16px 16px 16px;
  }
}

.dashboard-page-block-button-second {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: @white;
  border-radius: 10px;
  outline: none;
  border: none;
  background: @bluePrimary;
  height: 40px;
  @media @viewport-mobile {
    width: 100%;
  }
  .with-theme({
    box-shadow: @vars[box-shadow-btn-primary];
  });
  &:not([disabled]):hover, &:not([disabled]):focus {
    background: @blueDepressed;
    color: @white;
  }
}