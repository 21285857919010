@import '../../../../../styles/theme/theme';
@import '../../../../../styles/base/ui-kit';
@import '../../../../../styles/utils/variables';

.suppliers-block {
  margin-top: 24px;
  margin-bottom: 24px;
  &_wrapper {
    width: 100%;
    .suppliers-search {
      display: inline-block;
      margin-bottom: 12px;
      width: 100%;
      .ant-input-wrapper {
        height: 48px;
        display: flex;
        width: 100%;
        input,
        span {
          height: 48px;
          display: inline-block;
        }
        .ant-input {
          font-size: 14px;
          line-height: 20px;
          border-radius: @border-radius;
          margin-right: 16px;
          color: @Neutral800;
          width: calc(100% - 112px);
          &::placeholder {
            font-size: 14px;
            line-height: 20px;
            .with-theme({
              color: @vars[neutral-400-to-neutral-dark-400];
            })
          }
        }
        .ant-input-group-addon {
          .ant-input-search-button {
            height: 48px !important;
            line-height: 48px;
            border-radius: @border-radius;
            width: 96px;
            span {
              font-size: 14px;
            }
          }
        }
      }
    }
    .block-with-list {
      min-height: 208px !important;
      align-items: flex-start;
      &.empty_data {
        align-items: center;
        min-height: 208px !important;
      }
      .supplier_item {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        max-height: 48px;
        margin: 12px 0;
        img {
          height: 48px;
          width: 48px;
          display: inline-block;
          margin-right: 16px;
        }
        .store--name {
          width: 252px;
          min-width: 252px;
          flex: 1 1;
          margin-right: 16px;
          padding-right: 10px !important;
          input {
            color: @Neutral800;
          }
          .ant-input-suffix {
            position: relative;
            padding-left: 9px;
            &::before {
              content: '';
              background-color: @Neutral100;
              width: 1px;
              height: 46px;
              top: -11px;
              left: 0;
              position: absolute;
            }
          }
        }
        .without-arrows {
          width: 105px;
          margin-right: 16px;
          padding-right: 5px;
          padding-left: 10px;
          &.alibaba {
            padding-left: 15px;
            .ant-input-number-input-wrap {
              &::before {
                content: 'U';
                font-size: 14px;
                line-height: 24px;
                color: #C5CCE3;
              }
              .ant-input-number-input {
                padding-left: 35px;
              }
            }
          }
          &.aliexpress {
            padding-left: 15px;
            .ant-input-number-input-wrap {
              &::before {
                content: 'S';
                font-size: 14px;
                line-height: 24px;
                color: #C5CCE3;
                padding-right: 0;
                width: 15px;
              }
              &::after {
                position: absolute;
                display: block;
                content: url('../../../../Images/price.svg');
                top: 0;
                left: 15px;
                width: 36px;
                height: 24px;
              }
              .ant-input-number-input {
                padding-left: 40px;
              }
            }
          }
          &.rating-star {
            .ant-input-number-input-wrap {
              &::before {
                content: url('../../../../Images/star-grey.svg');
                width: 30px;
              }
              .ant-input-number-input {
                padding-left: 38px;
              }
            }
          }
          &.years_open {
            width: 168px;
            padding-left: 15px;
            .ant-input-number-input-wrap {
              &::before {
                content: 'Years Open';
                width: 102px;
                font-size: 14px;
                line-height: 20px;
                color: #C5CCE3;
                top: 2px;
              }
              .ant-input-number-input {
                padding-left: 115px;
              }
            }
          }
          .ant-input-number-input-wrap {
            &::before {
              width: 25px;
            }
            input {
              padding-left: 30px;
              color: @Neutral800;
              letter-spacing: -1px;
            }
          }
        }
        .cost-divider {
          margin-left: -12px;
          margin-right: 2px;
        }
      }
    }
  }
}
