@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';
@import '../../../../styles/utils/mixin';

.competitors-filter-wrapper.adlibrary {
  padding: 0 32px 0;
  max-height: 0;
  transition: all .3s ease;
  overflow: hidden;

  @media @viewport-mobile {
    padding: 0 16px 0;
  }

  &.active {
    max-height: 1200px;
    padding: 24px 32px;
    overflow: visible;
    .with-theme({
      border-bottom: 1px solid @vars[neutral-200-to-neutral-dark-200];
    });

    @media @viewport-mobile {
      padding: 16px;
      max-height: 2500px;
    }
  }

  .filters-title {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.3px;
    .with-theme({
      color: @vars[neutral-800-to-100];
    });

    sup {
      margin-left: 2px;
      .with-theme({
        color: @vars[neutral-400-to-neutral-dark-400];
      });
    }

    &-tag {
      margin-left: 6px;
      display: flex;
      padding: 4px 6px;
      align-items: center;
      border-radius: 6px;
      color: @orangeDepressed;
      text-transform: uppercase;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: -0.3px;
      .with-theme({
        background: @vars[orange-100-to-900];
      })
    }
  }

  .filters-wrapper {
    display: flex;
    width: 100%;
    align-items: flex-start;

    @media @viewport-mobile {
      flex-direction: column;
    }
  }

  .creative-wrapper {
    width: calc(50% + 1px);
    padding-right: 32px;
    .with-theme({
      border-right: 1px solid @vars[neutral-200-to-neutral-dark-200];
    });

    @media @viewport-mobile {
      width: 100%;
      border-right: none !important;
      padding-right: 0;
    }

    .creative-filter {
      margin-bottom: 24px;
      margin-top: 24px;
      width: fit-content;
      display: flex;
      height: 40px;
      padding: 4px;
      align-items: flex-start;
      border-radius: 10px;
      .with-theme({
        border: 1px solid @vars[neutral-200-to-neutral-dark-200];
        background: @vars[neutral-50-to-neutral-dark-50];
      });

      @media @viewport-mobile {
        margin-top: 16px;
        width: 100%;
        justify-content: space-between;
      }

      .creative-option {
        text-transform: capitalize;
        transition: all .3s ease;
        cursor: pointer;
        display: flex;
        height: 30px;
        padding: 0 12px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 8px;
        .with-theme({
          color: @vars[neutral-600-to-neutral-dark-500];
        });

        &.active {
          box-shadow: 0px 5px 10px -2px rgba(25, 28, 33, 0.06), 0px 2px 6px -2px rgba(106, 115, 133, 0.12), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
          .with-theme({
            background: @vars[white-to-neutral-dark];
            color: @vars[neutral-800-to-100];
          })
        }
      }
    }

    .input-filters-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 24px;
      gap: 16px;

      @media @viewport-mobile {
        margin-top: 16px;
        gap: 12px;
      }
    }
  }

  .categories-wrapper {
    width: calc(50% - 1px);
    padding-left: 32px;

    @media @viewport-mobile {
      width: 100%;
      padding-left: 0;
    }

    .categories-filter-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      gap: 16px;
      margin-top: 24px;
      max-height: 368px;
      overflow-y: scroll;

      @media @viewport-mobile {
        max-height: 315px;
      }

      &::-webkit-scrollbar {
        width: 4px;
        border-radius: 2px;
      }
      &::-webkit-scrollbar-button {
        display: none;
      }
      &::-webkit-scrollbar-track-piece {
        background: transparent;
        border-radius: 2px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 2px;
        .with-theme({
          background: @vars[neutral-200-to-neutral-dark-200];
        });
      }
      &::-webkit-scrollbar-track-piece:start {
        background: transparent;
        margin-top: 10px;
      }
      &::-webkit-scrollbar-track-piece:end {
        background: transparent;
        margin-bottom: 10px;
      }

      .categories-filter {
        display: flex;
        align-items: center;
        width: fit-content;
        border-radius: 8px;
        cursor: pointer;
        transition: all .3s ease;
        gap: 12px;

        &:hover {

          .categories-filter-checkbox {
            border: 1.5px solid @bluePrimary !important;
          }
        }

        &.active {
          .categories-filter-checkbox {
            background: @bluePrimary;
            border: 1.5px solid @bluePrimary !important;

            &:after {
              content: url("../../../Images/checkbox-mark.svg");
              left: 50%;
              top: 50%;
              font-size: 0;
              border: none;
              width: 16px;
              height: 16px;
              transform: scale(1) translate(-50%, -50%);
              position: absolute;
            }

            span {
              content: '';
              position: absolute;
              top: -1px;
              left: -1px;
              bottom: -1px;
              right: -1px;
              border-radius: @border-radius;
              border: 0 solid rgba(34, 90, 234, .2);
              opacity: 1;
              -webkit-animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
              animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
              -webkit-animation-fill-mode: forwards;
              animation-fill-mode: forwards;
              display: block;
              pointer-events: none;
            }
          }
        }

        &-checkbox {
          position: relative;
          border-radius: @border-radius;
          width: 20px;
          height: 20px;
          transition: all .3s ease;
          .with-theme({
            border: 1.5px solid @vars[neutral-200-to-neutral-dark-200];
          });
        }

        &-label {
          user-select: none;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: -0.3px;
          .with-theme({
            color: @vars[neutral-800-to-100];
          })
        }
      }
    }
  }

  .dropdowns-wrapper {
    display: flex;
    column-gap: 16px;
    row-gap: 24px;
    flex-wrap: wrap;

    @media @viewport-mobile {
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
    }

    .select-block {
      width: calc((100% - 64px) / 4);

      @media @viewport-mobile {
        width: 100%;
      }

      &.active_adsets {
        .ant-dropdown.dropdown-database-wrapper {
          width: 350px !important;
        }
      }

      &.ad_status {
        .ant-dropdown.dropdown-database-wrapper {
          width: 100% !important;
        }
      }

      &.domain_tld {
        .ant-dropdown.dropdown-database-wrapper {
          left: unset !important;
          right: 0 !important;
        }
      }

      .ant-dropdown.dropdown-database-wrapper {
        width: 252px !important;
        left: 0 !important;
      }
    }
  }

  .filters-btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;

    @media @viewport-mobile {
      margin-top: 24px;

      .btn {
        width: 50%;
      }
    }

    .filters-btn-reset {
      border: none !important;
      display: flex;
      height: 40px;
      padding: 6px 12px;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      transition: all .3s ease;
      cursor: pointer;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.3px;
      .with-theme({
        box-shadow: @vars[box-shadow-btn-secondary];
        background: @vars[white-to-neutral-dark];
        color: @vars[neutral-800-to-100];

        &:hover {
          background: @vars[neutral-50-to-neutral-dark-100];
        }
      });
    }

    .filters-btn-search {
      border: none !important;
      display: flex;
      height: 40px;
      padding: 6px 12px;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      transition: all .3s ease;
      cursor: pointer;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.3px;
      color: @white;
      background: @bluePrimary;
      .with-theme({
        box-shadow: @vars[box-shadow-btn-primary];
      });

      &:hover {
        background: @blueDepressed;
      }

      &.disabled {
        border: 1px solid transparent;
        box-shadow: none !important;
        cursor: not-allowed;
        .with-theme({
          background: @vars[neutral-200-to-neutral-dark-200] !important;
          color: @vars[neutral-500-to-neutral-dark-500] !important;
        });
      }
    }
  }
}
