@import '../../../styles/theme/theme';
@import '../../../styles/base/ui-kit';

.sales-tracker-page {

  .sales-tracker-top-page-header {
    .title-wrapper-icon {
      .with-theme({
        background: @vars[bg-red] !important;
        box-shadow: @vars[box-shadow-red] !important;
        border: 1px solid @vars[border-red] !important;
      });
    }
  }

  .skeleton.link {
    display: block;
  }

  .skeleton-table-header {
    align-items: center;
    display: flex;
    width: 100%;
    height: 40px;
    .with-theme({
      background: @vars[neutral-50-to-neutral-dark-50];
      border-bottom: 1px solid @vars[neutral-200-to-neutral-dark-200];
    })
  }

  .skeleton-table-row {
    display: flex;
    align-items: center;
    width: 100%;
    height: 80px;
    .with-theme({
      border-bottom: 1px solid @vars[neutral-200-to-neutral-dark-200];
    });

    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .product-database-table-cards {
    height: calc(100dvh - 155px);
    overflow-y: auto;
    padding: 24px 32px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    column-gap: 16px;
    row-gap: 24px;

    @media @viewport-mobile {
      padding: 16px;
    }

    &::-webkit-scrollbar {
      width: 4px;
      border-radius: 2px;
    }

    &::-webkit-scrollbar-button {
      display: none;
    }

    &::-webkit-scrollbar-track-piece {
      background: transparent;
      border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      .with-theme({
        background: @vars[neutral-200-to-neutral-dark-200];
      });
    }

    &::-webkit-scrollbar-track-piece:start {
      background: transparent;
      margin-top: 10px;
    }

    &::-webkit-scrollbar-track-piece:end {
      background: transparent;
      margin-bottom: 10px;
    }
  }
}
