@import '../../../styles/theme/theme';
@import '../../../styles/base/animation';
@import '../../../styles/base/ui-kit';

.cancel-trial-button {
  margin-top: 24px;
  width: 100%;
  padding: 16px 0;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  background: @bluePrimary;
  color: @white;
  border: none;

  &:hover, &:focus, &:active {
    background: @blueDepressed !important;
    color: @white !important;
  }
}
