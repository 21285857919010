@import '../../../styles/theme/theme';
@import '../../../styles/base/ui-kit';

.suppliers-page {
  padding: 0;
  overflow: unset!important;
  overflow-x: visible!important;

  .sales-tracker-top-page-header {

    .title-wrapper-icon {

      svg {
        margin-top: 3px;
        margin-left: 2px;
      }
    }
  }

  &-cards-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 32px;
    gap: 24px;

    @media @viewport-mobile {
      flex-direction: column;
      padding: 32px 16px;
      gap: 32px;
    }
  }

  .link.skeleton {
    .skeleton-animation-bg();
    border-radius: @border-radius;
    display: block;
  }
}
