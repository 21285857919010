@import '../../../styles/theme/theme';
@import '../../../styles/base/ui-kit';

.welcome-block {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 24px 32px 28px;
  @media @viewport-mobile {
    padding: 0;
  }

  &-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 2;
  margin-bottom: 0 !important;
  width: 100%;
  .welcome-block-subheader {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.3px;
    .with-theme({
      color: @vars[neutral-800-to-100];
    })
  }
  .welcome-block-subtitle {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.3px;
    .with-theme({
      color: @vars[neutral-600-to-neutral-dark-500];
    })
  }
  .welcome-block-title-wrapper-icon{
    display: flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    border: 1px solid rgba(7, 47, 148, 0.5);
    background: linear-gradient(0deg, #225AEA, #225AEA),
    linear-gradient(180deg, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0) 100%) !important;
    .with-theme({
      box-shadow: @vars[box-shadow-object-large];
    });
  }

    @media @viewport-mobile {
      padding: 0 !important;
      flex-direction: column;
      align-items: flex-start;
      .welcome-block-title-wrapper-icon {
        display: none !important;
      }

      .welcome-block-subheader {
        padding: 12px 16px;
        gap: 4px;

        .welcome-block-subtitle {
          font-size: 14px;
          line-height: 20px;
          letter-spacing: -0.3px;
        }
      }
    }

    .welcome-block-title-wrapper{
      display: flex;
      gap: 12px;
      @media @viewport-mobile {
        width: 100%;
        justify-content: space-between;
      }
    }
  }

  &.skeleton {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;

    .title-wrapper{
      display: flex;
      gap: 12px;
      align-items: center;
      @media @viewport-mobile {
        margin-bottom: 11px;
      }
    }

    .title-wrapper-icon{
      @media @viewport-mobile {
        display: none;
      }
    }
    .sales-tracker-top-page-subheader{
      padding: 0;
      @media @viewport-mobile {
        padding: 12px 16px;
      }
    }
  }
}