@import '../../../../../styles/theme/theme';
@import '../../../../../styles/base/ui-kit';

.dropdown-filter-tag {
  border: none;
  border-radius: @border-radius;
  padding: 8px 12px;
  gap: 12px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: @bluePrimary;
  display: flex;
  align-items: center;
  svg {
    margin-left: 8px;
  }
  .with-theme({
    background: @vars[blue-100-to-900];
  })
}
