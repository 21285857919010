@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';

.dashboard-page-block {
  .with-theme({
    border: 1px solid @vars[neutral-100-to-neutral-dark-100];
  });
  border-radius: 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  position: relative;

  &-shopify-btn-wrapper {
    display: flex;
    align-items: center;
    gap: 12px;

    @media @viewport-mobile {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &-icon {
    border-radius: 10px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg{
      width: 20px;
      height: 20px;
    }

    &-orange {
      .with-theme({
        background: @orangePrimary;
        border: 1px solid rgba(181, 87, 0, 0.5);
        box-shadow: 0px 0px 0px 0.83px rgba(98, 36, 10, 0.04);
        box-shadow: 0px 0.83px 0.83px -0.42px rgba(98, 36, 10, 0.04);
        box-shadow: 0px 2.5px 2.5px -1.25px rgba(98, 36, 10, 0.04);
        box-shadow: 0px 5px 5px -2.5px rgba(98, 36, 10, 0.04);
        box-shadow: 0px 10px 10px -5px rgba(98, 36, 10, 0.04);
        box-shadow: 0px 20px 20px -10px rgba(98, 36, 10, 0.04);

      })
    }

    &-red {
      .with-theme({
        background: @redPrimary;
        border: 1px solid rgba(158, 20, 20, 0.5);
        box-shadow: 0px 0px 0px 0.83px rgba(112, 6, 6, 0.04);
        box-shadow: 0px 0.83px 0.83px -0.42px rgba(112, 6, 6, 0.04);
        box-shadow: 0px 2.5px 2.5px -1.25px rgba(112, 6, 6, 0.04);
        box-shadow: 0px 5px 5px -2.5px rgba(112, 6, 6, 0.04);
        box-shadow: 0px 10px 10px -5px rgba(112, 6, 6, 0.04);
        box-shadow: 0px 20px 20px -10px rgba(112, 6, 6, 0.04);
      })
    }

    &-green {
      .with-theme({
        background: @greenPrimary;
        border: 1px solid rgba(9, 111, 69, 0.5);
        box-shadow: 0px 0px 0px 1.25px rgba(6, 73, 42, 0.04);
        box-shadow: 0px 1.25px 1.25px -0.63px rgba(6, 73, 42, 0.04);
        box-shadow: 0px 3.75px 3.75px -1.88px rgba(6, 73, 42, 0.04);
        box-shadow: 0px 7.5px 7.5px -3.75px rgba(6, 73, 42, 0.04);
        box-shadow: 0px 15px 15px -7.5px rgba(6, 73, 42, 0.04);
        box-shadow: 0px 30px 30px -15px rgba(6, 73, 42, 0.04);
      })
    }

    &-yellow {
      .with-theme({
        background: @yellowPrimary;
        border: 1px solid rgba(160, 116, 1, 0.5);
        box-shadow: 0px 0px 0px 0.83px rgba(112, 6, 6, 0.04);
        box-shadow: 0px 0.83px 0.83px -0.42px rgba(112, 6, 6, 0.04);
        box-shadow: 0px 2.5px 2.5px -1.25px rgba(112, 6, 6, 0.04);
        box-shadow: 0px 5px 5px -2.5px rgba(112, 6, 6, 0.04);
        box-shadow: 0px 10px 10px -5px rgba(112, 6, 6, 0.04);
        box-shadow: 0px 20px 20px -10px rgba(112, 6, 6, 0.04);
      })
    }

    &-purple {
      .with-theme({
        background: @purplePrimary;
        border: 1px solid rgba(70, 13, 144, 0.5);
        box-shadow: 0px 0px 0px 0.83px rgba(70, 13, 144, 0.04);
        box-shadow: 0px 0.83px 0.83px -0.42px rgba(70, 13, 144, 0.04);
        box-shadow: 0px 2.5px 2.5px -1.25px rgba(70, 13, 144, 0.04);
        box-shadow: 0px 5px 5px -2.5px rgba(70, 13, 144, 0.04);
        box-shadow: 0px 10px 10px -5px rgba(70, 13, 144, 0.04);
        box-shadow: 0px 20px 20px -10px rgba(70, 13, 144, 0.04);
      });
    }
  }

  &-text {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
  }

  &-title {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.3px;
    .with-theme({
      color: @vars[neutral-800-to-100];
    });
    text-align: left;
    margin-bottom: 0;
    display: flex;
    align-items: center;

    &-tag {
      height: 24px;
      text-transform: uppercase;
      border-radius: @border-radius;
      font-family: 'Inter', sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: -0.3px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      padding: 0 6px;
      top: 8px;
      right: 8px;
      .with-theme({
        background: @vars[blue-100-to-neutral-dark-100];
        color: @vars[blue-depressed-to-neutral-dark-500]
      });
    }
  }

  &-subtitle {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19.6px;
    color: @Neutral500;
    letter-spacing: -0.3px;
    margin-top: 4px;
    margin-bottom: 0;
    .with-theme({
      color: @vars[neutral-600-to-neutral-dark-500];
    });
  }

  &-buttons {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &-button {
      height: 36px;
      padding: 6px 12px;
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.3px;
      border-radius: 8px;
      cursor: pointer;
      transition: all .3s ease;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none !important;

      .with-theme({
        color: @vars[neutral-800-to-100] !important;
        background: @vars[white-to-neutral-dark-50];
        box-shadow: @vars[box-shadow-btn-secondary];

        &:hover, &:focus {
          background: @vars[neutral-50-to-neutral-dark-100];
          border: none !important;
        }

        &.disabled {
          cursor: not-allowed;
          background: @vars[neutral-200-to-neutral-dark-200] !important;
        }

        &:first-child{
          width: 100%;
        }
      });


    &.dashboard-page-shopify-button {
      padding: 12px 16px;
      width: fit-content;

        .badge {
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
          padding: 4px 8px;
          border-radius: 6px;
          margin-left:4px;
          .with-theme({
            color: @vars[blue-depressed-to-neutral-dark-500];
            background: @vars[blue-light-to-neutral-dark-200];
          })
        }
    }


    &-transparent {
      .with-theme({
        color: @vars[neutral-800-to-200] !important;
        border: 1px solid @vars[neutral-100-to-800];
      });
      background: transparent;

      &:hover, &:focus {
        .with-theme({
          color: @vars[neutral-800-to-200];
        });
        border: 1px solid @bluePrimary;
        background: transparent;
      }


      &.disabled {
        cursor: not-allowed;
        color: @Neutral500 !important;
      }
    }
  }

  @media @viewport-mobile {
    .skeleton-button {
      width: 100% !important;
    }
    &-content {
      flex-direction: column;
      align-items: flex-start;
    }

    &-text {
      width: 100% !important;
    }
  }
}
