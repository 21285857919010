@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';

.sales-tracker-top-page {
  overflow: unset !important;
  overflow-x: visible !important;
  max-height: unset !important;
  height: unset;

  .fadspot-page-quick-search-wrapper {
    margin: 0;
    width: 100%;
  }

  @media @viewport-mobile {
    overflow-y: scroll !important;
  }

  &-watch-tutorial {
    width: 169px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 10px;
    transition: all .3s ease;
    .with-theme({
      border: 1px solid @vars[neutral-200-to-neutral-dark-200];
      background: @vars[white-to-neutral-dark-50];
    });

    &:hover {
      border: 1px solid @bluePrimary;

      span {
        color: @bluePrimary;
      }

      svg path {
        fill: @bluePrimary;
      }
    }

    span {
      display: flex;
      align-items: center;
      margin-left: 10px;
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      transition: all .3s ease;
      .with-theme({
        color: @vars[color-dark-to-white];
      })
    }

    .watch-tutorial-icon {
      svg path {
        transition: all .3s ease;
        .with-theme({
          fill: transparent;
          stroke: @vars[color-dark-to-white];
        })
      }
    }

    svg path {
      transition: all .3s ease;
      .with-theme({
        fill: @vars[color-dark-to-white];
      })
    }
  }

  &-tags {
    margin-top: 12px;
    display: flex;
    align-items: center;

    @media @viewport-mobile {
      flex-wrap: wrap;
      gap: 8px;
    }
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 24px 32px;
    margin-bottom: 0 !important;
    .with-theme({
      border-bottom: 1px solid @vars[neutral-200-to-neutral-dark-200];
    });
    @media @viewport-mobile {
      display: none;
    }

    @media @viewport-mobile {
      padding: 12px 16px !important;
      .title-wrapper-icon {
        display: none !important;
      }

      .sales-tracker-top-page-subheader {
        gap: 4px;

        .sales-tracker-top-page-subtitle {
          font-size: 14px;
          line-height: 20px;
          letter-spacing: -0.3px;
        }
      }
    }

    .sales-tracker-top-page-credits {
      margin-left: auto;
      margin-right: 16px;
      display: flex;
      align-items: center;
      border-radius: 8px;
      height: 36px;
      .with-theme({
        border: 1px solid @vars[neutral-200-to-neutral-dark-200];
      });

      &-title {
        border-radius: 8px 0 0 8px;
        height: 100%;
        display: flex;
        padding: 0 12px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.3px;
        .with-theme({
          color: @vars[neutral-600-to-neutral-dark-500];
          border-right: 1px solid @vars[neutral-200-to-neutral-dark-200];
          background: @vars[neutral-50-to-neutral-dark-50];
        });
      }

      &-value {
        border-radius: 0 8px 8px 0;
        height: 100%;
        display: flex;
        padding: 0 12px;
        justify-content: center;
        align-items: center;
        gap: 2px;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.3px;
        .with-theme({
          color: @vars[neutral-600-to-neutral-dark-500];
          background: @vars[white-to-neutral-dark];
        });

        &.right-border {
          border-radius: 0;
          .with-theme({
            border-right: 1px solid @vars[neutral-200-to-neutral-dark-200];
          });
        }

        .current {
          color: @greenPrimary;
        }

        .total-green {
          color: @greenPrimary;
        }

        .total-orange {
          color: @orangePrimary;
        }

        .total-red {
          color: @redPrimary;
        }

        .separator {
          .with-theme({
            color: @vars[neutral-400-to-neutral-dark-400];
          })
        }

        .maximum {
          .with-theme({
            color: @vars[neutral-600-to-neutral-dark-500];
          })
        }

        .type {
          margin-left: 3px;
          .with-theme({
            color: @vars[neutral-500-to-neutral-dark-500];
          })
        }
      }
    }

    &-btn {
      cursor: pointer;
      position: relative;
      width: 40px !important;
      height: 40px !important;
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
      border: none !important;
      outline: none !important;
      box-shadow: none !important;
      padding: 0 !important;
      background: transparent !important;
    }

    .title-wrapper {
      display: flex;
      align-items: center;
      gap: 12px;

      &-icon {
        display: flex;
        width: 40px;
        height: 40px;
        justify-content: center;
        align-items: center;
        border-radius: 12px;
        border: 1px solid rgba(7, 47, 148, 0.50);
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0.00) 100%), #225AEA;
        .with-theme({
          box-shadow: @vars[box-shadow-object-large];
        });
      }
    }

    .ant-dropdown {
      position: absolute;
      left: unset !important;
      top: 60px !important;
      right: 35px !important;
    }

    &-dropdown {
      display: flex;
      width: 185px;
      padding: 8px;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      border-radius: 12px;

      &.banner-credits-dropdown {
        margin-top: 10px;
      }

      .with-theme({
        background: @vars[white-to-neutral-dark-50];
        border: 1px solid @vars[neutral-200-to-neutral-dark-200];
        box-shadow: @vars[box-shadow-object-large];
      });

      &-option {
        display: flex;
        align-items: center;
        padding: 6px 8px;
        margin: 0 auto;
        width: calc(100% - 8px);
        transition: all .3s ease;
        cursor: pointer;
        border-radius: 8px;

        &:hover {
          .with-theme({
            background: @vars[blue-100-to-neutral-dark-100];
          })
        }

        &.tour svg path {
          .with-theme({
            fill: @vars[neutral-600-to-neutral-dark-500];
          })
        }

        &.tutorial svg path {
          .with-theme({
            stroke: @vars[neutral-600-to-neutral-dark-500];
          })
        }

        span {
          margin-left: 8px;
          font-weight: 500;
          .with-theme({
            color: @vars[neutral-800-to-100];
          });

        }
      }
    }
  }

  &-title {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.3px;
    .with-theme({
      color: @vars[neutral-800-to-100];
    })
  }

  &-subtitle {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.3px;
    .with-theme({
      color: @vars[neutral-600-to-neutral-dark-500];
    })
  }

  &-limits {

    &-wrapper {
      display: flex;
      align-items: center;
    }

    padding: 12px 20px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    margin-left: 12px;
    color: @Neutral500;
    border-radius: @border-radius;
    .with-theme({
      border: 1px solid @vars[neutral-100-to-800];
      background: @vars[color-white-to-dark];
    });

    .ant-divider {
      .with-theme({ background-color: @vars[neutral-100-to-800] });
    }
  }

  &-subheader {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
  }

  &-subtitle {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    color: @Neutral500;
    margin-bottom: 0;
  }

  .period-filter-wrapper {
    .with-theme({
      border: 1px solid @vars[neutral-100-to-800];
      width: 369px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      border-radius: @border-radius;

      @media @viewport-mobile {
        width: 100%;
      }
    });
  }

  .period-filter {
    width: 184px;
    height: 100%;
    cursor: pointer;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: @Neutral500;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .3s ease;

    &.active {
      color: @white;
      z-index: 1;
    }

    &.disabled {
      color: @Neutral500;
    }

    &-active {
      position: absolute;
      left: 0;
      top: 0;
      width: 184px;
      height: 100%;
      content: '';
      background: @bluePrimary;
      border-radius: @border-radius;
      transition: all .3s ease;

      @media @viewport-mobile {
        width: calc(100% / 2);
      }

      &-disabled {
        .with-theme({
          background: @vars[neutral-100-to-800];
        });
      }
    }
  }

  .main-filters-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 0 16px;

    @media @viewport-mobile {
      flex-direction: column;
      gap: 16px;
      padding: 0 16px;
    }
  }

  .main-filter-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    @media @viewport-mobile {
      margin-top: 0;
    }
  }

  .main-filter-search {
    width: 100%;

    .top-products-search-wrapper {
      display: flex;
      gap: 16px;
      align-items: center;
      padding: 20px 16px 0;

      @media @viewport-mobile {
        flex-direction: column;
      }

      button {
        width: 167px;
        background: #225aea;
          padding: 8px 26px;
          height: 40px;
          font-family: 'Inter', sans-serif;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          color: @white !important;
          background: @bluePrimary;
          cursor: pointer;
          border: none;
          outline: none;
      
          &:hover, &:focus {
            background: @blueDepressed;
            color: @white;
          }
      
          &[disabled] {
            color: @Neutral500 !important;
            .with-theme({
              background: @vars[neutral-100-to-800];
            });
            &:hover {
              color: @Neutral500 !important;
            }
          }
        

        @media @viewport-mobile {
          width: 100%;
        }
      }

      .top-products-search-list {
        display: flex;
        align-items: center;
        min-height: 40px;
        padding: 0 18px;
        width: calc(100% - 16px - 167px);
        border-radius: @border-radius;
        .with-theme({
          border: 1px solid @vars[neutral-100-to-800];
        });

        @media @viewport-mobile {
          width: 100%;
        }

        &:hover {
          box-shadow: none !important;
          border-color: @bluePrimary !important;
        }

        &:has(input[disabled]) {
          cursor: not-allowed;

          &:hover {
            .with-theme({
              border-color: @vars[neutral-100-to-800] !important;
            });
          }
        }

        &:focus-within {
          border-color: @bluePrimary !important;
          box-shadow: 0 0 0 2px rgba(34,90,234,.2) !important;
        }

        .top-products-search-prefix {
          margin-right: 16px;
          display: flex;
          align-items: center;

          svg {
            margin-right: 8px;
          }

          .ant-divider {
            height: 32px;
            margin-left: 16px;
            margin-right: 0;
            .with-theme({
              background: @vars[neutral-100-to-800];
            });
          }
        }

        .top-products-search-list-items {
          display: flex;
          flex-wrap: wrap;
          align-content: center;
          min-height: 30px;
          max-width: calc(100% - 65px);

          @media @viewport-mobile {
            max-width: 255px;
          }

          .ant-tag {
            font-family: 'Inter',sans-serif;
            white-space: pre-wrap;
            border: none;
            padding: 4px 8px;
            margin-bottom: 8px;
            color: #225aea;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            display: flex;
            align-items: center;
            border-radius: 6px;
            margin-right: 8px;
            .with-theme({
              background: @vars[blue-200-to-800]
            });

            &-close-icon {
              margin-left: 8px;
            }
          }
        }

        .ant-input {
          border: none !important;
          height: 30px !important;
          padding: 0 !important;
          background-color: transparent !important;
          caret-color: @bluePrimary;
          .with-theme({
            color: @vars[neutral-800-to-200];
          });

          &::placeholder {
            .with-theme({
              color: @vars[neutral-400-to-neutral-dark-400];
            })
          }
        }
      }
    }

    .search_wrapper {
      margin-bottom: 0;

      @media @viewport-mobile {
        margin-top: 0;
        flex-wrap: wrap;
        gap: 16px;
      }

      .search_button {
        @media @viewport-mobile {
          width: 100%;
          min-width: 100%;
        }
      }

      button {
        width: 167px;

        @media @viewport-mobile {
          width: 100% !important;
          min-width: 100% !important;
          height: 40px !important;
        }
      }

      .search-input {
        width: calc(100% - 16px - 167px);

        @media @viewport-mobile {
          width: 100%;
          margin-right: 0;
        }

        .ant-input-affix-wrapper {
          height: 40px;
        }
      }
    }
  }

  .main-filter-dropdown-wrapper {
    display: flex;
    align-items: center;
    gap: 12px;
    position: relative;

    @media @viewport-mobile {
      flex-direction: column;
      width: 100%;
      position: static;
    }
  }

  .sales-tracker-top-table-wrapper {
    margin-top: 24px;
    padding: 0;

    @media @viewport-mobile {
      margin-top: 12px;
    }

    .product-database-table-header {
      &:after {
        display: none;
      }
    }
  }
}
