@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';

.sales-tracker-note {

  &-wrapper {
    margin-top: 16px;
    font-family: 'Inter', sans-serif;
    padding: 16px;
    border-radius: @border-radius;
    .with-theme({
      background: @vars[neutral-100-to-800];
      color: @vars[neutral-900-to-200];
      border: 1px solid @vars[neutral-200-to-700];
    })
  }

  &-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    & span {
      margin-left: 8px;
    }
  }

  &-description {
    margin: 8px 0 0 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
}
