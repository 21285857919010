@import '../../../styles/theme/theme';
@import '../../../styles/base/animation';
@import '../../../styles/base/ui-kit';
@import '../../../styles/utils/mixin';

.products-details {

  &-table-body {
    margin-top: 0 !important;

    .ant-table-cell-fix-right .product-database-table-cell {
      padding-left: 20px;
    }

    .title-column {
      display: flex;
      align-items: center;
      gap: 12px;

      &-img-wrap {
        width: 40px;
        height: 40px;
        border-radius: 8px;

        img {
          width: 40px;
          height: 40px;
          border-radius: 8px;
          object-fit: cover;
          object-position: center;
        }
      }

      &-text {
        max-width: calc(100% - 102px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        .title-column-text-wrapper{
          max-width: 118px;
          text-wrap: wrap;
          font-size: 14px;
          font-weight: 500;
          line-height: 19.6px;
          letter-spacing: -0.3px;
          .title-column-text-wrapper-clickable{
            color: @bluePrimary;
            cursor: pointer;
          }
        }
      }
    }

    .score-counter {
      display: flex;
      align-items: center;
      gap: 8px;

      &-stats {
        display: flex;
        align-items: center;
        gap: 2px;
      }

      &-stat {
        width: 4px;
        height: 12px;
        border-radius: 1px;
        .with-theme({
          background: @vars[neutral-100-to-neutral-dark-100];
        });

        &--active {
          background: @bluePrimary !important;
        }
      }
    }

    .skeleton-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 40px;
      .with-theme({
        background: @vars[neutral-50-to-neutral-dark-50];
        border-bottom: 1px solid @vars[neutral-200-to-neutral-dark-200];
      });

      .cell {
        display: flex;
        align-items: center;
        gap: 4px;

        &-1 {
          width: 52px;
        }

        &-2 {
          width: 320px;
        }

        &-3 {
          width: 96px;
        }

        &-4 {
          width: 96px;
        }

        &-5 {
          width: 120px;
        }

        &-6 {
          width: 103px;
        }

        &-7 {
          width: 115px;
        }

        &-8 {
          width: 64px;
        }
      }
    }

    .skeleton-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 80px;
      .with-theme({
        background: @vars[white-to-neutral-dark];
        border-bottom: 1px solid @vars[neutral-200-to-neutral-dark-200];
      });

      .cell {
        display: flex;
        align-items: center;
        gap: 12px;

        &-1 {
          width: 52px;
          justify-content: flex-end;
        }

        &-2 {
          width: 320px;
        }

        &-3 {
          width: 96px;
        }

        &-4 {
          width: 96px;
        }

        &-5 {
          width: 120px;
        }

        &-6 {
          width: 103px;
        }

        &-7 {
          width: 115px;
        }

        &-8 {
          width: 64px;
          justify-content: center;
        }
      }
    }
  }
}
