@import '../../../styles/base/ui-kit';
@import '../../../styles/base/animation';
@import '../../../styles/theme/theme';

.user-dropdown {
  position: relative;
  @media @viewport-mobile {
    width: 100%;
  }

  &-outer {
    display: flex;
    width: 100%;
    cursor: pointer;

    &.ant-dropdown-open {

      .user-dropdown-outer-info-wrapper svg {
        transform: rotate(180deg);
      }
    }

    &:has(.user-dropdown-outer-icon) {
      width: 40px;
      justify-content: center;
      align-items: center;
    }

    &-info {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      gap: 2px;
      font-family: "Inter", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.3px;

      &-wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        svg {
          transition: all .3s ease;
          .with-theme({

            path {
              stroke: @vars[neutral-600-to-neutral-dark-500];
            }
          })
        }
      }

      &-email {
        white-space: nowrap;
        .with-theme({
          color: @vars[neutral-500-to-neutral-dark-500];
        })
      }

      &-name {
        white-space: nowrap;
        .with-theme({
          color: @vars[neutral-800-to-100];
        })
      }
    }
  }

  &-inner {
    border-radius: 16px;
    width: 300px;
    padding: 16px 0 0;
    .with-theme({
      border: 1px solid @vars[neutral-200-to-neutral-dark-200];
      background: @vars[white-to-neutral-dark-50];
    });

    @media @viewport-mobile {
      border-radius: 0;
      width: 100vw;
      height: 100dvh;
      overflow-y: auto;
      overflow-x: hidden;
    }

    &-title {
      padding: 0 16px 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-family: 'Inter', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.3px;
      .with-theme({
        color: @vars[neutral-800-to-100];
      });
    }

    &-info {
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.3px;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      gap: 2px;

      &-wrapper {
        width: 100%;
        padding: 0 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &-name {
        .with-theme({
          color: @vars[neutral-800-to-100];
        })
      }

      &-email {
        .with-theme({
          color: @vars[neutral-600-to-neutral-dark-500];
        })
      }
    }

    &-logout {
      cursor: pointer;
      display: flex;
      width: 40px;
      height: 40px;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
      transition: all .3s ease;
      .with-theme({
        box-shadow: @vars[box-shadow-btn-secondary];
        background: @vars[white-to-neutral-dark-50];
      });

      &:hover, &:focus, &:active {
        .with-theme({
          background: @vars[neutral-50-to-neutral-dark-100];
        })
      }
    }

    &-subscription {

      &-wrapper {
        display: flex;
        align-items: flex-start;
        width: 100%;
        padding: 12px 16px;
        gap: 8px;
        .with-theme({
          background: @vars[white-to-neutral-dark-100];
        })
      }

      &-info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-style: normal;
        line-height: 20px;
        letter-spacing: -0.3px;
      }

      &-title {
        font-weight: 500;
        .with-theme({
          color: @vars[neutral-800-to-100];
        })
      }

      &-line {
        display: flex;
        align-items: center;
        gap: 3px;
      }

      &-key {
        font-weight: 400;
        .with-theme({
          color: @vars[neutral-600-to-neutral-dark-500];
        })
      }

      &-value {
        font-weight: 500;
        .with-theme({
          color: @vars[neutral-800-to-100];
        })
      }

      &-trial {
        padding: 12px 16px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-family: "Inter", sans-serif;
        font-size: 14px;
        font-style: normal;
        line-height: 20px;
        letter-spacing: -0.3px;
        .with-theme({
          background: @vars[yellow-xlight-to-neutral-dark-200];
        });

        h3 {
          margin-top: 8px;
          font-weight: 500;
          .with-theme({
            color: @vars[neutral-800-to-100];
          })
        }

        h4 {
          width: 90%;
          margin-top: 4px;
          font-weight: 400;
          .with-theme({
            color: @vars[neutral-600-to-neutral-dark-500];
          })
        }

        &-button {
          margin-top: 16px;
          width: 100%;
          transition: all .3s ease;
          font-family: 'Inter', sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: -0.3px;
          display: flex;
          align-items: center;
          border-radius: 8px;
          border: none;
          .with-theme({
            background: @vars[white-to-neutral-dark-50];
            box-shadow: @vars[box-shadow-btn-secondary];
            color: @vars[neutral-800-to-100];
          });

          &:hover, &:focus, &:active {
            .with-theme({
              background: @vars[neutral-50-to-neutral-dark-100];
            })
          }
        }
      }
    }

    &-settings {
      padding: 8px;
      margin: 4px auto;
      width: calc(100% - 8px);
      display: flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;
      transition: all .3s ease;
      border-radius: 8px;

      &:hover {
        .with-theme({
          background: @vars[blue-100-to-neutral-dark-100];
        })
      }

      svg path {
        .with-theme({
          stroke: @vars[neutral-600-to-neutral-dark-500];
        })
      }

      span {
        font-family: "Inter", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.3px;
        .with-theme({
          color: @vars[neutral-800-to-100];
        })
      }
    }

    &-store {

      &-list {
        padding: 12px;
        max-height: 256px;
        overflow-y: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 12px;
      }

      &-item {
        font-family: "Inter", sans-serif;
        font-weight: 500;
        font-style: normal;
        letter-spacing: -0.3px;
        display: flex;
        align-items: center;
        width: 100%;
        gap: 8px;
        height: 40px;

        svg {
          width: 20px !important;
          height: 20px !important;
        }

        & > div {
          max-width: calc(100% - 28px);
          display: flex;
          flex-wrap: wrap;
          gap: 8px;
          align-items: center;
        }
      }

      &-name {
        white-space: nowrap;
        font-size: 14px;
        line-height: 20px;
        .with-theme({
          color: @vars[neutral-800-to-100];
        })
      }

      &-link {
        white-space: nowrap;
        font-size: 12px;
        line-height: 16px;
        .with-theme({
          color: @vars[neutral-600-to-neutral-dark-500];
        })
      }
    }

    .text-link {
      display: flex;
      padding: 8px;
      margin: 4px auto;
      width: calc(100% - 8px);
      align-items: center;
      justify-content: flex-start;
      gap: 8px;
      cursor: pointer;
      font-family: "Inter", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.3px;
      transition: all .3s ease;
      border-radius: 8px;
      .with-theme({
        color: @vars[neutral-800-to-100];
      });

      &:hover {
        .with-theme({
          background: @vars[blue-100-to-neutral-dark-100];
        })
      }

      svg {
        width: 20px !important;
        height: 20px !important;
      }

      svg path {
        .with-theme({
          stroke: @vars[neutral-600-to-neutral-dark-500];
        })
      }
    }
  }
}
