@import '../../../../../styles/theme/theme';
@import '../../../../../styles/base/ui-kit';
@import '../../../../../styles/utils/variables';

.competitors-block {
  margin-top: 24px;
  margin-bottom: 24px;
  padding-top: 24px;
  padding-bottom: 24px;
  min-height: 584px;
  max-height: 584px;
  .block-with-label {
    height: 48px;
    .space-between {
      height: 100%;
    }
  }
  .competitors-list {
    max-height: 465px;
    min-height: 465px;
    overflow-y: scroll;
    padding-right: 12px;
    margin-right: -12px;
    width: 100%;
    &::-webkit-scrollbar {
      width: 4px;
      border-radius: 2px;
    }
    &::-webkit-scrollbar-button {
      display: none;
    }
    &::-webkit-scrollbar-track-piece {
      background: transparent;
      border-radius: 2px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      .with-theme({
        background: @vars[neutral-200-to-neutral-dark-200];
      });
    }
    .competitor-wrapper {
      &_link {
        display: flex;
        align-items: center;
        img {
          display: inline-block;
          width: 24px;
          height: 24px;
          margin-right: 12px;
        }
        a {
          font-size: 16px;
          line-height: 24px;
          color: @blue500;
          cursor: pointer;
        }
      }
      &_main {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .competitor-input {
          width: 164px;
          height: 32px;
          margin-right: 16px;
          color: @Neutral800;
          font-size: 14px;
          line-height: 20px;
          padding: 6px 12px;
        }
        .competitor-input-prefix-currency {
          width: 45px;
          border: none;
          position: absolute;
          right: 200px;
          z-index: 2;
          padding: 1px 5px;
          text-align: center;
        }

        .competitor-input-prefix {
          width: 164px;
          height: 32px;
          margin-right: 64px;
          .ant-input-number-handler-wrap {
            display: none;
          }
          .ant-input-number-input-wrap {
            .ant-input-number-input {
              padding: 0;
              padding-left: 56px;
              color: @Neutral800;
              font-size: 14px;
              line-height: 20px;
              width: 164px;
              height: 32px;
              line-height: 32px;
            }
            &::before {
              position: absolute;
              padding: 0 13px;
              border-right: 1px solid #f2f3f8;
              color: @Neutral300;
              font-size: 14px;
              line-height: 20px;
              height: 20px;
              top: 6px;
              left: 6px;
            }
          }
          .ant-input-prefix {
            border-right: 1px solid #f2f3f8;
            padding-right: 12px;
            padding-left: 6px;
            margin-right: 12px;
            color: @Neutral300;
          }

          .ant-input {
            height: 21px;
            color: @Neutral800;
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
    }
  }
}
