@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';

.select-block{
  width: calc(50% - 8px);

  @media @viewport-mobile {
    width: 100%;
  }

  &_label{
    width: 100%;
    display: inline-block;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.3px;
    margin-bottom: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;

    .with-theme({
      color: @vars[neutral-800-to-100];
    });
  }
}


.ant-dropdown {
  &-trigger.dropdown-database {

    svg {
      transition: transform 0.2s linear, color 0.2s linear;
    }

    &[disabled] {
      color: @Neutral500;
      .with-theme({
        background: @vars[neutral-100-to-800];
      });
    }

    &:not([disabled]):hover {
      .with-theme({
        border: 1px solid @bluePrimary;
      });
    }

    &.ant-dropdown-open {
      .with-theme({
        border: 1px solid @bluePrimary;
      });

      svg {

        &:last-of-type {
          transform: rotateZ(180deg);
        }
      }
    }

    .dropdown-database_value {
      margin-left: 8px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      flex: 1 1;
      .with-theme({
        color: @vars[neutral-800-to-200];
      });
    }
  }


  .dropdown-database {
    &-menu {
      border-radius: @border-radius;
      width: 100%;

      &.calendar {
        padding: 8px;
      }
      .with-theme({
        background: @vars[white-to-neutral-dark];
        border: 1px solid @vars[neutral-200-to-neutral-dark-200];
        box-shadow: @vars[box-shadow-object-large];
      });
      overflow: hidden;

      .ant-input-affix-wrapper.dropdown-search.input {
        height: 36px;

        .ant-input-prefix {
          margin-right: 8px;
        }

        &:hover{
          border: 1px solid @bluePrimary;
        }

        &.ant-input-affix-wrapper-focused{
          .with-theme({
            border: 1px solid @bluePrimary;
          })
        }

        > .ant-input {
          .with-theme({
            color: @vars[neutral-800-to-100];
            background: @vars[white-to-neutral-dark];

            &::placeholder {
              color: @vars[neutral-400-to-neutral-dark-400];
            }
          });
        }
      }
    }

    &-list {
      margin-top: 0 !important;
      .custom-scroll-bar();

      &-item {
        padding: 4px 8px;
        border-radius: 8px;
        width: 100%;

        &:hover {
          .with-theme({
            background: @vars[blue-100-to-neutral-dark-100] !important;
          })
        }

        .ant-checkbox-wrapper{
          width: 100% !important;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-family: 'Inter', sans-serif;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          .with-theme({
            color: @vars[neutral-800-to-100];
          });

          .ant-checkbox {
            display: none;
          }

          .ant-checkbox + span {
            padding-right: 0;
            padding-inline-start: 0;
            padding-inline-end: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
          }

          &:hover {
            .ant-checkbox {
              .ant-checkbox-inner {
                .with-theme({
                  border-color: @bluePrimary;
                });
              }
            }
          }

          .ant-checkbox {
            top: 0;
            .ant-checkbox-inner {
              width: 20px;
              height: 20px;
              border-radius: @border-radius;
              .with-theme({
                border: 1.5px solid @vars[neutral-200-to-700];
                background-color: @vars[color-white-to-dark];
              });

              &:after {
                content: url("../../../Images/checkbox-mark.svg");
                left: 50%;
                top: 50%;
                font-size: 0;
                border: none;
                width: 16px;
                height: 16px;
                transform: scale(1) translate(-50%, -50%);
              }
            }

            &.ant-checkbox-checked {
              .ant-checkbox-inner {
                .with-theme({
                  border-color: @bluePrimary;
                  background-color: @bluePrimary;
                });
              }
            }
          }
        }

        .ant-checkbox-wrapper.ant-checkbox-wrapper-disabled{
          .ant-checkbox-disabled {

            .ant-checkbox-inner{
              .with-theme({
                border-color: @vars[neutral-300-to-500] !important;
              })
            }

            & + span {
              .with-theme({
                color: @vars[neutral-300-to-500];
              })
            }
          }
        }
      }
    }
  }
}

.dropdown-database-wrapper {
  z-index: 1100;
  top: 75px !important;

  &-inner {
    top: 100px !important;
  }
}

.dropdown-date {

  &-info {
    &-wrapper {
      display: none;
    }

    &-date {
      font-size: 14px;
      line-height: 20px;
      .with-theme({
        color: @vars[neutral-800-to-200];
      });
    }

    &-timezone {
      font-size: 12px;
      line-height: 16px;
      color: @Neutral500;
      margin-top: 4px;
    }
  }

  &-wrapper {
    position: relative;
    width: 100%;
    min-width: 284px;
    height: 368px;
    padding: 58px 16px 16px;
    border-radius: 16px;
    .with-theme({
      border: 1px solid @vars[neutral-200-to-neutral-dark-200];
      background: @vars[white-to-neutral-dark];
      box-shadow: @vars[dropshadow-neutral];
    });

    .ant-dropdown-trigger.dropdown-database {
      position: relative;
      z-index: 1000000;
    }

    .ant-picker-dropdown {
      width: calc(100% - 32px) !important;
    }

    & > div:has(.ant-picker-range-wrapper) {
      width: 100% !important;
    }

    .rangepicker-title {
      display: none;
    }

    .ant-picker-dropdown-range {
      animation-duration: 0s !important;
      width: 100%;
      top: 16px !important;
      padding: 0;
      left: 16px !important;

      .ant-picker-panel-container {
        width: 100%;
        background: transparent;
        box-shadow: none;

        .ant-picker-panel-layout > div {
          width: 100%;
        }

        .ant-picker-panels {
          width: 100%;
          display: flex;
          justify-content: center;

          .ant-picker-panel {
            width: 100%;
            border: none;

            .ant-picker-header-next-btn {
              visibility: visible !important;
            }

            &:last-of-type {
              width: 0;
            }

            .ant-picker-date-panel {
              width: 100%;

              .ant-picker-body{
                margin-top: 55px !important;
                padding: 0;
              }
            }

            .ant-picker-content {
              width: 100%;

              .ant-picker-cell {
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;
                padding: 2px 0;
                .with-theme({
                  color: @vars[neutral-800-to-200];
                });

                &-range-start {
                  border-radius: 4px 0 0 4px;
                }

                &-range-end {
                  border-radius: 0 4px 4px 0;
                }

                &-in-range {
                  .with-theme({
                    background: @vars[blue-100-to-neutral-dark-100];
                  })
                }

                &-range-start, &-range-end {
                  background: @bluePrimary;

                  .ant-picker-cell-inner {
                    color: @white !important;

                    &:before {
                      display: none;
                    }
                  }
                }

                &.ant-picker-cell-range-end {
                  .ant-picker-future {
                    color: @white !important;
                  }
                }

                &.ant-picker-cell-in-range {

                  .ant-picker-future {
                    color: @bluePrimary !important;
                  }

                  .ant-picker-cell-inner {
                    color: @bluePrimary;

                    &:after, &:before {
                      right: 0 !important;
                      left: -1px !important;
                      border-radius: @border-radius;
                    }

                    &:after {
                      .with-theme({
                        background: @vars[blue-100-to-900];
                      })
                    }
                  }
                }

                &:before {
                  display: none;
                  left: 6px;
                  right: unset;
                  width: 32px;
                  height: 32px;
                  line-height: 32px;
                  border-radius: @border-radius;
                }

                &-disabled:before {
                  display: none;
                  background: rgba(0,0,0,0.04);
                }

                .ant-picker-cell-inner {
                  width: 32px;
                  height: 32px;
                  border-radius: 50%;
                  font-size: 14px;
                  line-height: 32px;
                  letter-spacing: -0.3px;
                  font-weight: 500;
                  .with-theme({
                    background: transparent;
                    color: @vars[neutral-800-to-100];
                  });

                  &:before {
                    border-radius: 50%;
                    .with-theme({
                      border: 1px solid @vars[neutral-200-to-neutral-dark-200];
                    });
                  }

                  &.ant-picker-future {
                    .with-theme({
                      color: @vars[neutral-300-to-neutral-dark-300];
                    })
                  }
                }
              }

              thead tr {
                .with-theme({
                  border-bottom: 1px solid @vars[neutral-100-to-neutral-dark-100];
                })
              }

              th {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                width: 32px;
                height: 36px;
                letter-spacing: -0.3px;
                .with-theme({
                  color: @vars[neutral-500-to-neutral-dark-500];
                })
              }
            }

            .ant-picker-header {
              border: none;
              padding: 0 4px;

              button {
                line-height: 32px;
              }

              &-view {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                pointer-events: none;
                letter-spacing: -0.3px;
                .with-theme({
                  color: @vars[neutral-800-to-100];
                });
              }

              .ant-picker-header-super-prev-btn, .ant-picker-header-super-next-btn {
                display: none;
              }

              .ant-picker-header-prev-btn {
                svg {
                  transform: rotate(90deg);
                }
              }

              .ant-picker-header-next-btn {
                svg {
                  transform: rotate(-90deg);
                }
              }

              .ant-picker-header-prev-btn, .ant-picker-header-next-btn {

                svg path {
                  .with-theme({
                    stroke: @vars[neutral-600-to-neutral-dark-500];
                  });
                }
              }
            }
          }
        }
      }
    }
  }

  &-title {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 10px;
    .with-theme({
      color: @vars[neutral-800-to-100];
    });
  }

  &-rangepicker {
    background: transparent;
    margin-top: 0;
    width: 100%;
    border: none;
    outline: none;
    box-shadow: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;

    .ant-picker-input {
      display: none;
    }

    .ant-picker-active-bar, .ant-picker-range-separator, .ant-picker-suffix {
      display: none;
    }
  }
}
.ant-dropdown {
  &-trigger.old-dropdown-database {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 15px;
    border-radius: @border-radius;
    transition: border 0.3s linear;

    .with-theme({
      background: @vars[color-white-to-dark];
      border: 1px solid @vars[neutral-100-to-800];
      color: @vars[neutral-800-to-100];
    });

    svg {
      transition: transform 0.2s linear, color 0.2s linear;
    }

    &[disabled] {
      color: @Neutral500;
      .with-theme({
        background: @vars[neutral-100-to-800];
      });
    }

    &:not([disabled]):hover {
      .with-theme({
        border: 1px solid @bluePrimary;
      });
    }

    &.ant-dropdown-open {
      .with-theme({
        border: 1px solid @bluePrimary;
      });

      svg {

        &:last-of-type {
          transform: rotateZ(180deg);
        }
      }
    }

    .old-dropdown-database_value {
      margin-left: 8px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      flex: 1 1;
      .with-theme({
        color: @vars[neutral-800-to-200];
      });
    }
  }


  .old-dropdown-database {
    &-menu {
      border-radius: @border-radius;
      padding: 7px;
      .with-theme({
        background: @vars[color-white-to-dark];
        border: 1px solid @vars[neutral-100-to-800];
      });
      overflow: hidden;

      .ant-input-affix-wrapper.dropdown-search.input {
        height: 40px;

        .ant-input-prefix {
          margin-right: 8px;
        }


        &:hover{
          border: 1px solid @bluePrimary;
        }

        &.ant-input-affix-wrapper-focused{
          .with-theme({
            border: 1px solid @bluePrimary;
          })
        }

        > .ant-input {
          .with-theme({
            color: @vars[neutral-800-to-200];
            background: @vars[color-white-to-dark];

            &::placeholder {
              color: @vars[neutral-300-to-700];
            }
          });
        }
      }
    }

    &-list {
      margin-top: 8px;
      overflow-y: auto;
      .custom-scroll-bar();

      &-item {
        padding: 8px 0 8px 12px;

        .ant-checkbox-wrapper{
          width: 100%!important;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-family: 'Inter', sans-serif;
          font-weight: 400;
          min-height: 24px;
          line-height: 20px;
          .with-theme({
            color: @vars[neutral-800-to-200];
          });

          .ant-checkbox + span {
            padding-right: 0;
          }

          &:hover {
            .ant-checkbox {
              .ant-checkbox-inner {
                .with-theme({
                  border-color: @bluePrimary;
                });
              }
            }
          }

          .ant-checkbox {
            top: 0;
            .ant-checkbox-inner {
              width: 20px;
              height: 20px;
              border-radius: @border-radius;
              .with-theme({
                border: 1.5px solid @vars[neutral-200-to-700];
                background-color: @vars[color-white-to-dark];
              });

              &:after {
                content: url("../../../Images/checkbox-mark.svg");
                left: 50%;
                top: 50%;
                font-size: 0;
                border: none;
                width: 16px;
                height: 16px;
                transform: scale(1) translate(-50%, -50%);
              }
            }

            &.ant-checkbox-checked {
              .ant-checkbox-inner {
                .with-theme({
                  border-color: @bluePrimary;
                  background-color: @bluePrimary;
                });
              }
            }
          }
        }

        .ant-checkbox-wrapper.ant-checkbox-wrapper-disabled{
          .ant-checkbox-disabled {

            .ant-checkbox-inner{
              .with-theme({
                border-color: @vars[neutral-300-to-500] !important;
              })
            }

            & + span {
              .with-theme({
                color: @vars[neutral-300-to-500];
              })
            }
          }
        }
      }
    }
  }
}

.old-dropdown-database-wrapper {
  z-index: 1100;
  top: 75px !important;

  &-inner {
    top: 100px !important;
  }
}
.old-dropdown-date {
  &-info {
    &-wrapper {
      font-family: 'Inter', sans-serif;
      font-weight: 500;
      margin-right: 12px;

      @media @viewport-mobile {
        display: none;
      }
    }

    &-date {
      font-size: 14px;
      line-height: 20px;
      .with-theme({
        color: @vars[neutral-800-to-200];
      });
    }

    &-timezone {
      font-size: 12px;
      line-height: 16px;
      color: @Neutral500;
      margin-top: 4px;
    }
  }

  &-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: calc(342px + 21px);

    @media @viewport-mobile {
      margin-top: 345px;
    }
  }

  &-button {
    transition: all .3s ease;
    padding: 12px 20px;
    height: 40px !important;
    .with-theme({
      border: 1px solid @vars[neutral-100-to-800];
      color: @vars[neutral-800-to-200];
      background: @vars[color-white-to-dark];
    });

    &:hover {
      border-color: @bluePrimary;
      color: @bluePrimary;
      background: transparent;
    }

    &.apply {
      border: none;
      margin-left: 16px;
      background: @bluePrimary;
      color: @white;

      &:hover {
        color: @white;
        background: @blueDepressed;
      }

      &[disabled]{
        .with-theme({
          color: @vars[neutral-300-to-700];
          background: @vars[neutral-100-to-800];
        });
      }
    }
  }

  &-wrapper {
    width: 752px;
    height: 616px;
    padding: 24px;
    border-radius: @border-radius;

    @media @viewport-mobile {
      width: 100%;
      height: 504px;
    }

    .with-theme({
      border: 1px solid @vars[neutral-100-to-800];
      background: @vars[color-white-to-dark];
    });

    .old-rangepicker-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 24px;

      @media @viewport-mobile {
        display: none;
      }

      span {
        display: block;
        width: 336px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 10px;
        .with-theme({
          color: @vars[neutral-800-to-200];
        });
      }
    }

    .ant-picker-dropdown-range {
      width: 702px;
      top: 193px !important;
      padding: 7px 0;
      animation-duration: 0s !important;
      left: 27px !important;

      @media @viewport-mobile {
        width: calc(100% - 48px);
        top: 103px !important;
        padding: 0;
        left: 24px !important;
      }

      .ant-picker-panel-container {
        width: 100%;
        background: transparent;
        box-shadow: none;

        .ant-picker-panel-layout > div {
          width: 100%;
        }

        .ant-picker-panels {
          width: 100%;
          display: flex;
          justify-content: space-between;

          @media @viewport-mobile {
            justify-content: center;
          }

          .ant-picker-panel {
            width: 320px; //336px;
            border: none;

            @media @viewport-mobile {
              width: 100%;

              .ant-picker-header-next-btn {
                visibility: visible !important;
              }

              &:last-of-type {
                width: 0;
              }
            }

            .ant-picker-date-panel {
              width: 100%;

              .ant-picker-body{
                padding: 16px 0 0;

                @media @viewport-mobile {
                  padding: 0;
                }
              }
            }

            .ant-picker-content {
              width: 100%;

              .ant-picker-cell {
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;
                padding: 6px 0;
                .with-theme({
                  color: @vars[neutral-800-to-200];
                });

                &.ant-picker-cell-range-end {
                  .ant-picker-future {
                    color: @white !important;
                  }
                }

                &.ant-picker-cell-in-range {
                  .ant-picker-future {
                    color: @bluePrimary !important;
                  }
                  .ant-picker-cell-inner {
                    color: @bluePrimary;

                    &:after, &:before {
                      right: 0 !important;
                      left: -1px !important;
                      border-radius: @border-radius;
                    }

                    &:after {
                      .with-theme({
                        background: @vars[blue-100-to-900];
                      })
                    }
                  }
                }

                &:before {
                  left: 6px;
                  right: unset;
                  width: 32px;
                  height: 32px;
                  line-height: 32px;
                  border-radius: @border-radius;
                }

                &-disabled:before {
                  background: rgba(0,0,0,0.04);
                }

                .ant-picker-cell-inner {
                  width: 32px;
                  height: 32px;
                  line-height: 32px;
                  border-radius: @border-radius;

                  &:before {
                    border-radius: @border-radius;
                  }

                  &.ant-picker-future {
                    .with-theme({
                      color: @vars[neutral-300-to-500];
                    })
                  }
                }
              }

              th {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                width: 32px;
                height: 32px;
                color: @Neutral500;
              }
            }

            .ant-picker-header {
              border: none;
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              padding: 0;
              .with-theme({
                color: @vars[neutral-800-to-200];
              });

              button {
                line-height: 32px;
              }

              &-view {
                height: 32px;
                line-height: 32px;
                pointer-events: none;
                font-weight: 500;
              }

              .ant-picker-header-super-prev-btn, .ant-picker-header-super-next-btn {
                line-height: 32px;
                display: none;
              }

              .ant-picker-header-prev-btn, .ant-picker-header-next-btn {
                height: 32px;
                line-height: 32px;
                .with-theme({
                  color: @vars[neutral-800-to-200];
                });
              }
            }
          }
        }
      }
    }
  }

  &-title {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 10px;
    .with-theme({
      color: @vars[neutral-800-to-200];
    });
  }

  &-oldRangePicker {
    background: transparent;
    margin-top: 0;
    width: 100%;
    border: none;
    outline: none;
    box-shadow: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;

    .ant-picker-input {
      padding: 8px 16px;
      font-size: 14px;
      line-height: 20px;
      border-radius: @border-radius;
      background: transparent;
      caret-color: @bluePrimary;
      transition: all .3s ease;
      max-width: 336px;

      @media @viewport-mobile {
        display: none;
      }

      .with-theme({
        border: 1px solid @vars[neutral-100-to-800];
      });

      &:hover {
        border-color: @bluePrimary;
      }

      &:focus-within {
        border-color: @bluePrimary;
        box-shadow: 0 0 0 2px rgba(34, 90, 234, .2);
      }

      & input {
        .with-theme({
          color: @vars[neutral-800-to-200];
        })
      }

      & input::placeholder {
        .with-theme({
          color: @vars[neutral-300-to-700];
        })
      }
    }

    .ant-picker-active-bar, .ant-picker-range-separator, .ant-picker-suffix {
      display: none;
    }
  }
}

.ant-picker-range-arrow {
  display: none !important;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  .with-theme({
    background: @vars[blue-100-to-900] !important;
  })
}

.ant-picker-cell-disabled.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: @bluePrimary !important;
}

.ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner, .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
  .with-theme({
    background: transparent;
    color: @bluePrimary;
  })
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before, .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
  .with-theme({
    background: @vars[neutral-200-to-800] !important;
  })
}

.ant-picker-clear {
  background: transparent;
  display: flex;
  align-items: center;
}


.ant-picker-cell-in-view.ant-picker-cell-range-start:hover .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end:hover .ant-picker-cell-inner {
  background: @blueDepressed;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-already-selected, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-already-selected{
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    bottom: -1px;
    right: -1px;
    border-radius: @border-radius;
    border: 0 solid rgba(34, 90, 234, .2);
    opacity: 1;
    -webkit-animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
    animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    display: block;
    pointer-events: none;
  }
  @-webkit-keyframes waveEffect {
    100% {
      top: -6px;
      left: -6px;
      bottom: -6px;
      right: -6px;
      border-width: 6px;
    }
  }
  @keyframes waveEffect {
    100% {
      top: -6px;
      left: -6px;
      bottom: -6px;
      right: -6px;
      border-width: 6px;
    }
  }
  @-webkit-keyframes fadeEffect {
    100% {
      opacity: 0;
    }
  }
  @keyframes fadeEffect {
    100% {
      opacity: 0;
    }
  }
}
