@import '../../../../../styles/theme/theme';
@import '../../../../../styles/base/ui-kit';
@import '../../../../../styles/utils/variables';
@import '../../../../../styles/utils/mixin';

.competitors-shopify-block {
  margin-top: 48px;
  .block-with-label {
    &_label {
      .interests-approved {
        background: #E7FFF1;
        border-radius: @border-radius;
        width: 140px;
        height: 48px;
        line-height: 48px;
        color: #16895A !important;
        text-align: center !important;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 22px;
        font-family: 'Inter', sans-serif;
        font-weight: 600;
        &.empty_interests {
          background-color: #FFF4F4 !important;
          color: #D71313 !important;
          span {
            display: inline-block;
            &:last-child {
              padding-left: 3px;
              font-size: 16px;
              font-family: 'Inter', sans-serif;
              font-weight: 500;
              color: #D71313 !important;
            }
          }
        }
        span {
          &:last-child {
            padding-left: 3px;
            display: inline-block;
            font-size: 16px;
            font-family: 'Inter', sans-serif;
            font-weight: 500;
            color: #16895A !important;
          }
        }
      }
    }
  }

  .container--table-view.competitors--shopify {
    padding: 0;
    .search {
      &_wrapper {
        .search-input {
          margin-right: 0;
          .ant-input-wrapper {
            display: flex;

            .ant-input-affix-wrapper {
              width: calc(100% - 108px);
            }
            .ant-input-group-addon {
              display: block;
              background-color: transparent;
              .ant-input-search-button {
                height: 48px;
                border-radius: @border-radius;
                width: 96px;
                margin-left: 12px;
              }
            }
          }

          &.shopify-search_active{
            .ant-input-wrapper {
              .ant-input-group-addon {
                .ant-input-search-button {
                  background: @blue600;
                  border: 1px solid @blue600;
                }
              }
            }
          }
        }
      }
    }
    .shopify-list {
      height: 600px;
      .empty-data-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        &_icon {
          .icon-empty-warning {
            path {
              fill: #C5CCE3 !important;
            }
          }
        }
        &_text {
          font-size: 22px;
          line-height: 32px;
          color: #C5CCE3;
          font-family: 'Inter', sans-serif;
          font-weight: 600;
        }
      }

      .ant-spin-nested-loading {
        .ant-spin-container {
          display: flex;
          align-items: center;
          justify-content: center;
          .empty-data-block {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            &_icon {
              .icon-empty-warning {
                path {
                  fill: #C5CCE3 !important;
                }
              }
            }
            &_text {
              font-size: 22px;
              line-height: 32px;
              color: #C5CCE3;
              font-family: 'Inter', sans-serif;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}

.products-details.shopify-products {
  overflow: hidden;
  position: relative;
  height: 100%;

  &--empty {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
  }

  // TABLE
  .list-table {
    &.shopify {
      position: relative;
      height: 100%;
    .ant-table {
      line-height: 1;
      background-color: transparent;
      .ant-table-container {
        .ant-table-header.ant-table-sticky-holder,
        .ant-table-sticky-scroll {
          background: transparent;
        }

        .ant-table-body {
          overflow-y: scroll;
          height: 568px;
          padding-bottom: 0px;

          @media @viewport-mobile {
            overflow-x: scroll;
          }
          padding-right: 6px !important;
          &::-webkit-scrollbar {
            width: 4px;
            border-radius: 2px;
          }
          &::-webkit-scrollbar-button {
            display: none;
          }
          &::-webkit-scrollbar-track-piece {
            background: transparent;
            border-radius: 2px;
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 2px;
            .with-theme({
              background: @vars[neutral-200-to-neutral-dark-200];
            });
          }
        }
      }
    }
    .ant-checkbox-wrapper {
      height: 58px;
      align-items: center;
      .ant-checkbox {
        .ant-checkbox-inner {
          align-items: center;
          justify-content: center;
          border-radius: @border-radius;
          width: 24px;
          height: 24px;
          border: 1.5px solid @Neutral200;
          &:after{
            content: url("../../../../Images/checkbox-mark.svg");
            left: 50%;
            top: 50%;
            font-size: 0;
            border: none;
            width: 16px;
            height: 16px;
            transform: scale(1) translate(-50%, -50%);
          }
        }
        &.ant-checkbox-checked {
          .ant-checkbox-inner {
            border: 1.5px solid @blue500;
          }
        }
      }
    }

    .ant-table-empty .ant-table-tbody > tr.ant-table-placeholder {
      > td {
        .with-theme({
          border-color: @vars[defatult-border-color];
        });

        .ant-empty-description {
          color: @Neutral500;
        }
      }
      &:hover > td {
        .with-theme({
          background: @vars[color-white-to-dark];
        });
      }
    }

    .ant-table-content {
      table {
        position: relative;
        width: 100%;
      }
    }

    .ant-table-thead {
      > tr > th {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: @Neutral500;
        text-align: left;
        position: relative;
        border-bottom: 2px solid transparent;
        padding-left: 14px!important;
        &:first-child {
          padding-left: 10px !important;
        }

        .with-theme({
          background: @vars[color-white-to-dark];
        });

        &.ant-table-column-has-sorters {
          padding-left: 0;
          padding-right: 0;

          .ant-table-column-sorters {
            justify-content: center !important;

            &:hover {
              .table-column-title > span {
                color: @blue !important;
              }
            }
          }

          .table-column-title {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            span {
              font-size: 16px;
              line-height: 24px;
              color: @Neutral500;
            }
          }

          .ant-table-column-sorters
            > .ant-table-column-sorter
            > .ant-table-column-sorter-inner {
            display: none !important;
          }
        }
        &:first-child {
          text-align: left;
          width: 200px;
          border-top-left-radius: 0;

          @media @viewport-mobile {
            &::after {
              box-shadow: none;
            }
          }
        }
        &:last-child {
          border-top-right-radius: 0;
        }

        &::before {
          background-color: transparent !important;
        }
        &::after {
          content: '';
          height: 1px;
          width: 100%;
          background-color: @Neutral100;
          position: absolute;
          z-index: 9;
          bottom: -2px;
          left: 0;
          .with-theme({
            background-color: @vars[defatult-border-color];
          });
          @media @viewport-mobile {
            content: none;
          }
        }

        &:first-child::after {
          left: 10px;
          @media @viewport-mobile {
            border: none;
            all: inherit;
            content: '';
            padding: 0;
            width: calc(100vw - 40px);
            background-color: @Neutral100;
            position: absolute;
            z-index: 3;
            bottom: -1px;
            left: 0;
            height: 1px;
            border-bottom: 1px;
            .with-theme({
            background-color: @vars[defatult-border-color];
          });
          }
        }
        &:last-child::after {
          left: -10px;
        }

        @media @viewport-mobile {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }

    .details-row {
      position: relative;

      td.ant-table-cell {
        .title-column-shopify {
          line-height: 64px;
          display: flex;
          align-items: center;

          .imag-wrap{
            width: 48px;
            height: 48px;
            border-radius: @border-radius;
            display: flex;
            align-items: center;

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
              border-radius: @border-radius;
            }
          }
          @media @viewport-mobile {
            .title-column-icon {
              svg > path {
                stroke: #C5CCE3;
              }
            }
            .title-column-icon.title-column-icon-saved {
              svg > path {
                stroke: @blue;
              }
            }
          }

          .title-column-text {
            font-size: 14px;
            line-height: 20px;
            margin-left: 16px;
            color: @Neutral800;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            word-break: break-word;
            width: 20vw;
            display: inline-block;
            .with-theme({
              color: @vars[neutral-800-to-200];
            });

            @media @viewport-mobile {
              white-space: normal;
              width: 30vw;
              padding: 2px;
              height: 45px;
              text-align: left;
            }
          }
        }
      }

      td.ant-table-cell {
        border-bottom: 1px solid transparent;
        border-top: 1px solid transparent;
        position: relative;
        text-align: center;
        padding: 12px 16px 11px 12px;

        .with-theme({
          background: @vars[color-white-to-dark];
        });
        @media @viewport-mobile {
          padding: 8px 0 8px 0;
          border: none;
        }

        .title-column.column-shopify {
          line-height: 64px;
          display: flex;
          align-items: center;
          max-width: 100%;
          white-space: nowrap;
          overflow-x: hidden;
          text-overflow: ellipsis;
          font-size: 16px;
          color: @Neutral800;
          img {
            width: 48px;
            height: 48px;
            border-radius: @border-radius;
          }
          @media @viewport-mobile {
            .title-column-icon {
              svg > path {
                stroke: #C5CCE3;
              }
            }
            .title-column-icon.title-column-icon-saved {
              svg > path {
                stroke: @blue;
              }
            }
          }

          .title-column-text {
            font-size: 14px;
            line-height: 20px;
            margin-left: 16px;
            color: @Neutral800;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            word-break: break-word;
            width: 20vw;
            display: inline-block;
            .with-theme({
              color: @vars[neutral-800-to-200];
            });

            @media @viewport-mobile {
              white-space: normal;
              width: 30vw;
              padding: 2px;
              height: 45px;
              text-align: left;
            }
          }
        }

        &:first-child {
          border-left: 1px solid transparent;
          text-align: left;
          padding: 12px 16px 11px 12px;

          @media @viewport-mobile {
            padding: 8px 0 8px 0;
            border: none;
          }
        }
        &:last-child {
          border-right: 1px solid transparent;
          padding: 12px 12px 11px 0;
          max-width: 30px !important;
          @media @viewport-mobile {
            padding: 0;
            border: none;
          }
        }

        &::after {
          content: '';
          height: 1px;
          width: 100%;
          background-color: @Neutral100;
          position: absolute;
          z-index: 3;
          bottom: -2px;
          left: 0;

          .with-theme({
            background: @vars[defatult-border-color];
          });
          @media @viewport-mobile {
            content: none;
          }
        }

        &:first-child::after {
          left: 10px;

          @media @viewport-mobile {
            all: inherit;
            content: '';
            padding: 0;
            height: 1px;
            width: calc(100vw - 40px);
            background-color: @Neutral100;
            position: absolute;
            z-index: 3;
            bottom: 0;
            left: 0;
            border: none;
            .with-theme({
              background-color: @vars[defatult-border-color];
            });
          }
        }
        &:last-child::after {
          left: -10px;
        }

        > span {
            .score_counter();

          > img {
            display: inline-block;
            margin-right: 12px;
            width: 64px;
            height: 64px;
            border-radius: @border-radius;
            @media @viewport-mobile {
              margin-right: 8;
              margin-left: 14px;
              width: 48px;
              height: 48px;
            }
          }
        }
      }

      &:hover {
        .some_td_styles();
      }
      .saved-overview {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .icon-bookmark {
          margin-right: 20px;
          &:hover {
            path {
              stroke: @blue;
            }
          }
        }

        .icon-external-link {
          &:hover {
            path {
              fill: @blue;
            }
          }
        }
      }
    }
  }
  }
}

.ant-input-search.shopify-search  > .ant-input-group > .ant-input-group-addon:last-child {
  left: 224px;
  z-index: 10;
}

.competitors-shopify-block {
  .custom-select.ant-select:not(.ant-select-customize-input) .ant-select-selector {
      left: -102px;
    }
  .custom-select.ant-select:not(.ant-select-customize-input) .ant-select-arrow {
    left: 87px;
  }
}
