@import '../../../styles/theme/theme';
@import '../../../styles/base/animation';
@import '../../../styles/base/ui-kit';

.drops {
  &_timer {
    max-width: calc(100vw - 72px);
    padding: 20px 32px;
    margin: 0;
    .with-theme({
      border-bottom: 1px solid @vars[neutral-200-to-neutral-dark-200];
    });

    @media @viewport-mobile {
      max-width: 100vw;
      padding: 0;
    }
  }

  &_wrapper {
    .no-scroll-bar();
    height: calc(var(--app-height) - 125px - 93px);
    overflow-y: auto;

    @media @viewport-mobile {
      padding: 16px;
      height: calc(100dvh - 72px);
    }

    &_block{
      max-width: calc(100vw - 72px);
      padding: 24px 32px;
      margin: 0 auto;

      @media @viewport-mobile {
        padding: 0;
        max-width: 100vw;
      }

      h3 {
        font-family: 'Inter', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.3px;
        margin-bottom: 16px;
        text-align: left;
        .with-theme({
          color: @vars[neutral-500-to-neutral-dark-500];
        });

        @media @viewport-mobile {
          margin-bottom: 12px;
          font-size: 14px;
        }

        &:last-of-type {
          margin-top: 24px;
        }
      }
    }
  }
}

.tag {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -13px;
  left: 0;
  right: 0;
  width: fit-content;
  margin: 0 auto;
  padding: 2px 8px;
  border-radius: @border-radius;
  text-align: center;
  text-transform: capitalize;
  height: 20px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.3px;
  word-break: normal;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  z-index: 100;
  .with-theme({
    border: 1px solid @vars[neutral-200-to-neutral-dark-200];
  });

  @media @viewport-mobile {
    top: -6px;
  }
}

.calendar {

  &-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }

  position: relative;
  padding: 16px;
  overflow: hidden;
  width: calc(50% - 8px);
  border-radius: 16px;
  .with-theme({
    background: @vars[neutral-50-to-neutral-dark-100];
  });

  @media @viewport-mobile {
    width: 100%;
  }

  &_header {
    .flex-row-space-between();
  }

  &_date {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.3px;
    padding: 0 0 16px;
    .with-theme({
      color: @vars[neutral-800-to-100];
    });

    @media @viewport-mobile {
      padding: 0 0 12px;
      font-size: 14px;
    }

  }

  &_month {
    display: inline-block;
    position: relative;
    margin-right: 20px;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: -14px;
      bottom: 0;
      margin: auto;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      .with-theme({
        background-color: @vars[neutral-300-to-neutral-dark-300];
      })
    }
  }

  &_year {
    display: inline-block;
  }

  &_link {
    &--disabled {
      cursor: not-allowed;
    }
  }

  &_wrapper {
    position: relative;
    display: flex;
    flex-flow: nowrap row;
    justify-content: flex-start;

    @media @viewport-mobile {
      flex-flow: column nowrap;
      align-items: stretch;
    }
  }

  &_item {
    display: block;
    position: relative;
    .week--coming-up, .week--feature {
      cursor: not-allowed;
    }
  }

  &_item-wrapper {
    position: relative;
    margin-right: 12px;
    flex: 1 0 auto;
    height: 128px;
    z-index: 1;

    @media @viewport-mobile {
      margin: 0 0 16px;
      min-height: 0;
      height: 48px;
      max-width: 100%;
    }

    &:last-child {
      margin-right: 0;

      @media @viewport-mobile {
        margin: 0 0 0;
      }
    }
  }
}

.week {
  border-radius: 12px;
  display: block;
  position: relative;
  padding: 8px 12px;
  z-index: 99;
  height: 100%;
  .trans();
  .with-theme({
    background: @vars[white-to-neutral-dark-100];
    box-shadow: @vars[box-shadow-btn-secondary];

    &:not(&--disabled):not(&--coming-up):hover {
      background: @vars[neutral-50-to-neutral-dark-200];
    }
  });

  @media @viewport-mobile {
    display: flex;
    align-items: center;
  }

  &_wrap {
    @media @viewport-mobile {
      display: flex;
      gap: 8px;
      align-items: center;
    }
  }

  .week_num,
  .week_text,
  .week_day,
  .week_month,
  .week_id {
    .trans();
  }

  .week_num {
    .with-theme({
      color: @vars[neutral-800-to-100];
    })
  }

  .week_text {
      .with-theme({
        color: @vars[neutral-600-to-neutral-dark-500];
      });
  }

  &_glob_wrap {
    position: relative;
    z-index: 1;

    &.disabled {
      &:hover {
        cursor: not-allowed;
      }
    }
    @media @viewport-mobile {
      width: 100%;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
    }
  }

  &--coming-up, &--disabled {
    .week_day,
    .week_month,
    .week_id,
    .week_num,
    .week_text {
      .with-theme({
        color: @vars[neutral-300-to-neutral-dark-300];
      });
    }
  }

  &_num {
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: -1.2px;
    text-align: center;

    @media @viewport-mobile {
      line-height: 28px;
      font-size: 20px;
    }
  }

  &_text {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.3px;
    margin-top: 4px;
    text-align: center;

    @media @viewport-mobile {
      margin-top: 0;
    }
  }

  &_day {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    margin-top: 12px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.3px;
    .with-theme({
      color: @vars[neutral-800-to-100];
    });

    @media @viewport-mobile {
      margin-top: 0;
      font-size: 14px;
    }
  }

  &_id {
    text-align: center;
    margin-top: 8px;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.3px;
    .with-theme({
      color: @vars[neutral-300-to-neutral-dark-300];
    });

    @media @viewport-mobile {
      margin-top: 2px;
    }
  }
}

.week-tag {
  &--latest {
    .tag();
    .with-theme({
      background: @vars[white-to-orange-primary];
      color: @vars[orange-primary-to-orange-xlight];
    });
  }

  &--coming-up {
    .tag();
    .with-theme({
      background: @vars[white-to-neutral-dark-310];
      color: @vars[neutral-300-to-neutral-dark-300];
    });
  }
}
