@import '../../../../../styles/theme/theme';
@import '../../../../../styles/base/ui-kit';
@import '../../../../../styles/base/animation';
@import '../../../../../styles/utils/variables';

.benefits-drawbacks-block {
  margin-top: 48px;
  margin-bottom: 48px;
  max-height: 480px;
  min-height: 480px;

  .block-with-label {
    &_label {
      min-height: 48px;
      display: flex;
      width: 100%;

      > div {
        width: 50%;
        display: flex;
        align-items: center;
        .btn-primary {
          display: inline-block;
          margin-left: auto;
        }
        .blue-block {
          display: inline-block;
          margin-left: 8px;
        }
        &:first-child {
          padding-right: 16px;
        }
        &:last-child {
          padding-left: 16px;
        }
      }
    }
  }
  .block-with-list {
    max-height: 408px !important;
    min-height: 408px !important;
    margin-top: 12px;
    .bd-list-wrapper {
      width: 100%;
      .bd-list-benefits {
        padding-right: 16px;
      }
      .bd-list-drawbacks {
        padding-left: 16px;
      }
      .bd-list-block {
        width: 100%;
        &_item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 24px;
          height: 48px;
          &:last-child {
            margin-bottom: 0;
          }
          &-main {
            display: flex;
            div {
              display: inline-block;
            }
            .benefit-drawback-text {
              div {
                display: block;
                max-width: 300px;
                overflow: hidden;
              }
              .bd-item-title {
                font-size: 16px;
                line-height: 24px;
                color: #151E3A;
                margin-bottom: 8px;
              }
              .bd-item-subtitle {
                font-size: 12px;
                line-height: 16px;
                color: #707BA0;
              }
            }
            .benefit-drawback-img {
              margin-right: 12px;
              width: 48px;
              height: 48px;
              border-radius: @border-radius;
              display: flex;
              align-items: center;
              justify-content: center;
              svg {
                width: 32px;
                height: 32px;
              }
              &.drawback {
                .with-theme({
                  background-color:  @vars[yellow-100-to-900];
                });
                svg {
                  *[stroke='#323232'] {
                    stroke: @yellow500;
                  }
                }
              }
              &.benefit {
                .with-theme({
                  background-color:  @vars[blue-100-to-900];
                });
                svg {
                  *[stroke='#323232'] {
                    stroke: @blue500;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
