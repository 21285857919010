@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';

.delete-filter-preset-wrapper {

  .delete-preset-divider {
    margin-top: 24px;
  }

  .delete-filter-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 24px;
  }

  .delete-preset-button-cancel {
    background: transparent;
    transition: all .3s ease;
    padding: 16px 24px;
    .with-theme({
      border: 1px solid @vars[neutral-200-to-neutral-dark-200];
      color: @vars[neutral-800-to-white];
    });

    &:hover {
      border-color: @bluePrimary;
      color: @bluePrimary;
    }

    &--orange {
      color: @white !important;
      border: none !important;
      background: @yellowPrimary !important;

      &:hover {
        background: @yellowDepressed !important;
      }
    }

    &.close-error-modal {
      background: @bluePrimary;
      color: @white;
      border: none !important;

      &:hover {
        background: @blueDepressed;
        color: @white;
      }
    }
  }

  .delete-preset-button-apply {
    margin-left: 16px;
    border: none !important;
    background: @redPrimary;
    color: @white !important;

    &:hover {
      background: @redDepressed;
      color: @white !important;
    }
  }
}
