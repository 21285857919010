@import '../../styles/theme/theme';
@import '../../styles/base/animation';

.icon {
  .trans();
  &-btn{
    cursor: pointer;
  }
  &-user-default {
    vertical-align: 0;
  }
  &.icon-notification-delete {
    rect {
      .with-theme({
        fill: @vars[red-100-to-900];
      });
    }
  }
  &-sun {
    vertical-align: -3px;
    &-bold,
    &-outline {
      .with-theme({
        fill: @vars[fill-color];
      });
    }
  }

  &-moon {
    vertical-align: -3px;
    &-outline,
    &-bold {
      .with-theme({
        fill: @vars[fill-color];
      });
    }
  }

  &-notification {
    &-outline {
      .with-theme({
        stroke: @vars[notif-fill-color];
        fill: none;
      });
    }

    &-bold {
      .with-theme({
        fill: @vars[notif-fill-color];
      });
    }

    &--disabled {
      .with-theme({
        stroke: @vars[neutral-200-to-800];
      })
    }
  }

  &-chat {
    .with-theme({
      stroke: @vars[notif-fill-color];
      fill: none;
    });
  }

  &-empty-warning {
    .with-theme({
      fill: @vars[notif-empty-icon-color];;
    });
  }

  &-menu {
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    transition: transform 400ms;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .line {
      fill:none;
      transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
      strokeWidth:5.5;
      strokeLinecap:round;

      .with-theme({
        stroke: @vars[menu-fill];
      });
    }

    .top {
      stroke-dasharray: 40 160;
    }
    .middle {
      stroke-dasharray: 40 142;
      transform-origin: 50%;
      transition: transform 400ms;
    }
    .bottom {
      stroke-dasharray: 40 85;
      transform-origin: 50%;
      transition: transform 400ms, stroke-dashoffset 400ms;
    }

    &.active {
      transform: rotate(45deg);
      .top {
        stroke-dashoffset: -64px;
      }
      .middle {
        transform: rotate(90deg);
      }
      .bottom {
        stroke-dashoffset: -64px;
      }
    }
  }

  &-boosters {
    vertical-align: -5px;

    &:hover {
      path:nth-of-type(2) {
        .with-theme({
          fill: @vars[sidebar-fill-bold] !important;
        });
      }
    }
  }

  &-chevron-left {
    vertical-align: -8px;
    .with-theme({
      fill: @vars[sidebar-fill-outline];
    });
  }

  &-view-port {
    .with-theme({
      fill: @vars[blue-100-to-900];
    });
  }

  &-view-port-table {
    .with-theme({
      fill: @vars[blue-100-to-900];
    });
  }

  &-bookmark {
    vertical-align: -5px;
  }

  &-arrow-downward {
    path {
      .with-theme({
        fill: @vars[neutral-200-to-500];
      });
    }

    &.icon-descend {
      path:last-child {
        fill: @blue500;
      }
    }

    &.icon-ascend {
      path:first-child {
        fill: @blue500;
      }
    }
  }

  &-cart-upcoming {
    vertical-align: -3px;
    path {
      .with-theme({
        fill: @vars[neutral-500-to-100];
      });
    }
  }

  &-copy {
    .with-theme({
      fill: @vars[neutral-300-to-700];
    });

    &.active {
      fill: @blue500;
    }
  }

  &-speedometer-arrow {
    filter: drop-shadow(0px 0px 37.3897px rgba(110, 125, 175, 0.15));
    path {
      .with-theme({
        stroke: @vars[color-white-to-dark];
      });
    }
  }

  &-price,
  &-orders {
    .with-theme({
      fill: @vars[neutral-300-to-700];
    });
  }

  &-shopify{
    &.disabled{
      .with-theme({
      path:nth-child(1){
        fill: @vars[neutral-200-to-500];
      }
      path:nth-child(2){
        fill: @vars[neutral-300-to-700];
      }
      path:nth-child(3){
        fill: @vars[neutral-100-to-900];
      }
      });
    }
  }
}

.attention-outline-icon, .icon-database-filter {
  transition: all .3s ease;
  &:hover {
    fill: @bluePrimary !important;
  }
}
