@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';
@import '../../../../styles/utils/mixin';

.page-options-wrapper {
  width: 100%;
  padding: 24px 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .with-theme({
    border-bottom: 1px solid @vars[neutral-200-to-neutral-dark-200];
  });

  @media @viewport-mobile {
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }

  .search-options {
    display: flex;
    align-items: center;
    gap: 16px;

    @media @viewport-mobile {
      gap: 12px;
      width: 100%;
    }

    .search {
      display: flex;
      align-items: center;
      gap: 12px;

      @media @viewport-mobile {
        width: 100%;
        gap: 12px;
      }

      &-input {
        width: 400px;
        margin-right: 0;

        &.search-input-error .ant-input-affix-wrapper {
          border: 1px solid @redPrimary !important;
        }

        @media @viewport-mobile {
          width: calc(100% - 82px);
        }
      }

      &-submit {
        transition: all .3s ease;
        cursor: pointer;
        display: flex;
        align-items: center;
        border: none !important;
        height: 40px;
        padding: 6px 12px;
        border-radius: 10px;
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.3px;
        .with-theme({
          color: @vars[neutral-800-to-100];
          background: @vars[white-to-neutral-dark-50];
          box-shadow: @vars[box-shadow-btn-secondary];

          &:hover, &:focus {
            background: @vars[neutral-50-to-neutral-dark-100];
          }

          &.disabled {
            cursor: not-allowed;
            background: @vars[neutral-100-to-neutral-dark-100];
          }
        })
      }
    }

    .filters {
      user-select: none;
      cursor: pointer;
      gap: 8px;
      transition: all .3s ease;
      display: flex;
      align-items: center;
      border: none !important;
      height: 40px;
      padding: 6px 12px;
      border-radius: 10px;
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.3px;
      .with-theme({
        color: @vars[neutral-800-to-100];
        background: @vars[white-to-neutral-dark-50];
        box-shadow: @vars[box-shadow-btn-secondary];

        &:hover, &:focus, &.active {
          background: @vars[neutral-50-to-neutral-dark-100];
        }

        &.disabled {
          cursor: not-allowed;
          background: @vars[neutral-100-to-neutral-dark-100] !important;
        }
      });
    }
  }

  .view-options {
    display: flex;
    align-items: center;
    gap: 12px;

    @media @viewport-mobile {
      width: 100%;
    }

    .filters {
      user-select: none;
      cursor: pointer;
      gap: 8px;
      transition: all .3s ease;
      display: flex;
      align-items: center;
      border: none !important;
      height: 40px;
      padding: 6px 12px;
      border-radius: 10px;
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.3px;
      .with-theme({
        color: @vars[neutral-800-to-100];
        background: @vars[white-to-neutral-dark-50];
        box-shadow: @vars[box-shadow-btn-secondary];

        &:hover, &:focus, &.active {
          background: @vars[neutral-50-to-neutral-dark-100];
        }

        &.disabled {
          cursor: not-allowed;
          background: @vars[neutral-100-to-neutral-dark-100] !important;
        }
      });

      @media @viewport-mobile {
        width: calc(100% - 72px - 131px - 24px);
        justify-content: center;
      }
    }

    .sales-tracker-top-page-header-dropdown {
      width: 240px;

      &-option {
        width: 100%;

        svg path {
          .with-theme({
            stroke: @vars[neutral-600-to-neutral-dark-500];
          })
        }
      }
    }

    .product-view {
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 4px;
      border-radius: 10px;
      .with-theme({
        background: @vars[neutral-50-to-neutral-dark-100];
        border: 1px solid @vars[neutral-200-to-neutral-dark-200];
      });

      &.wide {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        letter-spacing: -0.3px;
        .with-theme({
          color: @vars[neutral-800-to-100];
        });

        .product-view-btn {
          width: 50%;
          gap: 8px;
        }
      }

      &-btn {
        transition: all .3s ease;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        .with-theme({
          svg path {
            stroke: @vars[neutral-600-to-neutral-dark-500];
          }
        });

        &--active {
          box-shadow: 0px 5px 10px -2px rgba(25, 28, 33, 0.06), 0px 2px 6px -2px rgba(106, 115, 133, 0.12), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
          .with-theme({
            background: @vars[white-to-neutral-dark-100];

            svg path {
              stroke: @vars[neutral-800-to-100];
            }
          });
        }
      }
    }

    .presets {
      transition: all .3s ease;
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 8px;
      border: none !important;
      height: 40px;
      padding: 6px 12px;
      border-radius: 10px;
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.3px;
      .with-theme({
        color: @vars[neutral-800-to-100];
        background: @vars[white-to-neutral-dark-50];
        box-shadow: @vars[box-shadow-btn-secondary];

        &:hover, &:focus {
          background: @vars[neutral-50-to-neutral-dark-100];
        }

        &.ant-dropdown-open:not(.sort) {
          svg:last-of-type {
            transform: rotate(180deg);
          }
        }

        svg:last-of-type {
          transition: all .3s ease;
          path {
            stroke: @vars[neutral-600-to-neutral-dark-500];
          }
        }
      })
    }
  }
}

.sales-tracker-top-page-header-dropdown-option.disabled {
  cursor: not-allowed;
  .with-theme({
    background: @vars[neutral-100-to-neutral-dark-100] !important;
    span {
      color: @vars[neutral-300-to-neutral-dark-300] !important;
    }
    svg path {
      stroke: @vars[neutral-300-to-neutral-dark-300];
    }
  })
}
