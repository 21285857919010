@import '../../../styles/theme/theme';
@import '../../../styles/base/ui-kit';

.product-database-page.competitors-page {

  .sales-tracker-top-page-header {

    .title-wrapper-icon {
      .with-theme({
        background: @vars[bg-yellow] !important;
        box-shadow: @vars[box-shadow-yellow] !important;
        border: 1px solid @vars[border-yellow] !important;
      });
    }
  }

  .competitors-filter-wrapper {
    padding: 0 32px 0;
    max-height: 0;
    transition: all .3s ease;
    overflow: hidden;

    @media @viewport-mobile {
      padding: 0 16px 0;
    }

    &.active {
      max-height: 900px;
      padding: 24px 32px 0;
      overflow: visible;

      @media @viewport-mobile {
        padding: 16px 16px 0;
        max-height: 1500px;
      }
    }
  }
}
