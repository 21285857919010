@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';

.product-details-card-header.filters-header {
  .link-block {
    display: flex;
    align-items: center;

    .link {
      color: @bluePrimary;
      transition: .3s linear all;
      &:hover {
        cursor: pointer;
        color: @blueDepressed;
      }
    }

    .link:first-of-type {
      margin-right: 16px;
    }
  }

  .header-watch-tutorial {
    cursor: pointer;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: @bluePrimary;

    svg {
      margin-left: 4px;
    }
  }

  @media @viewport-mobile {
    .title {
      font-size: 18px;
      line-height: 28px;
    }

    .link-block {
      display: none;
    }
  }
}

.product-details-card-body.filters-body {

  &.pd-body {
    .ant-collapse.ant-collapse-ghost {
      .ant-collapse-item {
        &.ant-collapse-no-arrow > .ant-collapse-header{
          padding: 10px 16px 24px;
        }
      }
    }
  }

  .block-with-label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &_label {
      width: 100%;
      display: inline-block;
      height: 24px;
      font-family: 'Inter', sans-serif;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 24px;

      @media @viewport-mobile {
        margin-bottom: 12px;
      }

      .with-theme({
        color: @vars[neutral-800-to-200];
      });

      &.space_between {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .important {
        color: @red500;
        padding-left: 4px;
      }

      .categories-tag {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: @bluePrimary;
        padding: 4px 8px;
        margin-left: 8px;
        border-radius: @border-radius;
        .with-theme({
          background: @vars[blue-100-to-900];
        })
      }

      .link {
        cursor: pointer;
        font-family: 'Inter', sans-serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: @bluePrimary;

        &:hover {
          color: @blueDepressed;
        }

        &.red {
          color: @redPrimary;

          &:hover {
            color: @redDepressed;
          }
        }
      }

      .icon-btn {
        width: 16px;
        height: 16px;
        font-size: 16px;
        line-height: 16px;
        margin-left: 4px;

        svg {
          width: 16px;
          height: 16px;
          .with-theme ({
            fill: @vars[neutral-300-to-700];
          })
        }
      }
    }

    .ant-checkbox-group {
      padding-bottom: 16px;
    }
  }

  .ant-checkbox-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    min-height: 24px;
    width: fit-content;
    line-height: 20px;
    .with-theme({
      color: @vars[neutral-800-to-200];
    });

    .ant-checkbox + span {
      padding-right: 0;
    }

    &:hover {
      .ant-checkbox {
        .ant-checkbox-inner {
          .with-theme({
            border-color: @bluePrimary;
          });
        }
      }
    }

    .ant-checkbox {
      top: 0;
      .ant-checkbox-inner {
        width: 20px;
        height: 20px;
        border-radius: @border-radius;
        .with-theme({
          border: 1.5px solid @vars[neutral-200-to-700];
          background-color: @vars[color-white-to-dark];
        });

        &:after {
          content: url("../../../Images/checkbox-mark.svg");
          left: 50%;
          top: 50%;
          font-size: 0;
          border: none;
          width: 16px;
          height: 16px;
          transform: scale(1) translate(-50%, -50%);
        }
      }

      &.ant-checkbox-checked {
        .ant-checkbox-inner {
          .with-theme({
            border-color: @bluePrimary;
            background-color: @bluePrimary;
          });
        }
      }
    }

    .country-flag {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: @blue100;
    }

    .info-wrap {
      margin-left: 8px;

      span {
        display: block;
      }

      span:last-of-type {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: @Neutral500;
      }
    }
  }
}

.product-details-card-body {

  .dropdowns-wrapper {
    padding: 12px 24px;
    display: flex;
    column-gap: 16px;
    row-gap: 24px;

    @media @viewport-mobile {
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
    }

    .select-block {
      width: calc((100% - 64px) / 4);

      @media @viewport-mobile {
        width: 100%;
      }

      &.active_adsets {
        .ant-dropdown.dropdown-database-wrapper {
          width: 350px !important;
        }
      }

      &.ad_status {
        .ant-dropdown.dropdown-database-wrapper {
          width: 100% !important;
        }
      }

      &.domain_tld {
        .ant-dropdown.dropdown-database-wrapper {
          left: unset !important;
          right: 0 !important;
        }
      }

      .ant-dropdown.dropdown-database-wrapper {
        width: 252px !important;
        left: 0 !important;
      }
    }
  }

  .selects-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;

    @media @viewport-mobile {
      margin-bottom: 12px;
      flex-direction: column;
    }
  }

  .switch-wrapper {
    display: flex;
    justify-content: space-between;

    @media @viewport-mobile {
      flex-direction: column;
      margin-top: 12px;
    }
  }

  .switch-button-wrapper {
    display: flex;
    justify-content: space-between;

    .switch-button-reset {
      background: transparent;
      transition: all .3s ease;
      padding: 12px 19px;
      height: 40px;
      .with-theme({
        border: 1px solid @vars[neutral-100-to-800];
        color: @vars[neutral-800-to-200];

        &:hover {
          color: @white !important;
        }
      });

      &:not([disabled]):hover {
        border-color: @bluePrimary;
        color: @bluePrimary;
      }
    }

    .switch-button-apply {
      margin-left: 16px;
      background: @bluePrimary;
      color: @white !important;
      border: none !important;

      &[disabled] {
        color: @Neutral500 !important;
        border: 1px solid transparent;
        .with-theme({
          background: @vars[neutral-100-to-800];
        })
      }

      &:hover {
        background: @blueDepressed;
      }
    }
  }

  .switch-options-wrapper {
    display: flex;

    @media @viewport-mobile {
      flex-direction: column;
      gap: 12px;
      margin-bottom: 12px;
    }

    .switch-option {
      display: flex;
      align-items: center;
      margin-right: 18px;

      @media @viewport-mobile {
        margin-right: 0;
      }

      p {
        font-family: 'Inter', sans-serif;
        white-space: nowrap;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        .with-theme({
          color: @vars[neutral-800-to-200];
        });

        .switch-option-tag {
          font-family: 'Inter', sans-serif;
          color: @bluePrimary;
          margin-left: 4px;
          text-transform: uppercase;
          padding: 4px 8px;
          border-radius: @border-radius;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          .with-theme({
            background: @vars[blue-100-to-900];
          })
        }
      }
    }

    .ant-switch {
      width: 32px;
      min-width: unset;
      height: 20px;
      border-radius: 50px;
      margin-right: 12px;
      .with-theme({
        background: @vars[neutral-200-to-700];
      });

      &:focus, &:hover {
        outline: none;
        box-shadow: none;
      }

      &-handle {
        width: 16px;
        height: 16px;
        background: @white;
        box-shadow: none;
        border-radius: 50%;
      }

      &.ant-switch-checked {
        background: @bluePrimary;

        .ant-switch-handle {
          left: calc(100% - 18px);
        }
      }
    }
  }
}

.title {
  &.skeleton.database {
    width: 191px;
    height: 32px;
    border-radius: @border-radius;

    span {
      width: 76px;
      height: 32px;
      border-radius: @border-radius;
    }
  }
}

.link-block.skeleton.database {
  display: flex;
  align-items: center;

  .link.skeleton.database {
    width: 118px;
    height: 20px;
    border-radius: @border-radius;

    &:first-child {
      margin-right: 16px;
    }
  }
}

.link.skeleton {
  .skeleton-animation-bg();
  border-radius: @border-radius;
}

.min-max-input-wrapper.skeleton {
  display: flex;
  align-items: center;
  gap: 10px;

  span:first-of-type, .middle {
    width: 24px;
    height: 24px;
    margin-right: 12px;
  }

  .middle {
    margin-right: 0;
  }

  .link-skeleton-sub-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    min-width: 0;
    width: calc((100% - 48px) / 2);
    .with-theme({
      border: 1px solid @vars[neutral-100-to-800];
    });
    border-radius: @border-radius;
    padding: 10px 7px;

    span:first-of-type {
      width: 24px;
      height: 20px;
    }

    span:last-of-type {
      width: 32px;
      height: 20px;
    }
  }
}

.block-with-label_label.header-skeleton-section {
  display: flex;
  align-items: center;

  @media @viewport-mobile {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }

  span {
    border-radius: @border-radius;
  }

  span:first-of-type {
    width: 48px;
    height: 24px;
    margin-right: 12px;

    @media @viewport-mobile {
      width: 160px;
    }
  }

  span:last-of-type {
    width: 20px;
    height: 20px;

    @media @viewport-mobile {
      width: 60px;
    }
  }
}

.checkbox-skeleton-wrapper {
  display: flex;
  align-items: center;
  width: 50%;

  span:last-of-type {
    margin-left: 10px;
  }
}

.min-max-skeleton-divider {
  position: absolute;
  right: -9px;
  top: 0;
  height: calc(100% - 13px);
}

.skeleton-select-wrapper {
  width: 18.2%;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 1920px) {
    width: 291px;
  }

  .skeleton-select-sub-wrapper {
    margin-top: 10px;
    width: 100%;
    border-radius: @border-radius;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px;
    .with-theme({
      border: 1px solid @vars[neutral-100-to-800];
    });

    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.input-filter-skeleton-wrapper {
  display: flex;
  flex-direction: column;

  div {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 16px;
    padding: 9px 16px;
    .with-theme({
      border: 1px solid @vars[neutral-200-to-neutral-dark-200];
    });
    border-radius: @border-radius;
  }
}

.switch-button-wrapper.skeleton {
  gap: 16px;
}

.notification-rename-error {
  .notification__icon {
    .with-theme({
      background: @vars[red-100-to-900];
    })
  }
}
