@import '../../../../../styles/theme/theme';
@import '../../../../../styles/base/ui-kit';
@import '../../../../../styles/utils/variables';

.product-main-block {
  margin-top: 24px;
  .product-main-block_wrapper {
    margin-top: 12px;
  }
  .block-with-label {
    &_input {
      .ant-input-prefix {
        border-right: 1px solid @Neutral100;
        padding-right: 12px;
        margin-right: 12px !important;
      }
    }
  }

  .product-cost-wrapper {
    display: flex;
    justify-content: space-between;
    .product-cost {
      max-width: 191px !important;
    }
    .cost-divider {
      display: inline-block;
      height: 48px;
      line-height: 48px;
      padding: 0 20px;
      color: @Neutral800;
    }
    .block-with-label {
      &_input {
        .ant-input-prefix {
          border-right: 1px solid @Neutral100;
          padding-right: 12px;
          margin-right: 12px;
        }
      }
    }
  }
}
