@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';
@import '../../../../styles/utils/mixin';

.popular-today {
  padding: 16px 32px;
  display: flex;
  align-items: center;
  .with-theme({
    border-bottom: 1px solid @vars[neutral-200-to-neutral-dark-200];
  });

  &-title {
    margin-right: 16px;
    white-space: nowrap;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.3px;
    .with-theme({
      color: @vars[neutral-600-to-neutral-dark-500];
    });

    @media @viewport-mobile {
      display: none;
    }
  }

  &-items {
    scroll-snap-type: x mandatory;
    display: flex;
    align-items: center;
    gap: 12px;
    flex-wrap: nowrap;
    overflow-x: auto;

    &::-webkit-scrollbar {
      display: none;
    }
    &::-webkit-scrollbar-button {
      display: none;
    }
    &::-webkit-scrollbar-track-piece {
      display: none;
    }
    &::-webkit-scrollbar-thumb {
      display: none;
    }
    &::-webkit-scrollbar-track-piece:start {
      display: none;
    }
    &::-webkit-scrollbar-track-piece:end {
      display: none;
    }
  }

  &-item {
    scroll-snap-align: end;
    display: flex;
    align-items: center;
    height: 32px;
    padding: 6px 8px;
    gap: 8px;
    border-radius: 8px;
    .with-theme({
      border: 1px solid @vars[neutral-200-to-neutral-dark-200];
      background: @vars[white-to-neutral-dark];
    });

    img {
      width: 20px;
      height: 20px;
      object-fit: contain;
      border-radius: 50%;
    }

    span {
      white-space: nowrap;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.3px;
      .with-theme({
        color: @vars[neutral-800-to-100];
      });
    }
  }

  &-handlers {
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 12px;
    position: relative;
    overflow: visible;
    height: 32px;

    @media @viewport-mobile {
      display: none;
    }

    &:before {
      content: '';
      width: 100px;
      height: 100%;
      left: -98px;
      top: 0;
      position: absolute;
      z-index: 2;
      .with-theme({
        background: @vars[right-gradient-bg];
      });
    }
  }

  &-handler {
    cursor: pointer;

    &.disabled {
      cursor: not-allowed;
      opacity: 0.45;
    }

    &.prev {
      transform: rotate(180deg);
    }
  }
}
