@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';

.fadspot-page-inner-content {
  padding-bottom: 24px;

  @media @viewport-mobile {
    padding-bottom: 48px;
  }

  .report-main-block {
    border: none !important;
    margin: 0;
    padding: 16px 32px;
    justify-content: space-between;

    @media @viewport-mobile {
      padding: 0 16px 16px;
      align-items: flex-start;
    }

    .main-block-calendar {
      span {
        font-weight: 500;
        font-size: 18px;
      }
    }
  }
}

.fadspot-page-overview {

  .report-main-block {
    .select-block {
      width: 190px;
      @media @viewport-mobile {
        max-width: 100% !important;
      }
    }
  }

  .same-destination-title {
    margin-top: 48px;
    margin-left: 32px;
    text-align: left;
    font-size: 18px;
    line-height: 22px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    .with-theme({
      color: @vars[neutral-800-to-100];
    });

    @media @viewport-mobile {
      font-size: 18px;
      line-height: 20px;
      margin-top: 40px;
      margin-left: 16px;
    }
  }

  &-banner {
    width: 100%;
    padding: 12px 32px;
    display: flex;
    align-items: flex-start;
    gap: 8px;
    font-family: 'Innter', sans-serif;
    
    @media @viewport-mobile {
      display: block;
      padding: 12px 16px;
    }

    .with-theme({
      border-top: 1px solid @vars[neutral-200-to-neutral-dark-200];
      background: @vars[yellow-xlight-to-yellow-dark];
    });


    & > div {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      margin-left: 4px;
      gap: 5px;

      h3 {
        margin: 0;
        padding: 0;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        .with-theme({
          color: @vars[color-dark-to-white];
        });

        @media @viewport-mobile {
          margin-top: 8px;
          text-align: start;
          font-size: 14px;
          line-height: 18px;
        }
      }

      p {
        margin: 0;
        padding: 0;
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
        .with-theme({
          color: @vars[neutral-600-to-neutral-dark-500];
        });

        @media @viewport-mobile {
          font-size: 14px;
          line-height: 18px;
        }
      }
    }
  }

  &-post-wrapper {
    display: flex;
    align-items: flex-start;
    padding: 20px 32px 0px 32px;
    gap: 16px;
    margin-bottom: 24px;

    @media @viewport-mobile {
      padding: 0;
      flex-direction: column;
      align-items: flex-start;
    }

    .fadspot-page-overview-post {
      width: 452px;

      &-title {
        margin: 0;
        padding: 0;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 18px;

        @media @viewport-mobile {
          margin: 16px 16px;
          font-size: 18px;
        }
        .with-theme({
          color: @vars[neutral-800-to-100];
        });
      }

      @media @viewport-mobile {
        width: 95%;
        margin: 0 auto;
      }

      @media screen and (min-width: 1200px) and (max-width: 1440px) {
        width: 330px;
      }

    }

    .fadspot-page-overview-info-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      width: calc(100% - 420px - 16px);

      @media screen and (min-width: 1920px) {
        width: calc(100% - 450px - 16px);
      }
      
      @media screen and (min-width: 1440px) and (max-width: 1919px) {
        width: calc(100% - 450px - 16px);
      }

      @media @viewport-mobile {
        width: 100%;
      }

      .fadspot-page-overview-info-block {
        background: transparent;
        padding-left: 32px;
        width: 100%;
        font-family: 'Inter', sans-serif;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        min-height: 88px;

        @media @viewport-mobile {
          padding: 16px;
        }

        &.fadspot-page-overview-advertisers {
          width: 100%;

          .ant-carousel {
            width: 101% !important;
          }

          .fadspot-page-overview-advertisers-wrapper {
            display: flex !important;
            align-items: center;
            justify-content: flex-start;
            gap: 1%;
            flex-wrap: nowrap;
            width: 100%;

            @media @viewport-mobile {
              flex-direction: column;
              gap: 10px;
              align-items: flex-start;
            }
          }

          .fadspot-page-overview-advertisers-title-wrapper {
            width: 100%;
            margin-bottom: 8px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            & > div {
              display: flex;
              align-items: center;
            }

            .fadspot-page-overview-advertisers-title-value {
              font-size: 12px;
              line-height: 20px;
              font-family: 'Inter', sans-serif;
              font-weight: 500;
              padding: 2px 8px;
              border-radius: 6px;
              margin-left: 4px;

              .with-theme({
                color: @vars[neutral-600-to-neutral-dark-500];
                background-color: @vars[neutral-100-to-neutral-dark-200];
              });
            }
          }

          .fadspot-page-overview-advertisers-title-buttons {
            gap: 4px;

            button {
              display: flex;
              align-items: center;
              justify-content: center;
              border: none;
              outline: none;
              cursor: pointer;
              background: transparent;
              padding: 0;
              width: 23px;
              height: 23px;

              .with-theme({
                background-color: @vars[white-to-neutral-dark-310];
              });

              svg path {
                .with-theme({
                  fill: @vars[neutral-500-to-neutral-dark-500];
                })
              }

              &[disabled] {
                cursor: not-allowed;
                background: transparent;

                svg path {
                  .with-theme({
                    fill: @vars[neutral-300-to-neutral-dark-300];
                  });
                }
              }
            }
          }

          .fadspot-page-overview-advertisers-title-button-prev {
            svg {
              transform: rotate(180deg);
              margin-top: -3px;
            }
          }
        }

        &.fadspot-page-overview-landing {
          max-height: 182px;

          .custom {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            max-width: calc(100% - 103px);

            & > div > div {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              gap: 4px;
            }
          }

          &:has(.ad-landing-pages-btn) {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
          }

          & > div {
            display: flex;
            width: 100%;
            flex-direction: column;
            gap: 2px;
            align-items: flex-start;
            overflow-y: auto;
            max-height: calc(100% - 24px);

            &::-webkit-scrollbar {
              width: 4px;
              height: 4px;
              border-radius: 2px;
            }
            &::-webkit-scrollbar-button {
              display: none;
            }
            &::-webkit-scrollbar-track-piece {
              background: transparent;
              border-radius: 2px;
            }
            &::-webkit-scrollbar-thumb {
              border-radius: 2px;
              .with-theme({
                background: @vars[neutral-200-to-neutral-dark-200];
              });
            }

            & > div {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              gap: 4px;

              .ad-landing-pages-link {
                text-decoration: none;
                font-family: 'Inter', sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                max-width: calc(100% - 20px);
                .with-theme({
                  color: @vars[color-dark-to-white];
                });

                &:hover {
                  color: @bluePrimary;
                }
              }
            }
          }

          .ad-landing-pages-btn {
            width: 102px;
            height: 40px;
            font-family: 'Inter', sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
            cursor: pointer;
            transition: all .3s ease;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
            .with-theme({
              background: @vars[color-white-to-dark];
              border: 1px solid @vars[neutral-100-to-800];
              color: @vars[neutral-800-to-200];
            });

            &:hover {
              border: 1px solid @bluePrimary;
              color: @bluePrimary;

              svg path {
                fill: @bluePrimary;
              }
            }
          }
        }

        &.fadspot-page-overview-status {
          & > div {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 4px;

            .circle {
              width: 4px;
              height: 4px;
              border-radius: 50%;
              .with-theme({
                background: @vars[color-dark-to-white];
              });

              &.status {
                width: 10px;
                height: 10px;
                background: @greenPrimary;

                &.red {
                  background: @redPrimary;
                }

                &.grey {
                  background: @Neutral500;
                }
              }
            }

            & > span {
              font-family: 'Inter', sans-serif;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
              .with-theme({
                color: @vars[color-dark-to-white];
              })
            }
          }
        }

        &.fadspot-page-overview-landing-same {
          & > span {
            cursor: pointer;
            color: @bluePrimary;
            font-family: 'Inter', sans-serif;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            transition: all .3s ease;
            text-decoration: underline;
            padding-top: 4px;

            &:hover {
              color: @blueDepressed;
            }
          }

          &.disabled > span {
            cursor: default;
            .with-theme({
              color: @vars[neutral-800-to-200];

              &:hover {
                color: @vars[neutral-800-to-200];
              }
            })
          }
        }

        &.fadspot-page-overview-timeline {
          & > div {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            gap: 35px;

            @media @viewport-mobile {
              width: 100%;
            }

            .badge {
              padding: 4px 8px;
              font-family: 'Inter', sans-serif;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 16px;
              border-radius: @border-radius;
              .with-theme({
                background: @vars[neutral-100-to-neutral-dark-100];
                color: @vars[neutral-600-to-neutral-dark-500];
              })
            }

            .item {

              &-wrapper {
                display: flex;
                align-items: center;
                gap: 12px;
                position: relative;
                margin-left: 10px;
                
                @media @viewport-mobile {
                  width: 100%;
                }

                &:not(:first-of-type) {

                  &:before {
                    position: absolute;
                    left: 16px;
                    height: 35px;
                    width: 1px;
                    top: -35px;
                    content: '';
                    .with-theme({
                      border-right: 1px solid @vars[neutral-200-to-neutral-dark-200];
                    })
                  }
                }

                &:not(:last-of-type) {

                  &:after {
                    position: absolute;
                    left: 16px;
                    height: 35px;
                    width: 1px;
                    bottom: -35px;
                    content: '';
                    .with-theme({
                      border-right: 1px solid @vars[neutral-200-to-neutral-dark-200];
                    })
                  }
                }
              }

              &-info {
                display: flex;
                align-items: center;
                font-family: 'Inter', sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                gap: 10px;

                @media @viewport-mobile {
                  flex-direction: column;
                  align-items: flex-start;
                }

                p {
                  svg {
                    margin-left: 4px;
                    margin-bottom: 2px;
                  }
                  .with-theme({
                    color: @vars[color-dark-to-white];
                  })
                }

                span {
                  .with-theme({
                    color: @vars[neutral-600-to-neutral-dark-500];
                  })
                }
              }

              &-image {
                width: 32px;
                height: 32px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                .with-theme({
                  background: @vars[neutral-50-to-neutral-dark-50];
                });
                
                &-last {
                  .with-theme({
                    background-color: @vars[blue-light-to-blue-dark];
                  })
                }
              }
            }
          }
        }

        h3 {
          margin: 0;
          padding: 0;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          .with-theme({
            color: @vars[neutral-800-to-100];
          });

          svg {
            margin-left: 4px;
          }
        }
      }
    }
  }

  &-chart-stats {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 32px;

    @media @viewport-mobile {
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
    }

    .fadspot-page-overview-chart-stat {
      display: flex;
      align-items: center;

      @media @viewport-mobile {
        justify-content: space-between;
        width: 100%;
      }

      span {
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        display: flex;
        align-items: flex-start;
        .with-theme({
          color: @vars[color-dark-to-white];
        });
      }
      
      &-wrapper {
        display: flex;
        align-items: center;
        gap: 8px;
      }

      &-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 5px;
      }

      &-info {
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        display: flex;
        align-items: flex-start;
        .with-theme({
          color: @vars[color-dark-to-white];
        });

        p {
          display: flex;
          align-items: center;
          margin-right: 12px;

          .with-theme({
            color: @vars[neutral-600-to-neutral-dark-500];
          });

          svg {
            margin-left: 4px;
            margin-bottom: 2px;
          }
        }
      }
    }
  }
}
