@import '../../../styles/utils/variables';

.product-actions-admin {
  &:after {
    display: none;
  }
}

.store-admin-page {
.ant-switch {
  background: @Neutral200;

  &:hover {
    background: @Neutral200 !important;
  }

  &-checked {
    background: @bluePrimary;

    &:hover {
      background: @blueDepressed !important;
    }
  }

  &-inner {
    width: 110px;
  }
}
}
