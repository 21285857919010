@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';
@import '../../../../styles/utils/mixin';

.st-nav-header {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 64px;
  padding: 0 24px;
  .with-theme({
    background: @vars[blue-100-to-900];
  });

  @media @viewport-mobile {
    padding: 0;
  }

  &:after {
    position: absolute;
    content: '';
    right: calc((100% + 272px - 100vw) / 2);
    width: 100vw;
    top: 0;
    height: 100%;
    .with-theme({
      background: @vars[blue-100-to-900];
    });
    z-index: -1;

    @media @viewport-mobile {
      right: -8%;
    }
  }

  &-back {
    color: @bluePrimary;
    display: flex;
    align-items: center;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    transition: all .3s ease;
    gap: 8px;
    cursor: pointer;

    @media @viewport-mobile {
      font-size: 10px;
      line-height: 12px;
    }

    svg {
      transform: rotate(180deg);
      path {
        transition: all .3s ease;
        fill: @bluePrimary;
      }
    }

    &:hover {
      color: @blueDepressed;

      svg path {
        fill: @blueDepressed;
      }
    }
  }

  &-info {
    display: flex;
    align-items: center;
    margin-left: auto;

    @media @viewport-mobile {
      display: none;
    }

    span {
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      .with-theme({
        color: @vars[neutral-800-to-200];
      })
    }

    svg {
      margin-left: 4px;
    }
  }

  &-btn-wrapper {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &-btn {
    height: 32px;
    border-radius: @border-radius;
    padding: 0 16px;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .with-theme({
      border: 1px solid @vars[neutral-100-to-800];
      background: @vars[color-white-to-dark];
      color: @vars[neutral-800-to-200];
    });

    @media @viewport-mobile {
      font-size: 10px;
      line-height: 12px;
    }

    &:not(.active):hover {
      border: 1px solid @bluePrimary;
      color: @bluePrimary;
    }

    &.active {
      background: @bluePrimary;
      color: @white;
      border: 1px solid @bluePrimary;
      cursor: default;
    }
    &.disabled {
      cursor: not-allowed;
      border: 1px solid @Neutral200;
      .with-theme({
        background: @vars[neutral-100-to-800];
      });
      span {
        color: @Neutral500;
        margin-left: 5px;
      }
    }
  }
}
