@import '../../../styles/utils/variables';

.portfolio-admin-page {
  padding: 64px 0 36px;

  .search{
    max-width: 1088px;
    padding: 0 24px;
    margin: 0 auto;
  }

  @media @viewport-mobile {
    .search{
      padding: 0;
    }
  }
}
